import { ReactNode, createContext } from "react";
import useEstados, { EstadosType } from "./hooks/useEstados";

interface EstadosContextProviderProps {
    children: ReactNode;
}

export interface EstadosContextData {
    handleGetAllEstados: () => Promise<EstadosType[] | null>;
    estadosResponse: EstadosType[];
}

const initialState: EstadosContextData = {
    handleGetAllEstados: async () => {
        return Promise.resolve(null);
    },
    estadosResponse: [],
};

export const EstadosContext =
    createContext<EstadosContextData>(initialState);

export default function EstadosContextProvider({
    children,
}: EstadosContextProviderProps) {
    const {
        handleGetAllEstados,
        estadosResponse,
    } = useEstados();

    return (
        <EstadosContext.Provider
            value={{
                handleGetAllEstados,
                estadosResponse,
            }}
        >
            {children}
        </EstadosContext.Provider>
    );
}
