import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { CandidateContext } from "../CandidateContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface ExpertiseType {
  title: string;
  typeJob: string;
  company: string;
  startDate: string;
  endDate: string;
  description: string;
  candidate?: any;
  id?: number;
}

export default function useExpertise() {
  const showToast = useToast();
  const {setLoading} = useContext(LoadingContext)
  const { handleLogout } = useContext(AuthContext)
  const [expertises, setExpertises] = useState<ExpertiseType[]>([]);
  const [expertisesTable, setExpertisesTable] = useState<any>({
    mylist: [],
    columns: [],
  });

  const [formValues, setFormValues] = useState<ExpertiseType>({
    title: "",
    typeJob: "",
    company: "",
    startDate: "",
    endDate: "",
    description: "",
  });

  const { candidate } = useContext(CandidateContext);

  function getAllExpertiseByCandidateId(
    candidateId: number
  ): Promise<ExpertiseType[] | null> {
    setLoading(true);
    return api
      .get(`/Expertise/get-expertises-candidate/${candidateId}`)
      .then((response) => {
        const expertises: ExpertiseType[] = response.data;
        setExpertises(expertises);
        createNewListTable(expertises);
        setLoading(false);
        return expertises;
      })
      .catch((error) => {
        showToast(
          "error",
          "Erro ao obter informações das experiências. Verifique novamente mais tarde.",
          error
        );
        setLoading(false);
        console.error("Erro ao obter informações do usuário:", error);
        return null;
      });
  }

  function postExpertiseByCandidateId(
    expertise: ExpertiseType
  ): Promise<void | null> {
    setLoading(true);
    return api
      .post(`/Expertise/create?candidateId=${candidate.id}`, expertise)
      .then((response) => {
        showToast("success", "Experiência cadastrada com sucesso.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          handleLogout()
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao adicionar experiência. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao adicionar informações da experiência:", error);
        return null;
      });
  }

  function putExpertiseById(
    idExperience: number,
    expertise: ExpertiseType
  ): Promise<void | null> {
    setLoading(true);
    return api
      .put(`/Expertise/${idExperience}`, expertise)
      .then((response) => {
        showToast("success", "Experiência atualizada com sucesso.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          handleLogout()
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao atualizar informações das experiências. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao atualizar informações da experiências:", error);
        return null;
      });
  }

  function deleteExpertiseById(
    idExperience: number,
  ): Promise<void | null> {
    setLoading(true);
    return api
      .delete(`/Expertise/${idExperience}`)
      .then((response) => {
        showToast("success", "Experiência deletada com sucesso.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          handleLogout()
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao deletada experiência. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao deletar a experiência:", error);
        return null;
      });
  }

  const createNewListTable = (expertises: ExpertiseType[]) => {
    if (expertises && expertises.length > 0) {
      const newList = expertises.map((item: ExpertiseType) => {
        return {
          column1: item.title,
          column2: item.company,
          column3: item.startDate
            ? item.startDate.split("-").reverse().join("/")
            : "",
          column4: item.endDate
            ? item.endDate.split("-").reverse().join("/")
            : "",
          id: item.id,
        };
      });

      const columns = [
        "CARGO",
        "EMPRESA",
        "DATA INICIO",
        "DATA FIM",
        "",
      ];

      setExpertisesTable({ mylist: newList, columns: columns });
    } else {
      setExpertisesTable({ mylist: [], columns: [] });
    }
  };

  return {
    getAllExpertiseByCandidateId,
    expertises,
    expertisesTable,
    postExpertiseByCandidateId,
    formValues,
    setFormValues,
    putExpertiseById,
    deleteExpertiseById
  };
}
