// import { Fragment, useContext, useEffect, useState } from "react";
// import {
//   Card,
//   CardHeader,
//   Container,
//   Col,
//   Row,
//   Form,
//   Button,
// } from "reactstrap";
// import Header from "../../../components/Headers/Header";
// import AdminNavbar from "../../../components/Navbars/AdminNavbar/AdminNavbar";
// import CardsJobs from "../../../components/CardsJobs/CardsJobs";
// import CardsJobDetails from "../../../components/CardsJobDetails/CardsJobDetails";
// import DropdownFilter from "../../../components/DropdownFilter/DropdownFilter";
// import ModalComponent from "../../../components/ModalComponent/ModalComponent";
// import "./SearchJobs.css";
// import { JobContext } from "../../../context/JobContext";
// import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
// import { JobType } from "../../../context/hooks/useJob";
// import calculateDate from "../../../utils/calculateDate";
// import PaginationMaterial from "../../../components/PaginationMaterial/PaginationMaterial";
// import _ from "lodash";
// import WhatsappButton from "../../../components/WhatsappButtom/WhatsappButtom";

// const Icons = () => {
//   const { 
//     getAllJobs,
//     handleGetByJobId,
//     getAllJobsPaged,
//     jobs, 
//     setJobs, 
//     setJobSelectCard,
//     listJobs,
//     setListJobs,
//     initialListJobs,
//     pageCount,
//     setPageCount
//   } = useContext(JobContext);
//   const [openModal, setOpenModal] = useState(false);
//   const [dataSearch, setDataSearch] = useState({input1: "", input2: "", workModel: "", publishedDate: "", PCD: ""});
//   const [formValuesFilter, setFormValuesFilter] = useState({
//     input1: "",
//     input2: "",
//     input3: "",
//   });

//   const [pagination, setPagination] = useState({
//     initial: 0,
//     final: 20,
//   });

//   const onChangePaginationCommon = async (e: any, value: any) => {

//     await getAllJobsPaged(value, dataSearch.input2, dataSearch.input1, dataSearch.workModel, dataSearch.publishedDate, dataSearch.PCD);
//   };

//   const onChangePagination = (e: any, value: any) => {
//     e.preventDefault();
//     const positionInitial = (value - 1) * 20;
//     const positionFinal = positionInitial + 20;

//     setPagination({ initial: positionInitial, final: positionFinal });
//   };

//   const handleSearchCandidate = async (data: any) => {
//     const result = await getAllJobs();

//     if (!result) {
//       return;
//     }

//     let filteredResult = result;

//     if (data.input1 || data.input2) {
//       filteredResult = filteredResult.filter((e) => {
//         const jobTitleMatch =
//           !data.input1 ||
//           _.deburr(e.jobTitle.toLowerCase()).includes(
//             _.deburr(data.input1.toLowerCase())
//           );

//         const locationMatch =
//           !data.input2 ||
//           _.deburr(e.location.toLowerCase()).includes(
//             _.deburr(data.input2.toLowerCase())
//           );

//         return jobTitleMatch && locationMatch;
//       });
//     }

//     setJobs(filteredResult);
//   };

//   const handleChangeFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value, type, id, innerText } = e.target;

//     if (type === "date") {
//       setFormValuesFilter({
//         ...formValuesFilter,
//         [name]: value,
//       });
//     } else {
//       setFormValuesFilter({
//         ...formValuesFilter,
//         [id.split("-")[0]]: innerText,
//       });
//     }
//   };

//   const handleApplyFilters = async () => {
//     const result = await getAllJobs();
//     if (!result) {
//       return;
//     }

//     let filteredResult = result;

//     const filters = [
//       { field: "workModel", value: formValuesFilter.input1 },
//       { field: "publishedDate", value: formValuesFilter.input2 },
//       { field: "pcd", value: formValuesFilter.input3 },
//     ];

//     filters.forEach((filter) => {
//       if (filter.value) {
//         filteredResult = filteredResult.filter((e) =>
//           e[filter.field].toLowerCase().includes(filter.value.toLowerCase())
//         );
//       }
//     });

//     setJobs(filteredResult);
//   };

//   const handleClearFilters = () => {
//     setFormValuesFilter({ input1: "", input2: "", input3: "" });
//   };

//   useEffect(() => {
//     getAllJobs();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   useEffect(() => {
//     setJobSelectCard(jobs[0]);
//     setPagination({
//       initial: 0,
//       final: 20,
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [jobs]);

//   return (
//     <>
//       <WhatsappButton />
//       <Header />
//       <LoadingSpinner />
//       <AdminNavbar
//         placeholderSearch="Cargo ou palavra chave"
//         placeholderSearch1="Localização"
//         titleButtonSearch="ACHAR VAGAS"
//         brandText={"PROCURAR VAGAS"}
//         search={false}
//         titleSearch={""}
//         handleButtonSearch={handleSearchCandidate}
//       />
//       <Container className="mt--7" fluid>
//         <Card
//           className="shadow cards-filters-jobs"
//           style={{ marginTop: "60px" }}
//         >
//           <CardHeader className="bg-transparent">
//             <Row className="justify-content-center">
//               <Col lg="3" md="6" sm="12">
//                 <DropdownFilter
//                   options={[
//                     { label: "Presencial" },
//                     { label: "Remoto" },
//                     { label: "Hídrido" },
//                   ]}
//                   handleChange={handleChangeFilters}
//                   name="input1"
//                   value={formValuesFilter.input1}
//                   type="text"
//                   nameDrop="Modelo de Trabalho"
//                 />
//               </Col>
//               <Col lg="3" md="6" sm="12">
//                 <DropdownFilter
//                   options={[]}
//                   handleChange={handleChangeFilters}
//                   name="input2"
//                   value={formValuesFilter.input2}
//                   type="date"
//                   nameDrop="Data de Publicação"
//                 />
//               </Col>
//               <Col lg="3" md="6" sm="12">
//                 <DropdownFilter
//                   options={[
//                     { label: "Também p/ PcD" },
//                     { label: "Não Informado" },
//                   ]}
//                   handleChange={handleChangeFilters}
//                   name="input3"
//                   value={formValuesFilter.input3}
//                   type="text"
//                   nameDrop="PCD"
//                 />
//               </Col>
//               <Col
//                 lg="1"
//                 md="6"
//                 sm="12"
//                 className="d-flex align-items-center justify-content-center"
//               >
//                 <Button
//                   onClick={handleApplyFilters}
//                   className="butom-search-jobs"
//                   size="lg"
//                 >
//                   APLICAR
//                 </Button>
//               </Col>
//               <Col
//                 lg="1"
//                 md="6"
//                 sm="12"
//                 className="d-flex align-items-center justify-content-center ml-3"
//               >
//                 <Button
//                   onClick={handleClearFilters}
//                   className="butom-search-jobs"
//                   size="lg"
//                 >
//                   LIMPAR
//                 </Button>
//               </Col>
//             </Row>
//           </CardHeader>
//         </Card>
//         <div style={{ display: "flex" }}>
//           <div className="container-group-cardjobs">
//             {jobs.length > 0 &&
//               jobs
//                 .slice(pagination.initial, pagination.final)
//                 .map((job: JobType) => {
//                   return (
//                     <Fragment key={job.id}>
//                       <CardsJobs
//                         onClick={() =>
//                           setJobSelectCard(jobs.find((e) => e.id === job.id))
//                         }
//                         openJob={() => setOpenModal(true)}
//                         description={job.description}
//                         jobType={job.jobType}
//                         nameJob={job.jobTitle}
//                         companyLogoUrl={job.companyLogoUrl}
//                         data={calculateDate(job.publishedDate)}
//                         local={job.location}
//                         localOffice={job.workModel}
//                         nameCompany={job.companyName}
//                       />
//                     </Fragment>
//                   );
//                 })}
//             <div className="d-flex justify-content-center mt-4">
//               {jobs.length === 0 ? (
//                 <div style={{ color: "red" }}>NENHUMA VAGA ENCONTRADA</div>
//               ) : (
//                 <PaginationMaterial
//                   onChange={onChangePagination}
//                   count={Math.ceil(jobs.length / 20 || 0)}
//                 />
//               )}
//             </div>
//           </div>
//           {jobs.length > 0 && (
//             <div className="details-jobs-card" style={{ flex: 1 }}>
//               <Col style={{ maxWidth: "100%", height: "100%" }}>
//                 <CardsJobDetails openJob={() => setOpenModal(true)} />
//               </Col>
//             </div>
//           )}
//         </div>
//       </Container>
//       <ModalComponent
//         openModal={openModal}
//         closeModal={() => setOpenModal((state) => !state)}
//       />
//     </>
//   );
// };

// export default Icons;

import { Fragment, useContext, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Form,
  Button,
} from "reactstrap";
import Header from "../../../components/Headers/Header";
import AdminNavbar from "../../../components/Navbars/AdminNavbar/AdminNavbar";
import CardsJobs from "../../../components/CardsJobs/CardsJobs";
import CardsJobDetails from "../../../components/CardsJobDetails/CardsJobDetails";
import DropdownFilter from "../../../components/DropdownFilter/DropdownFilter";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import "./SearchJobs.css";
import { JobContext } from "../../../context/JobContext";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { JobType } from "../../../context/hooks/useJob";
import calculateDate from "../../../utils/calculateDate";
import PaginationMaterial from "../../../components/PaginationMaterial/PaginationMaterial";
import _ from "lodash";
import WhatsappButton from "../../../components/WhatsappButtom/WhatsappButtom";

const Icons = () => {
  const {
    getAllJobs,
    handleGetByJobId,
    getAllJobsPaged,
    jobs,
    setJobs,
    setJobSelectCard,
    listJobs,
    setListJobs,
    initialListJobs,
    pageCountJobs,
    setPageCountJobs
  } = useContext(JobContext);
  const [openModal, setOpenModal] = useState(false);
  const [dataSearchJob, setDataSearchJob] = useState({ input1: "", input2: "", workModel: "", publishedDate: "", PCD: "" });
  const [pagination, setPagination] = useState({
    initial: 0,
    final: 20,
  });

  const onChangePaginationCommon = async (e: any, value: any) => {
    await getAllJobsPaged(value, dataSearchJob.input1, dataSearchJob.input2, dataSearchJob.workModel, dataSearchJob.publishedDate, dataSearchJob.PCD);
  };

  const handleSearchJobPage = async () => {
    await getAllJobsPaged(
      1,
      "",
      "",
      dataSearchJob.workModel,
      dataSearchJob.publishedDate,
      dataSearchJob.PCD
    );
  };

  const handleSearchJob = async (data: any) => {
    if (data.input1 || data.input2 || data.workModel || data.publishedDate || data.PCD ) {
      setDataSearchJob({input1: data.input1, input2: data.input2, workModel: data.workModel, publishedDate: data.publishedDate, PCD: data.PCD});
      await getAllJobsPaged(1, data.input1, data.input2, data.workModel, data.publishedDate, data.PCD);
    }
  };

  const handleChangeFilters = (e: React.ChangeEvent<HTMLInputElement>, teste: string) => {
    const { name, value, type, id, innerText } = e.target;

    if(teste === "workModel" || teste === "PCD"){
      setDataSearchJob({
        ...dataSearchJob,
        [teste]: innerText,
      });
    }
    else{
      setDataSearchJob({
        ...dataSearchJob,
        [name]: value,
      });
    }
  };

  const handleClearFilters = () => {
    setDataSearchJob({ input1: dataSearchJob.workModel, input2: dataSearchJob.input2, workModel: "", publishedDate: "", PCD: "",  });
  };

  useEffect(() => {
    getAllJobsPaged(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setJobSelectCard(jobs[0]);
    setPagination({
      initial: 0,
      final: 20,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  return (
    <>
      <WhatsappButton />
      <Header />
      <LoadingSpinner />
      <AdminNavbar
        placeholderSearch="Cargo ou palavra chave"
        placeholderSearch1="Localização"
        titleButtonSearch="ACHAR VAGAS"
        brandText={"PROCURAR VAGAS"}
        search={false}
        titleSearch={""}
        handleButtonSearch={handleSearchJob}
      />
      <Container className="mt--7" fluid>
        <Card
          className="shadow cards-filters-jobs"
          style={{ marginTop: "60px" }}
        >
          <CardHeader className="bg-transparent">
            <Row className="justify-content-center">
              <Col lg="3" md="6" sm="12">
                <DropdownFilter
                  options={[
                    { label: "Presencial" },
                    { label: "Remoto" },
                    { label: "Híbrido" },
                  ]}
                  handleChange={(e: any) => handleChangeFilters(e, "workModel")}
                  name="workModel"
                  value={dataSearchJob.workModel}
                  type="text"
                  nameDrop="Modelo de Trabalho"
                />
              </Col>
              <Col lg="3" md="6" sm="12">
                <DropdownFilter
                  options={[]}
                  handleChange={handleChangeFilters}
                  name="publishedDate"
                  value={dataSearchJob.publishedDate}
                  type="date"
                  nameDrop="Data de Publicação"
                />
              </Col>
              <Col lg="3" md="6" sm="12">
                <DropdownFilter
                  options={[
                    { label: "Também p/ PcD" },
                    { label: "Não Informado" },
                  ]}
                  handleChange={(e: any) => handleChangeFilters(e, "PCD")}
                  name="PCD"
                  value={dataSearchJob.PCD}
                  type="text"
                  nameDrop="PCD"
                />
              </Col>
              <Col
                lg="1"
                md="6"
                sm="12"
                className="d-flex align-items-center justify-content-center"
              >
                <Button
                  onClick={handleSearchJobPage}
                  className="butom-search-jobs"
                  size="lg"
                >
                  APLICAR
                </Button>
              </Col>
              <Col
                lg="1"
                md="6"
                sm="12"
                className="d-flex align-items-center justify-content-center ml-3"
              >
                <Button
                  onClick={handleClearFilters}
                  className="butom-search-jobs"
                  size="lg"
                >
                  LIMPAR
                </Button>
              </Col>
            </Row>
          </CardHeader>
        </Card>

        <div style={{ display: "flex" }}>
          <div className="container-group-cardjobs">
            {listJobs.slice(pagination.initial, pagination.final)
              .map((job: JobType) => {
                return (
                  <Fragment key={job.id}>
                    <CardsJobs
                      onClick={() =>
                        setJobSelectCard(job)
                      }
                      openJob={() => setOpenModal(true)}
                      description={job.description}
                      jobType={job.jobType}
                      nameJob={job.jobTitle}
                      companyLogoUrl={job.companyLogoUrl}
                      data={calculateDate(job.publishedDate)}
                      local={job.location}
                      localOffice={job.workModel}
                      nameCompany={job.companyName}
                    />
                  </Fragment>
                );
              })}
            <div className="d-flex justify-content-center mt-4">
              {/* {pageCountJobs && ( */}
                <div className="d-flex justify-content-center mt-4">
                  <PaginationMaterial
                    onChange={(e: any, value: any) => (onChangePaginationCommon(e, value))}
                    count={pageCountJobs}
                  />
                </div>
              {/* )} */}
              {jobs.length === 0 && (
                <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
              )}
            </div>
          </div>
          {jobs.length > 0 && (
            <div className="details-jobs-card" style={{ flex: 1 }}>
              <Col style={{ maxWidth: "100%", height: "100%" }}>
                <CardsJobDetails openJob={() => setOpenModal(true)} />
              </Col>
            </div>
          )}
        </div>
      </Container>
      <ModalComponent
        openModal={openModal}
        closeModal={() => setOpenModal((state) => !state)}
      />
    </>
  );
};

export default Icons;

