import * as React from "react";
import { SVGProps } from "react";

const IconeEye = ({
  fill,
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 30 22"
      fill="none"
    >
      <path
        d="M15 15C17.2091 15 19 13.2091 19 11C19 8.79086 17.2091 7 15 7C12.7909 7 11 8.79086 11 11C11 13.2091 12.7909 15 15 15Z"
        fill="white"
      />
      <path
        d="M29.9396 10.66C28.7634 7.61765 26.7216 4.98662 24.0664 3.09209C21.4112 1.19756 18.2591 0.122573 14.9996 0C11.7401 0.122573 8.58796 1.19756 5.93278 3.09209C3.27759 4.98662 1.23574 7.61765 0.0595746 10.66C-0.0198582 10.8797 -0.0198582 11.1203 0.0595746 11.34C1.23574 14.3824 3.27759 17.0134 5.93278 18.9079C8.58796 20.8024 11.7401 21.8774 14.9996 22C18.2591 21.8774 21.4112 20.8024 24.0664 18.9079C26.7216 17.0134 28.7634 14.3824 29.9396 11.34C30.019 11.1203 30.019 10.8797 29.9396 10.66ZM14.9996 17.5C13.714 17.5 12.4573 17.1188 11.3884 16.4046C10.3194 15.6903 9.48633 14.6752 8.99436 13.4874C8.50239 12.2997 8.37367 10.9928 8.62447 9.73191C8.87527 8.47104 9.49434 7.31285 10.4034 6.40381C11.3124 5.49476 12.4706 4.8757 13.7315 4.6249C14.9924 4.37409 16.2993 4.50281 17.487 4.99478C18.6747 5.48675 19.6899 6.31987 20.4041 7.38879C21.1184 8.45771 21.4996 9.71442 21.4996 11C21.4969 12.7231 20.8113 14.3749 19.5928 15.5933C18.3744 16.8117 16.7227 17.4974 14.9996 17.5Z"
        fill="white"
      />
    </svg>
  );
};

export default IconeEye;
