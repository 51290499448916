import { ReactNode, createContext } from "react";
import useCandidate, { CandidateType } from "./hooks/useCandidate";

interface CandidateContextProviderProps {
  children: ReactNode;
}

export interface CandidateContextData {
  handleGetByCandidateId: (id: number) => Promise<CandidateType | null>;
  handleGetFavoriteCandidateAPI: (ids: number[]) => Promise<CandidateType[] | null>;
  handleGetByUserId: (id: number) => Promise<CandidateType | null>;
  putCandidate: (candidate: CandidateType) => Promise<CandidateType | null>;
  handleGetCvCandidate: (idCandidate: number) => any;
  getAllCandidatesPaged: (
    pageNumber: number, 
    city?: string, 
    keyword?: string, 
    educationStatus?: string, 
    hasCar?: boolean, 
    hasPCD?: boolean, 
    distance?: number,
    // latitude?: number,
    // longitude?: number,
    state?: string,
    cityReference?: string,
  ) => Promise<CandidateType[] | null>;
  downloadCandidateReport: (
    city?: string, 
    keyword?: string, 
    educationStatus?: string, 
    hasCar?: boolean, 
    hasPCD?: boolean, 
    distance?: number,
    state?: string,
    cityReference?: string,
    listFavorite?: number[],
  ) => void;
  candidate: CandidateType;
  listCandidate: CandidateType[];
  initialListCandidate: CandidateType[];
  setListCandidate: any;
  setCandidate: any;
  setPageCount: any;
  pageCount: number;
}

const initialState: CandidateContextData = {
  handleGetByCandidateId: async (id: number) => {return Promise.resolve(null);
  },
  handleGetByUserId: async (id: number) => {return Promise.resolve(null);
  },
  handleGetFavoriteCandidateAPI: async (ids: number[]) => {return Promise.resolve(null);
  },
  handleGetCvCandidate: async (idCandidate: number) => {},
  getAllCandidatesPaged: async (
    pageNumber: number, 
    city?: string, 
    keyword?: string, 
    educationStatus?: string, 
    hasCar?: boolean,
    hasPCD?: boolean,
    distance?: number,
    state?: string,
    cityReference?: string,
  ) => {
    return Promise.resolve(null);
  },
  downloadCandidateReport: async (
    city?: string, 
    keyword?: string, 
    educationStatus?: string, 
    hasCar?: boolean,
    hasPCD?: boolean,
    distance?: number,
    state?: string,
    cityReference?: string,
    listFavorite?: number[],
  ) => {
    return Promise.resolve(null);
  },
  putCandidate: async (candidate: CandidateType) => {
    return Promise.resolve(null);
  },
  listCandidate: [],
  initialListCandidate: [],
  candidate: {
    name: "",
    city: "",
    state: "",
    region: "",
    typeCandidate: 0,
    document: "",
    email: "",
    celPhone: "",
    address: "",
    birthData: "",
    user: {},
    description: "",
    photo: "",
    curriculum: "",
    linkedin: "",
    hasCar: false,
    carLicense: 0,
    personalGoal: "",
    hasPCD: false,
    isActive: 0,
    presentationVideo: "",
    averageAdvertising: "",
    id: 0,
    updatedDate: "",
  },
  setListCandidate: () => {},
  setCandidate: () => {},
  setPageCount: () => {},
  pageCount: 0,
};

export const CandidateContext =
  createContext<CandidateContextData>(initialState);

export default function CandidateContextProvider({
  children,
}: CandidateContextProviderProps) {
  const {
    handleGetByCandidateId,
    handleGetByUserId,
    handleGetFavoriteCandidateAPI,
    candidate,
    setCandidate,
    putCandidate,
    listCandidate,
    initialListCandidate,
    setListCandidate,
    pageCount,
    getAllCandidatesPaged,
    setPageCount,
    downloadCandidateReport,
    handleGetCvCandidate
  } = useCandidate();

  return (
    <CandidateContext.Provider
      value={{
        handleGetByCandidateId,
        handleGetByUserId,
        handleGetFavoriteCandidateAPI,
        candidate,
        initialListCandidate,
        setCandidate,
        putCandidate,
        listCandidate,
        setListCandidate,
        pageCount,
        getAllCandidatesPaged,
        setPageCount,
        downloadCandidateReport,
        handleGetCvCandidate
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
}
