import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { ChangeEvent, useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { UserProps } from "../context/hooks/useAuth";

const Login = () => {
  const { handleLogin, setSelectTypeUser, selectTypeUser } =
    useContext(AuthContext);

  const [login, setLogin] = useState<UserProps>({
    password: "",
    email: "",
  });

  const [loading, setLoading] = useState(false);

  const handleLoginClick = async () => {
    setLoading(true);
    await handleLogin(login);
    setLoading(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLogin((prevLogin) => ({
      ...prevLogin,
      [name]: value,
    }));
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="shadow border-0 align-items-center">
          <img
            style={{ width: "63%" }}
            alt="..."
            className="rounded-circle"
            src={require("../assets/img/brand/logoAtt.png")}
          />
          {/* <div className="bg-transparent">
            <div className="text-muted text-center mb-3">
              <small>Entre com</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../assets/img/icons/common/linkdln.svg").default
                    }
                  />
                </span>
                <span className="btn-inner--text">Linkedln</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../assets/img/icons/common/google.svg").default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </div> */}
          <div className="px-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Ou entre com as credenciais</small>
            </div> */}
            <div className="text-center text-muted mb-4">
              <small>Eu sou um...</small>
            </div>
            <Form>
              <FormGroup className="mb-3 d-flex">
                <div className="custom-control custom-control-alternative custom-checkbox mr-7">
                  <input
                    className="custom-control-input"
                    id=" customCheckLoginPartner"
                    type="checkbox"
                    checked={selectTypeUser === 1}
                    onChange={() => {
                      setSelectTypeUser(1);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLoginPartner"
                  >
                    <span className="text-muted">Candidato</span>
                  </label>
                </div>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="customCheckLoginStudent"
                    type="checkbox"
                    checked={selectTypeUser === 2}
                    onChange={() => {
                      setSelectTypeUser(2);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckLoginStudent"
                  >
                    <span className="text-muted">Recrutador</span>
                  </label>
                </div>
              </FormGroup>
            </Form>

            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={login.email}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={login.password}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Lembre-me</span>
                </label>
              </div>
              <div className="text-center">
                <Button
                  onClick={handleLoginClick}
                  className="my-4"
                  color="primary"
                  type="button"
                  disabled={loading}
                >
                  {loading && (<Spinner size="sm">Loading...</Spinner> )}
                  {" "}Entre
                </Button>
              </div>
            </Form>
          </div>
        </Card>
        <Row className="">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Esqueceu a senha?</small>
            </a>
          </Col>
          {/* <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Crie uma nova conta</small>
            </a>
          </Col> */}
        </Row>
      </Col>
    </>
  );
};

export default Login;
