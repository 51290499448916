import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import UserHeader from "../../components/Headers/UserHeader/UserHeader";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import MaskedInput from "react-text-mask";
import ModalChangePassword from "../../components/ModalChangePassword/ModalChangePassword";
import WhatsappButton from "../../components/WhatsappButtom/WhatsappButtom";

const ProfileRecruiter = () => {
  const { handleGetByUserId, company, setCompany, putCompany } =
    useContext(CompanyContext);
  const { objUserTk } = useContext(AuthContext);

  const [editPerfilState, setEditPerfilState] = useState<boolean>(true);
  const photoInputRef = useRef<HTMLInputElement>(null);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompany({
      ...company,
      [name]: value,
    });
  };

  const handleSalveIndoCompany = () => {
    putCompany(company);
    setEditPerfilState(true);
  };

  const handleClickChangePhoto = () => {
    if (photoInputRef.current) {
      photoInputRef.current.click();
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contentBase64 = e.target?.result as string;
        if (file.type.startsWith("image/")) {
          setCompany((prevCompany: any) => ({
            ...prevCompany,
            photo: contentBase64,
          }));
        } else {
          alert("Tipo de arquivo não suportado:");
        }
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetByUserId(objUserTk.UserId);
    };

    fetchData();
  }, []);

  return (
    <>
      <WhatsappButton />
      <UserHeader />
      <LoadingSpinner />
      <AdminNavbar brandText={"MEU PERFIL"} search={false} titleSearch={""} />
      <Container className="mt--3" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-end">
                <Col className="order-lg-2" lg="6">
                  <div className="card-profile-image">
                    <div className="card-candidate-content">
                      <div className="card-profile-image-recruiter ">
                        <img
                          style={{
                            objectFit: "cover",
                            objectPosition: "center center",
                          }}
                          alt="Avatar"
                          className="img-fluid rounded-circle"
                          src={
                            company.photo ||
                            require("../../assets/img/theme/profile.png")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <label className="fileInput">
                    {!editPerfilState && (
                      <Button
                        style={{ color: "#FFF", backgroundColor: "#004C4E" }}
                        size="sm"
                        onClick={handleClickChangePhoto}
                      >
                        Mudar foto
                      </Button>
                    )}
                  </label>
                  <input
                    ref={photoInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      {/* <div>
                        <span className="heading">10</span>
                        <span className="description">Vagas</span>
                      </div> */}
                      {/* <div>
                        <span className="heading">103</span>
                        <span className="description">
                          Candidatos Interessados
                        </span>
                      </div> */}
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>{company.name}</h3>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <div className="col-6 xs-8">
                    <h3 className="mb-0">Minha conta</h3>
                  </div>
                  <Col className="text-right col-6" xs="4">
                  <Button
                      style={{ color: "#FFF", backgroundColor: "#004C4E" }}
                      onClick={(e) => setOpenModalChangePassword(true)}
                      size="sm"
                    >
                      Mudar senha
                    </Button>
                    {editPerfilState ? (
                      <>
                        <Button
                          style={{ color: "#FFF", backgroundColor: "#004C4E" }}
                          onClick={(e) => setEditPerfilState(false)}
                          size="sm"
                        >
                          Editar seu perfil
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          style={{ color: "#FFF", backgroundColor: "#004C4E" }}
                          onClick={handleSalveIndoCompany}
                          size="sm"
                        >
                          Salvar edições
                        </Button>
                        <Button
                          style={{
                            color: "#004C4E",
                            border: " 1px solid #004C4E",
                          }}
                          onClick={() => setEditPerfilState(true)}
                          size="sm"
                        >
                          Cancelar
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    INFORMAÇÃO DO USUÁRIO
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Nome completo
                          </label>
                          <Input
                            name="name"
                            value={company?.name}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Nome"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Empresa
                          </label>
                          <Input
                            name="companyName"
                            value={company?.companyName}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-company-name"
                            placeholder="Nome da empresa"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email Corporativo
                          </label>
                          <Input
                            name="email"
                            value={company?.email}
                            onChange={handleChange}
                            disabled
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="exemple@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">
                    INFORMAÇÃO DE CONTATO
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Endereço
                          </label>
                          <Input
                            name="address"
                            value={company?.address}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Endereço, número"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Cidade
                          </label>
                          <Input
                            name="city"
                            value={company?.city}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-first-city"
                            placeholder="Cidade"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Telefone
                          </label>
                          <MaskedInput
                            name="celPhone"
                            value={company?.celPhone}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="(99) 9 99999999"
                            mask={[
                              "(",
                              /[1-9]/,
                              /\d/,
                              ")",
                              " ",
                              /\d/,
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            render={(ref, props) => (
                              //@ts-ignore
                              <Input innerRef={ref} {...props} />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />

                  {/* <h6 className="heading-small text-muted mb-4">
                    SOBRE A EMPRESA
                  </h6> */}
                  {/* <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Input
                            rows={3}
                            name="description"
                            value={company?.description}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-last-description"
                            placeholder="Fale um pouco mais sobre sua empresa..."
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalChangePassword
        openModal={openModalChangePassword}
        closeModal={() => setOpenModalChangePassword(false)}
      />
    </>
  );
};

export default ProfileRecruiter;
