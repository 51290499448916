import { ReactNode, createContext } from "react";
import useIndicator, { IndicatorType, UserCountType, IncompleteCandidateType } from "./hooks/useIndicator";

interface IndicatorContextProviderProps {
    children: ReactNode;
}

export interface IndicatorContextData {
    handleGetCandidateCountByState: () => Promise<IndicatorType | null>;
    handleGetCandidateCountByCity: () => Promise<IndicatorType | null>;
    handleGetUserCountByType: () => Promise<UserCountType | null>;
    handleGetUserCountByCompanyName: () => Promise<UserCountType | null>;
    handleGetIncompleteCandidate: () => Promise<IncompleteCandidateType | null>;
}

const initialState: IndicatorContextData = {
    handleGetCandidateCountByState: async () => { return Promise.resolve(null); },
    handleGetCandidateCountByCity: async () => { return Promise.resolve(null); },
    handleGetUserCountByType: async () => { return Promise.resolve(null); },
    handleGetUserCountByCompanyName: async () => { return Promise.resolve(null); },
    handleGetIncompleteCandidate: async () => { return Promise.resolve(null); },
};

export const IndicatorContext =
    createContext<IndicatorContextData>(initialState);

export default function IndicatorContextProvider({
    children,
}: IndicatorContextProviderProps) {
    const {
        handleGetCandidateCountByState,
        handleGetCandidateCountByCity,
        handleGetUserCountByType,
        handleGetUserCountByCompanyName,
        handleGetIncompleteCandidate,
    } = useIndicator();

    return (
        <IndicatorContext.Provider
            value={{
                handleGetCandidateCountByState,
                handleGetCandidateCountByCity,
                handleGetUserCountByType,
                handleGetUserCountByCompanyName,
                handleGetIncompleteCandidate,
            }}
        >
            {children}
        </IndicatorContext.Provider>
    );
}
