import { useContext } from "react";
import "./LoadingSpinner.css";
import { LoadingContext } from "../../context/LoadingContext";

const LoadingSpinner = () => {
  const { loading } = useContext(LoadingContext);

  return (
    <div className={`loading-overlay ${loading ? "" : "d-none"}`}>
      <div className="loader"></div>
    </div>
  );
};

export default LoadingSpinner;
