import { useContext } from "react";
import {AuthContext} from "../../context/AuthContext";

const Header = () => {
  const { selectTypeUser } = useContext(AuthContext);
  return (
    <>
      <div className={selectTypeUser === 1 ? "header bg-translucent-pharmacy pb-5 pt-5 pt-md-6" : "header bg-translucent-pharmacy-recruiter pb-5 pt-5 pt-md-6"}>
      </div>
    </>
  );
};

export default Header;
