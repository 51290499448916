import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components
import { useContext, useEffect, useRef, useState } from "react";

import UserHeader from "../../components/Headers/UserHeader/UserHeader";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import TableComponent from "../../components/TableComponent/TableComponent";
import ModalExperience from "../../components/ModalExperience/ModalExperience";
import ModalComponentRecruiter from "../../components/ModalComponentRecruiter/ModalComponentRecruiter";

import { CandidateContext } from "../../context/CandidateContext";
import { AuthContext } from "../../context/AuthContext";
import { ExpertiseContext } from "../../context/ExpertiseContext";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import calculateAge from "../../utils/calculateAge";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import MaskedInput from "react-text-mask";
import { EducationContext } from "../../context/EducationContext";
import ModalEducation from "../../components/ModalEducation/ModalEducation";
import { CoursesContext } from "../../context/CoursesContext";
import ModalCourses from "../../components/ModalCourses/ModalCourses";
import ModalCompetence from "../../components/ModalCompetence/ModalCompetence";
import { CompetenceContext } from "../../context/CompetenceContext";
import ModalChangePassword from "../../components/ModalChangePassword/ModalChangePassword";
import WhatsappButton from "../../components/WhatsappButtom/WhatsappButtom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { EstadosContext } from "../../context/EstadosContext";
import { EstadosType } from "../../context/hooks/useEstados";
import { MunicipiosContext } from "../../context/MunicipiosContext";
import { MunicipiosType } from "../../context/hooks/useMunicipios";

const Profile = () => {
  const { objUserTk } = useContext(AuthContext);
  const { handleGetByUserId, candidate, setCandidate, putCandidate, handleGetByCandidateId } = useContext(CandidateContext);
  const { handleGetAllEstados, estadosResponse } = useContext(EstadosContext);
  const { handleGetAllMunicipios, handleGetMunicipiosPorEstados, municipiosResponse } = useContext(MunicipiosContext);

  const CarLicenseOptions = {
    NaoPossui: 0,
    Provisória: 1,
    Definitiva: 2,
  };

  const {
    getAllExpertiseByCandidateId,
    deleteExpertiseById,
    expertisesTable,
    setFormValues,
    expertises,
  } = useContext(ExpertiseContext);

  const {
    getAllEducationByCandidateId,
    deleteEducationById,
    educationsTable,
    setFormValuesEducation,
    educations,
  } = useContext(EducationContext);

  const {
    getAllCompetenceByCandidateId,
    competenceTable,
    postCompetenceByCandidateId,
    formValuescompetence,
    setFormValuesCompetence,
    putCompetenceById,
    deleteCompetenceById,
    competences
  } = useContext(CompetenceContext);

  const {
    getAllCoursesByCandidateId,
    courses,
    coursesTable,
    postCourseByCandidateId,
    formValuesCourses,
    setFormValuesCourses,
    putCourseById,
    deleteCourseById
  } = useContext(CoursesContext);

  const [openModal, setOpenModal] = useState({ open: false, idEdit: 0 });
  const [openModalDelete, setOpenModalDelete] = useState({
    open: false,
    idDelete: 0,
    type: "",
  });

  const [openModalEducation, setOpenModalEducation] = useState({
    open: false,
    idEdit: 0,
  });

  const [openModalCourses, setOpenModalCourses] = useState({
    open: false,
    idEdit: 0,
  });

  const [openModalCompetence, setOpenModalCompetence] = useState({
    open: false,
    idEdit: 0,
  });

  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const [openModalCurriculum, setOpenModalCurriculum] = useState(false);

  const [editPerfilState, setEditPerfilState] = useState<boolean>(true);

  const photoInputRef = useRef<HTMLInputElement>(null);
  const curriculoInputRef = useRef<HTMLInputElement>(null);

  const [selectedState, setSelectedState] = useState(candidate.state || "");
  const [selectedCity, setSelectedCity] = useState(candidate.city || "");
  const isCityDisabled = selectedState === "";

  const handleGetExperince = (idExperience: number) => {
    if (idExperience) {
      setFormValues(expertises.find((x) => x.id === idExperience));
      setOpenModal({ open: true, idEdit: idExperience });
    } else {
      setOpenModal({ open: true, idEdit: 0 });
    }
  };

  const handleGetEducation = (idEducation: number) => {
    if (idEducation) {
      setFormValuesEducation(educations.find((x) => x.id === idEducation));
      setOpenModalEducation({ open: true, idEdit: idEducation });
    } else {
      setOpenModalEducation({ open: true, idEdit: 0 });
    }
  };

  const handleGetCourses = (idCourse: number) => {
    if (idCourse) {
      setFormValuesCourses(courses.find((x) => x.id === idCourse));
      setOpenModalCourses({ open: true, idEdit: idCourse });
    } else {
      setOpenModalCourses({ open: true, idEdit: 0 });
    }
  }

  const handleGetCompetence = (idCompetence: number) => {
    if (idCompetence) {
      setFormValuesCompetence(competences.find((x) => x.id === idCompetence));
      setOpenModalCompetence({ open: true, idEdit: idCompetence });
    } else {
      setOpenModalCompetence({ open: true, idEdit: 0 });
    }
  }

  const handleDelete = async () => {
    if (openModalDelete.type === "expertise") {
      await deleteExpertiseById(openModalDelete.idDelete);
      await getAllExpertiseByCandidateId(candidate.id);
    }
    if (openModalDelete.type === "education") {
      await deleteEducationById(openModalDelete.idDelete);
      await getAllEducationByCandidateId(candidate.id);
    }
    if (openModalDelete.type === "curses") {
      await deleteCourseById(openModalDelete.idDelete);
      await getAllCoursesByCandidateId(candidate.id);
    }
    if (openModalDelete.type === "competence") {
      await deleteCompetenceById(openModalDelete.idDelete);
      await getAllCompetenceByCandidateId(candidate.id);
    }
    setOpenModalDelete({ open: false, idDelete: 0, type: "" });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      const fileSizeInKB = file.size / 1024;
      const maxSizeKB = 500;

      if (file.type.startsWith("image/") && fileSizeInKB > maxSizeKB) {
        toast.error('O tamanho da imagem é muito grande. Por favor, selecione uma imagem menor que 500 KB.');
        event.target.value = '';
        return;
      }

      reader.onload = (e) => {
        const contentBase64 = e.target?.result as string;

        if (file.type.startsWith("image/")) {
          setCandidate((prevCandidate: any) => ({
            ...prevCandidate,
            photo: contentBase64,
          }));
        } else if (file.type === "application/pdf") {
          setCandidate((prevCandidate: any) => ({
            ...prevCandidate,
            curriculum: `${file.name} + ${contentBase64}`,
          }));
        } else {
          console.warn("Tipo de arquivo não suportado:", file.type);
        }
      };
      reader.readAsDataURL(file);
    }
  };


  const handleClickChangePhoto = () => {
    if (photoInputRef.current) {
      photoInputRef.current.click();
    }
  };

  const handleClickChangeCurriculo = () => {
    if (curriculoInputRef.current) {
      curriculoInputRef.current.click();
    }
  };

  const handleGetUserDetails = (idCandidate: number) => {
    var cardCandidate = handleGetByCandidateId(idCandidate);
    setOpenModalCurriculum((prevState) => !prevState);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type === "radio") {
      if (name === "hasCar") {
        const isChecked = value === "true";
        setCandidate({
          ...candidate,
          hasCar: isChecked,
          carLicense: isChecked ? 0 : undefined,
        });
      } else if (name === "hasPCD") {
        const isChecked = value === "true";
        setCandidate({
          ...candidate,
          hasPCD: isChecked,
        });
      } else if (name === "typeCandidate") {
        const isChecked = parseInt(value);
        setCandidate({
          ...candidate,
          typeCandidate: isChecked,
        });
      }
    } else if (type === "select" && name === "carLicense") {
      setCandidate({
        ...candidate,
        carLicense: parseInt(value),
      });
    }
    else {
      setCandidate({
        ...candidate,
        [name]: value,
      });
    }
  };

  const handleSalveIndoCandidate = () => {
    putCandidate(candidate);
    setEditPerfilState(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await handleGetByUserId(objUserTk.UserId);
      if (result) {
        await Promise.all([
          setSelectedState(result.state || ""),
          setSelectedCity(result.city || ""),
          getAllExpertiseByCandidateId(result.id),
          getAllEducationByCandidateId(result.id),
          getAllCoursesByCandidateId(result.id),
          getAllCompetenceByCandidateId(result.id),
          handleGetMunicipiosPorEstados(result.state || ""),
          handleGetAllEstados(),
        ]);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <ToastContainer />
      <WhatsappButton />
      <UserHeader />
      <LoadingSpinner />
      <AdminNavbar brandText={"MEU PERFIL"} search={false} titleSearch={""} />
      <Container className="mt--3" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-end">
                <Col className="order-lg-2" lg="6">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="card-candidate-content">
                        <div className="card-profile-image-recruiter ">
                          <img
                            style={{
                              objectFit: "cover",
                              objectPosition: "center center",
                            }}
                            alt="Avatar"
                            className="img-fluid rounded-circle"
                            src={
                              candidate.photo ||
                              require("../../assets/img/theme/profile.png")
                            }
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <label className="fileInput">
                    {!editPerfilState && (
                      <Button
                        className="button-custom"
                        style={{ color: "#FFF", backgroundColor: "#640764" }}
                        size="sm"
                        onClick={handleClickChangePhoto}
                      >
                        Mudar foto
                      </Button>
                    )}
                  </label>
                  <input
                    ref={photoInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                        <span className="heading"></span>
                        <span className="description"></span>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                    {candidate.name}
                    <span className="font-weight-light">
                      , {calculateAge(candidate.birthData)} anos
                    </span>
                  </h3>
                </div>
              </CardBody>
            </Card>
            <Card style={{ marginTop: "40px", gap: "10px" }}>
              <CardBody
                className="pt-md-2"
                style={{ backgroundColor: "#004C4E", borderRadius: "6px" }}
              >
                <Row>
                  <div className="col" style={{ color: "#FFF", cursor: "pointer" }} onClick={(e) => handleGetUserDetails(candidate.id)}>
                    <div className="d-flex justify-content-center mt-md-2">
                      <div>
                        Visualizar currículo
                        {/* <span style={{ color: "#FFF", fontSize: "15px" }}>
                          {candidate.curriculum?.split("+")[0] ||
                            "SeuCurriculo.pdf"}
                        </span> */}
                        {/* <Button
                          style={{ color: "#FFF", backgroundColor: "#640764", flexGrow: 1 }}
                          onClick={(e) => handleGetUserDetails(candidate.id)}
                          size="sm"
                        >
                          Visualizar currículo
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
              {!editPerfilState && (
                <>
                  {/* <Button
                    className="button-custom"
                    style={{
                      color: "#FFF",
                      backgroundColor: "#004C4E",
                      width: "35%",
                    }}
                    size="sm"
                    onClick={handleClickChangeCurriculo}
                  >
                    Trocar currículo
                  </Button> */}
                  <input
                    ref={curriculoInputRef}
                    type="file"
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </>
              )}
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <div className="col-6 xs-8">
                    <h3 className="mb-0">Minha conta</h3>
                  </div>
                  <Col className="text-right col-6" xs="4">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      {/* <Button
                        style={{ color: "#FFF", backgroundColor: "#640764", flexGrow: 1 }}
                        onClick={(e) => handleGetUserDetails(candidate.id)}
                        size="sm"
                      >
                        Visualizar currículo
                      </Button> */}
                      <Button
                        style={{ color: "#FFF", backgroundColor: "#640764", flexGrow: 1 }}
                        onClick={(e) => setOpenModalChangePassword(true)}
                        size="sm"
                      >
                        Mudar senha
                      </Button>
                      {editPerfilState ? (
                        <Button
                          style={{ color: "#FFF", backgroundColor: "#640764", flexGrow: 1 }}
                          onClick={(e) => setEditPerfilState(false)}
                          size="sm"
                        >
                          Editar seu perfil
                        </Button>
                      ) : (
                        <>
                          <Button
                            style={{ color: "#FFF", backgroundColor: "#640764", flexGrow: 1 }}
                            onClick={handleSalveIndoCandidate}
                            size="sm"
                          >
                            Salvar edições
                          </Button>
                          <Button
                            style={{
                              color: "#640764",
                              border: " 1px solid #640764",
                              flexGrow: 1
                            }}
                            onClick={() => setEditPerfilState(true)}
                            size="sm"
                          >
                            Cancelar
                          </Button>
                        </>
                      )}
                    </div>
                  </Col>

                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    INFORMAÇÃO DO USUÁRIO
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Nome completo
                          </label>
                          <Input
                            name="name"
                            value={candidate?.name}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Nome"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-birth-date"
                          >
                            Data de nascimento
                          </label>
                          <Input
                            name="birthData"
                            value={candidate?.birthData}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-birth-date"
                            placeholder="Data de nascimento"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-birth-date"
                          >
                            Possui carro próprio
                          </label>
                          {" "}
                          <input
                            name="hasCar"
                            value="true"
                            checked={candidate?.hasCar === true}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            type="radio"
                          />{" "}
                          Sim
                          {" "}
                          <input
                            name="hasCar"
                            value="false"
                            checked={candidate?.hasCar === false}
                            onChange={handleChange}
                            className="form-control-alternative"
                            disabled={editPerfilState}
                            type="radio"
                          />{" "}
                          Não
                          <div>
                            <br />
                            <label className="form-control-label" htmlFor="input-car-license">Status da carteira: </label>
                            {" "}
                            <select
                              name="carLicense"
                              value={candidate?.carLicense}
                              onChange={handleChange}
                              className="form-control-sm"
                              disabled={editPerfilState}
                            >
                              <option value={CarLicenseOptions.NaoPossui}>Não Possui</option>
                              <option value={CarLicenseOptions.Provisória}>Provisória</option>
                              <option value={CarLicenseOptions.Definitiva}>Definitiva</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-birth-date"
                        >
                          Possui alguma deficiência
                        </label>
                        {" "}
                        <input
                          name="hasPCD"
                          value="true"
                          checked={candidate?.hasPCD === true}
                          onChange={handleChange}
                          disabled={editPerfilState}
                          type="radio"
                        />{" "}
                        Sim
                        {" "}
                        <input
                          name="hasPCD"
                          value="false"
                          checked={candidate?.hasPCD === false}
                          onChange={handleChange}
                          className="form-control-alternative"
                          disabled={editPerfilState}
                          type="radio"
                        />{" "}
                        Não
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Endereço de email
                          </label>
                          <Input
                            name="email"
                            value={candidate?.email}
                            onChange={handleChange}
                            disabled
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="example@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Url do Linkedin
                          </label>
                          <Input
                            name="linkedin"
                            value={candidate?.linkedin}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="https://www.linkedin.com/in"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-presentation-video"
                          >
                            Vídeo de apresentação
                          </label>
                          <Input
                            name="presentationVideo"
                            value={candidate?.presentationVideo}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-presentation-video"
                            placeholder="https://www.youtube.com/watch?v=VIDEO_ID"
                            type="url"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-average-advertising"
                          >
                            Vídeo de propaganda médica
                          </label>
                          <Input
                            name="averageAdvertising"
                            value={candidate?.averageAdvertising}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-average-advertising"
                            placeholder="https://www.youtube.com/watch?v=VIDEO_ID"
                            type="url"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">
                    INFORMAÇÃO DE CONTATO
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Endereço
                          </label>
                          <Input
                            name="address"
                            value={candidate?.address}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-addres"
                            placeholder="Endereço, número"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        {/* <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Estado
                          </label>
                          <MaskedInput
                            name="state"
                            value={candidate?.state}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-state"
                            placeholder="Estado"
                            mask={[/[A-Za-z]/, /[A-Za-z]/]}
                            guide={false}
                            render={(ref, props) => (
                              //@ts-ignore
                              <Input innerRef={ref} {...props} />
                            )}
                          />
                        </FormGroup> */}
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-username">
                            Estado
                          </label>
                          <Input
                            type="select"
                            name="state"
                            value={selectedState}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setSelectedState(selectedValue);
                              handleGetMunicipiosPorEstados(selectedValue);
                              setSelectedCity("");
                              handleChange(e);
                            }}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-state"
                          >
                            <option value="">Selecione um estado</option>
                            {estadosResponse.map((estado) => (
                              <option key={estado.codigo_uf} value={estado.uf}>
                                {estado.uf}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        {/* <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Cidade
                          </label>
                          <Input
                            name="city"
                            value={candidate?.city}
                            onChange={handleChange}
                            disabled={editPerfilState || isCityDisabled}
                            className="form-control-alternative"
                            id="input-city"
                            placeholder="Cidade"
                            type="text"
                            list="cities-list"
                          />
                          <datalist id="cities-list">
                            {municipiosResponse.map((municipios) => (
                              <option key={municipios.nome} value={municipios.nome} />
                            ))}
                          </datalist>
                        </FormGroup> */}
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-username">
                            Cidade
                          </label>
                          <Input
                            type="select"
                            id="input-city"
                            name="city"
                            value={selectedCity}
                            onChange={(e) => {
                              setSelectedCity(e.target.value);
                              handleChange(e);
                            }}
                            disabled={editPerfilState || isCityDisabled}
                            className="form-control-alternative"
                          >
                            <option value="">Selecione uma cidade</option>
                            {Array.isArray(municipiosResponse) && municipiosResponse
                              .filter((municipio) =>
                                municipio.nome.toLowerCase().includes(selectedCity.toLowerCase())
                              )
                              .map((municipio) => (
                                <option key={municipio.nome} value={municipio.nome}>
                                  {municipio.nome}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Região
                          </label>
                          <Input
                            name="region"
                            value={candidate?.region}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-region"
                            placeholder="Região"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Documento
                          </label>
                          <MaskedInput
                            name="document"
                            value={candidate?.document}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-document"
                            placeholder="Digite seu CPF"
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                            ]}
                            guide={false}
                            render={(ref, props) => (
                              //@ts-ignore
                              <Input innerRef={ref} {...props} />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Telefone
                          </label>
                          <MaskedInput
                            name="celPhone"
                            value={candidate?.celPhone}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="(99) 9 99999999"
                            mask={[
                              "(",
                              /[1-9]/,
                              /\d/,
                              ")",
                              " ",
                              /\d/,
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            render={(ref, props) => (
                              //@ts-ignore
                              <Input innerRef={ref} {...props} />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">SOBRE VOCÊ</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Input
                            rows={7}
                            name="description"
                            value={candidate?.description}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-last-description"
                            placeholder="Fale um pouco mais sobre você..."
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">Objetivos pessoais</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Input
                            rows={3}
                            name="personalGoal"
                            value={candidate?.personalGoal}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-last-personalGoal"
                            placeholder="Descreva um pouco dos seus objetivos pessoais..."
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />

                  <div className="pl-lg-4">
                    <TableComponent
                      actionDelete={(idExperience: number) =>
                        setOpenModalDelete({
                          open: true,
                          idDelete: idExperience,
                          type: "expertise",
                        })
                      }
                      actionDropdown={handleGetExperince}
                      titleTable="Experiências"
                      columns={expertisesTable.columns}
                      data={expertisesTable.mylist}
                    />
                  </div>
                  <hr className="my-4" />
                  <div className="pl-lg-4">
                    <TableComponent
                      actionDelete={(idEducation: number) =>
                        setOpenModalDelete({
                          open: true,
                          idDelete: idEducation,
                          type: "education",
                        })
                      }
                      actionDropdown={handleGetEducation}
                      titleTable="Formação acadêmica"
                      columns={educationsTable.columns}
                      data={educationsTable.mylist}
                    />
                  </div>
                  <hr className="my-4" />
                  <div className="pl-lg-4">
                    <TableComponent
                      actionDelete={(idCourse: number) =>
                        setOpenModalDelete({
                          open: true,
                          idDelete: idCourse,
                          type: "curses",
                        })
                      }
                      actionDropdown={handleGetCourses}
                      titleTable="Cursos extracurrículares"
                      columns={coursesTable.columns}
                      data={coursesTable.mylist}
                    />
                  </div>
                  <hr className="my-4" />
                  <div className="pl-lg-4">
                    <TableComponent
                      actionDelete={(idCompetence: number) =>
                        setOpenModalDelete({
                          open: true,
                          idDelete: idCompetence,
                          type: "competence",
                        })
                      }
                      actionDropdown={handleGetCompetence}
                      titleTable="Competências"
                      columns={competenceTable.columns}
                      data={competenceTable.mylist}
                    />
                  </div>
                </Form>
                <div className="form-group form-check mt-3">
                  {/* <input
                    type="checkbox"
                    checked={candidate?.typeCandidate == 0}
                    value={candidate?.typeCandidate || 0}
                    onChange={handleChange}
                    disabled={editPerfilState}
                    className="form-check-input"
                    name="typeCandidate"
                  /> */}
                  {/* <label className="form-check-label ml-2">Autorizo mostrar meu currículo aos recrutadores</label> */}
                  <div className="form-group form-check mt-3 p-3 border border-warning rounded bg-light">
                    <div className="mb-2">
                      <label className="form-check-label font-weight-bold text-danger">ATENÇÃO</label>
                    </div>

                    <label className="form-control-label mb-2" htmlFor="input-birth-date">
                      Você autoriza mostrar seu currículo aos recrutadores?
                    </label>
                    {" "}
                    <div className="form-check form-check-inline">
                      <input
                        name="typeCandidate"
                        value="0"
                        checked={candidate?.typeCandidate == 0}
                        onChange={handleChange}
                        disabled={editPerfilState}
                        type="radio"
                        className="form-check-input"
                      />
                      <label className="form-check-label">Sim</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        name="typeCandidate"
                        value="2"
                        checked={candidate?.typeCandidate == 2}
                        onChange={handleChange}
                        className="form-check-input"
                        disabled={editPerfilState}
                        type="radio"
                      />
                      <label className="form-check-label">Não</label>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalConfirm
          openModal={openModalDelete.open}
          closeModal={() =>
            setOpenModalDelete({ open: false, idDelete: 0, type: "" })
          }
          handleConfirm={handleDelete}
        />
      </Container>
      <ModalExperience
        closeModal={() => setOpenModal({ open: false, idEdit: 0 })}
        openModal={openModal}
      />
      <ModalEducation
        closeModal={() => setOpenModalEducation({ open: false, idEdit: 0 })}
        openModal={openModalEducation}
      />
      <ModalCourses
        closeModal={() => setOpenModalCourses({ open: false, idEdit: 0 })}
        openModal={openModalCourses}
      />
      <ModalCompetence
        closeModal={() => setOpenModalCompetence({ open: false, idEdit: 0 })}
        openModal={openModalCompetence}
      />
      <ModalChangePassword
        openModal={openModalChangePassword}
        closeModal={() => setOpenModalChangePassword(false)}
      />
      <ModalComponentRecruiter
        openModal={openModalCurriculum}
        closeModal={() => setOpenModalCurriculum(false)}
      />
    </>
  );
};

export default Profile;
