import { ReactNode, createContext } from "react";
import useAnnotation, { AnnotationType } from "./hooks/useAnnotation";

interface AnnotationContextProviderProps {
    children: ReactNode;
}

export interface AnnotationContextData {
    handleGetAnnotation: (userId: number, candidateId: number) => Promise<AnnotationType | null>;
    addAnnotation: (annotation: AnnotationType) => Promise<AnnotationType | null>;
    putAnnotation: (annotation: AnnotationType) => Promise<AnnotationType | null>;
    deleteAnnotation: (annotation: AnnotationType) => Promise<AnnotationType | null>;
    annotation: AnnotationType;
    setAnnotation: any;
}

const initialState: AnnotationContextData = {
    handleGetAnnotation: async (userId: number, candidateId: number) => {
        return Promise.resolve(null);
    },
    addAnnotation: async (annotation: AnnotationType) => {
        return Promise.resolve(null);
    },
    putAnnotation: async (annotation: AnnotationType) => {
        return Promise.resolve(null);
    },
    deleteAnnotation: async (annotation: AnnotationType) => {
        return Promise.resolve(null);
    },
    annotation: {
        candidateId: 0,
        userId: 0,
        description: "",
        creationDate: "",
        lastUpdateDate: ""
    },
    setAnnotation: () => { },
};

export const AnnotationContext =
    createContext<AnnotationContextData>(initialState);

export default function AnnotationContextProvider({
    children,
}: AnnotationContextProviderProps) {
    const {
        handleGetAnnotation,
        addAnnotation,
        putAnnotation,
        deleteAnnotation,
        annotation,
        setAnnotation
    } = useAnnotation();

    return (
        <AnnotationContext.Provider
            value={{
                handleGetAnnotation,
                addAnnotation,
                putAnnotation,
                deleteAnnotation,
                annotation,
                setAnnotation
            }}
        >
            {children}
        </AnnotationContext.Provider>
    );
}
