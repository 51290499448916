import { CardBody, Row, Button } from "reactstrap";
import "./CardsJobDetails.css";
import { useContext } from "react";
import { JobContext } from "../../context/JobContext";

interface CardsJobsProps {
  openJob: () => void;
}

const CardsJobs = (props: CardsJobsProps) => {
  const {
    openJob,
  } = props;

  const { jobSelectCard } = useContext(JobContext)


  return (
    <>
      <CardBody style={{height: "100%"}}>
        <Row className="" style={{ marginLeft: "40px", position: "sticky", top: "50px", minHeight: "100vh" }}>
          {/* @ts-ignore */}
          <div lg="8" md="6">
            <div className="card-list-jobs-detalis" tabIndex={0}>
              <div
                id="tooltip9332484"
                className="container-card-list-jobs-detalis"
              >
                <div className="content-card-list-jobs-detalis">
                  {jobSelectCard?.jobTitle}
                </div>
                <div className="cotent-info-local-jobs-detalis">
                  <div className="content-name-company-detalis">
                    {jobSelectCard?.companyName}
                  </div>
                  <div className="">{jobSelectCard?.location}</div>
                  {/* <div className="">
                    R$ 1.250,00 a R$ 2.500,00 (Bruto mensal)
                  </div> */}
                  <div className="">{jobSelectCard?.workModel}</div>
                  <div className="">{jobSelectCard?.jobType}</div>
                </div>
                <Button
                  className="button-custom-apply-job"
                  href="#pablo"
                  onClick={openJob}
                >
                  <i className="ni ni-curved-next"></i>
                  APLICAR
                </Button>
                <div>
                  <div className="mb-2 filter-job-details-about">
                    {/* <div className="filter-item-job-details-about" tabIndex={0}>
                      VAGA
                    </div> */}
                    {/* <div className="filter-item-job-details-about" tabIndex={0}>
                      EMPRESA
                    </div> */}
                  </div>
                  <hr className="line-filter-job-details-about" />
                </div>
                <div className="cotent-desc-local-jobs-detalis">
                  {jobSelectCard?.jobTitle}
                  <br />
                  <br />
                  {jobSelectCard?.description}
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Row>
      </CardBody>
    </>
  );
};

export default CardsJobs;
