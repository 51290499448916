import React, { useContext, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'; 

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin/Admin";
import AuthLayout from "./layouts/Auth/Auth";
import AuthContextProvider, { AuthContext } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastContext";
import CandidateContextProvider from "./context/CandidateContext";
import ExpertiseContext from "./context/ExpertiseContext";
import CompanyContextProvider from "./context/CompanyContext";
import LoadingContextProvider from "./context/LoadingContext";
import JobContextProvider from "./context/JobContext";
import EducationContext from "./context/EducationContext";
import FavoriteContextProvider from "./context/FavoriteContext";
import IndicatorContextProvider from "./context/IndicatorContext";
import AnnotationContextProvider from "./context/AnnotationContext";
import CoursesContextProvider from "./context/CoursesContext";
import CompetenceContextProvider from "./context/CompetenceContext";
import ChatGptContextProvider from "./context/ChatGptContext";
import EstadosContextProvider from "./context/EstadosContext";
import MunicipiosContextProvider from "./context/MunicipiosContext";

const rootElement =
  document.getElementById("root") ?? document.createElement("div");
const root = ReactDOM.createRoot(rootElement);

function CustomRoute() {
  const { authenticated, loadingButton, handleLogout } = useContext(AuthContext);
  const [showUpdateModal, setShowUpdateModal] = useState(false); 

  setInterval(() => {
    handleLogout();
  }, 60 * 60 * 1000);

  if (loadingButton) {
    return <Route>Carregando...</Route>;
  }

  // const configuration = {
  //   onUpdate: (registration: ServiceWorkerRegistration) => {
  //     if (registration && registration.waiting) {
  //       if (window.confirm('Uma nova versão do aplicativo está disponível. Deseja recarregar a página para aplicar as alterações?')) {
  //         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  //         window.location.reload();
  //       }
  //       else {
  //         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  //         window.location.reload();
  //       }
  //     }
  //   }
  // };

  const configuration = {
    onUpdate: (registration: ServiceWorkerRegistration) => {
      if (registration && registration.waiting) {
        setShowUpdateModal(true); 
      }
    }
  };

  serviceWorkerRegistration.register(configuration);

  const handleUpdateConfirmation = () => {
    serviceWorkerRegistration.unregister();
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName); 
      });
    });
    window.location.reload(); 
    handleLogout();
  };

  if (!authenticated || loadingButton) {
    return (
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />;
        <Route path="*" element={<Navigate to="/auth/" replace />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="*" element={<Navigate to="/admin/" replace />} />
      </Routes>
      <Modal isOpen={showUpdateModal}>
        <ModalBody>
          Uma nova versão do aplicativo está disponível. Deseja recarregar a página para aplicar as alterações?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateConfirmation}>OK</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

root.render(
  <BrowserRouter>
    <ToastProvider>
      <LoadingContextProvider>
        <AuthContextProvider>
          <FavoriteContextProvider>
            <IndicatorContextProvider>
              <CandidateContextProvider>
                <AnnotationContextProvider>
                  <CompanyContextProvider>
                    <ExpertiseContext>
                      <CoursesContextProvider>
                        <CompetenceContextProvider>
                          <EducationContext>
                            <ChatGptContextProvider>
                              <EstadosContextProvider>
                                <MunicipiosContextProvider>
                                  <JobContextProvider>
                                    <CustomRoute />
                                  </JobContextProvider>
                                </MunicipiosContextProvider>
                              </EstadosContextProvider>
                            </ChatGptContextProvider>
                          </EducationContext>
                        </CompetenceContextProvider>
                      </CoursesContextProvider>
                    </ExpertiseContext>
                  </CompanyContextProvider>
                </AnnotationContextProvider>
              </CandidateContextProvider>
            </IndicatorContextProvider>
          </FavoriteContextProvider>
        </AuthContextProvider>
      </LoadingContextProvider>
    </ToastProvider>
  </BrowserRouter >
);