import * as React from "react";
import { SVGProps } from "react";

const IconeHeart = ({ fill }: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
    >
      <path
        d="M12.119 18.643L11.999 18.7629L11.867 18.643C6.16702 13.4757 2.39902 10.0589 2.39902 6.59401C2.39902 4.19619 4.19902 2.39782 6.59902 2.39782C8.44702 2.39782 10.247 3.59673 10.883 5.22725H13.115C13.751 3.59673 15.551 2.39782 17.399 2.39782C19.799 2.39782 21.599 4.19619 21.599 6.59401C21.599 10.0589 17.831 13.4757 12.119 18.643ZM17.399 0C15.311 0 13.307 0.971117 11.999 2.49373C10.691 0.971117 8.68702 0 6.59902 0C2.90302 0 -0.000976562 2.88937 -0.000976562 6.59401C-0.000976562 11.1139 4.07902 14.8185 10.259 20.4174L11.999 22L13.739 20.4174C19.919 14.8185 23.999 11.1139 23.999 6.59401C23.999 2.88937 21.095 0 17.399 0Z"
        fill="white"
      />
    </svg>
  );
};

export default IconeHeart;
