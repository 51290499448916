import "./ModalConfirm.css";

interface ModalComponentProps {
  openModal: boolean;
  closeModal: () => void;
  handleConfirm: () => void;
}

const ModalConfirm = ({
  openModal,
  closeModal,
  handleConfirm,
}: ModalComponentProps) => {
  return (
    <>
      {openModal && (
        <>
          <div
            id="myModal"
            className="modal fade show"
            tabIndex={-1}
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <div className="icon-box">
                    <i style={{fontSize: "49px"}} className="ni ni-fat-remove"></i>
                  </div>
                  <h4 className="modal-title w-100">Tem certeza ?</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={closeModal}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Você realmente deseja excluir esse registro? Este processo
                    não pode ser desfeito.
                  </p>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={closeModal}
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleConfirm}
                    type="button"
                    className="btn btn-danger"
                  >
                    Deletar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
};

export default ModalConfirm;
