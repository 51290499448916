import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface AnnotationType {
    candidateId: number,
    userId: number,
    description?: string,
    creationDate?: string,
    lastUpdateDate?: string
}

export default function useAnnotation() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext)
    const { handleLogout } = useContext(AuthContext)
    const [annotation, setAnnotation] = useState<AnnotationType>({
        candidateId: 0,
        userId: 0,
        description: "",
        creationDate: "",
        lastUpdateDate: ""
    });

    function handleGetAnnotation(userId: number, candidateId: number): Promise<AnnotationType | null> {
        setLoading(true);
        return api
            .get(`/Annotation/get-annotations/${userId}/${candidateId}`)
            .then((response) => {
                const annotation: AnnotationType = response.data;
                setAnnotation(annotation);
                setLoading(false);
                return annotation;
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do usuário:", error);
                return null;
            });
    }

    function addAnnotation(annotation: AnnotationType): Promise<AnnotationType | null> {
        setLoading(true);
        console.log("annotation: ", annotation);
        return api
            .post(`/Annotation/add-annotations`, annotation)
            .then((response) => {
                showToast("success", "Anotação adicionada com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao adicionar anotação. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do usuário:", error);
                return null;
            });
    }

    function putAnnotation(annotation: AnnotationType): Promise<AnnotationType | null> {
        setLoading(true);
        return api
            .put(`/Annotation/update-annotations`, annotation)
            .then((response) => {
                showToast("success", "Anotação atualizada com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar anotação. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do usuário:", error);
                return null;
            });
    }

    function deleteAnnotation(annotation: AnnotationType): Promise<AnnotationType | null> {
        setLoading(true);
        return api
            .post(`/Annotation/delete-annotations`, annotation)
            .then((response) => {
                showToast("success", "Anotação deletada com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao deletar anotação. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do usuário:", error);
                return null;
            });
    }

    return { 
        annotation, 
        setAnnotation, 
        handleGetAnnotation, 
        addAnnotation,
        putAnnotation,
        deleteAnnotation
    };
}
