import { Fragment, useContext, useEffect, useState } from "react";
import { Container, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Header from "../../components/Headers/Header";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import WhatsappButton from "../../components/WhatsappButtom/WhatsappButtom";
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import useUser, { CreateUserType } from "../../context/hooks/useUser";
import MaskedInput from "react-text-mask";
import CsvUploadModal from "../../components/CsvUploadModal/CsvUploadModal";

const AddUser = () => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<CreateUserType>({
        id: 0,
        name: "",
        companyName: "",
        email: "",
        celPhone: "",
        username: "",
        password: "",
        status: 1,
        type: 0,
        expirationDate: "",
        typeCandidate: 0
    });
    const [users, setUsers] = useState<CreateUserType[]>([]);
    const [editingUser, setEditingUser] = useState<CreateUserType | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);

    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [dataSearch, setDataSearch] = useState({ name: "" });

    const {
        createUsersFromCsv,
        createUser,
        getAllUsers,
        getAllUsersWithTypeCandidate,
        updateUser,
        updateUserWithType,
        listUsers,
        initialListUsers,
        pageCount
    } = useUser();

    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const toggleErrorModal = () => setErrorModal(!errorModal);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const formattedValue = name === 'expirationDate' ? value.replace(/\//g, '') : value;

        if (userData.type === 3) {
            setUserData(prevData => ({
                ...prevData,
                type: 1,
                typeCandidate: 2
            }))
        }

        setUserData(prevData => ({
            ...prevData,
            [name]: formattedValue,
            username: name === 'name' ? value : prevData.username
        }));
    };

    useEffect(() => {
        setLoading(true);
        getAllUsersWithTypeCandidate(1)
            .then(data => {
                setUsers(data || []);
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar usuários:", error);
                setLoading(false);
            });
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        createUser(userData)
            .then(newUser => {
                setLoading(false);
                if (newUser) {
                    toggleSuccessModal();
                } else {
                    toggleErrorModal();
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("Erro ao criar usuário:", error);
                setErrorMessage(error.message || "Erro desconhecido");
                toggleErrorModal();
            });
    };

    const handleEditUser = (user: CreateUserType) => {
        setEditingUser(user);
        setEditModalOpen(true);
    };

    const onChangePaginationCommon = async (e: any, value: any) => {

        await getAllUsersWithTypeCandidate(value, dataSearch.name);
    };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentPage(1); 
        setLoading(true);
        getAllUsersWithTypeCandidate(1, dataSearch.name)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingUser) {
            updateUserWithType(editingUser)
                .then(updatedUser => {
                    if (updatedUser) {
                        console.log("Usuário atualizado com sucesso:", updatedUser);
                        toggleSuccessModal();
                    } else {
                        console.error("Erro ao atualizar usuário.");
                        toggleErrorModal();
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar usuário:", error);
                    toggleErrorModal();
                });
        }
    };

    const handleExpirationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;

        if (checked) {
            setEditingUser(prevUser => ({
                ...prevUser!,
                expirationDate: "vitalício",
            }));
        } else {
            setEditingUser(prevUser => ({
                ...prevUser!,
                [name]: value.replace(/\//g, ''),
            }));
        }
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditingUser(prevUser => ({
            ...prevUser!,
            [name]: value,
        }));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataSearch({ ...dataSearch, name: e.target.value });
    };

    return (
        <>
            <WhatsappButton />
            {loading && <LoadingSpinner />}
            <Header />
            <AdminNavbar
                brandText={"Adicionar Usuário"}
                search={false}
                titleSearch={""}
            />
            <Container className="mt-4">
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-first-name">Nome</Label>
                        <Input className="form-control-alternative" type="text" name="name" id="name" value={userData.name} placeholder="Username" onChange={handleInputChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-email">Email</Label>
                        <Input className="form-control-alternative" type="email" name="email" id="email" value={userData.email} placeholder="example@example.com" onChange={handleInputChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-celPhone">Telefone</Label>
                        <Input className="form-control-alternative" type="tel" name="celPhone" id="celPhone" value={userData.celPhone} placeholder="(99) 9 99999999" onChange={handleInputChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-date">Data de expiração</Label>
                        <div className="d-flex align-items-center">
                            <Input
                                className="form-control-alternative w-25 mr-2"
                                type="date"
                                name="expirationDate"
                                id="expirationDate"
                                value={userData.expirationDate}
                                onChange={handleInputChange}
                            />
                            <Label check className="ml-5">
                                <Input
                                    type="checkbox"
                                    name="expirationDate"
                                    value="vitalício"
                                    onChange={handleInputChange}
                                />{' '}
                                Vitalício
                            </Label>
                        </div>
                    </FormGroup>
                    {/* <FormGroup>
                        <Label className="form-control-label" htmlFor="input-password">Senha</Label>
                        <Input className="form-control-alternative" type="password" name="password" id="password" value={userData.password} placeholder="Password" onChange={handleInputChange} required />
                    </FormGroup> */}
                    <FormGroup tag="fieldset">
                        <legend className="form-control-label">Tipo de Usuário</legend>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="type"
                                    value="3"
                                    checked={userData.type == 3}
                                    onChange={handleInputChange}
                                />{' '}
                                Candidato externo
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="type"
                                    value="1"
                                    checked={userData.type == 1}
                                    onChange={handleInputChange}
                                />{' '}
                                Candidato
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="type"
                                    value="2"
                                    checked={userData.type == 2}
                                    onChange={handleInputChange}
                                />{' '}
                                Empresa
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    {userData.type == 2 && (
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-company-name">Nome da Empresa</Label>
                            <Input className="form-control-alternative" type="text" name="companyName" id="companyName" value={userData.companyName} placeholder="Nome da Empresa" onChange={handleInputChange} />
                        </FormGroup>
                    )}
                    <div className="d-flex justify-content-end">
                        <Button style={{ color: "#FFF", backgroundColor: "#004C4E" }} type="submit" size="m">Adicionar usuário</Button>
                        <CsvUploadModal />
                    </div>
                </Form>
            </Container>

            <hr />

            <Container className="mt-5">
                <Row>
                    <Col xs="6">
                        <form className="d-flex" onSubmit={handleSearchSubmit}>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="search"
                                id="search"
                                placeholder="Buscar por nome"
                                value={dataSearch.name}
                                onChange={handleSearchChange}
                                style={{ width: "75%" }}
                            />
                            <Button style={{ color: "#FFF", backgroundColor: "#004C4E" }} type="submit" size="m">Buscar</Button>
                        </form>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Tipo</th>
                                    <th>Data de expiração</th>
                                    <th>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listUsers.map((user: CreateUserType, index: number) => (
                                    <tr key={index}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.typeCandidate == 2 ? "Candidato Externo" : user.typeCandidate == 0 ? "Candidato" : "Empresa"}</td>
                                        <td>{user.expirationDate ?? "Vitalício"}</td>
                                        <td>
                                            <Button onClick={() => handleEditUser(user)}><i className="fa-solid fa-pen-to-square"></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {/* <Button disabled={currentPage === 1} onClick={paginatePrev}>Anterior</Button>
                            <span className="mx-3">Página {currentPage}</span>
                            <Button disabled={currentPage === Math.ceil(users.length / usersPerPage)} onClick={paginateNext}>Próxima</Button> */}
                            {/* <nav aria-label="...">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem disabled={currentPage === 1}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={paginatePrev}
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem className="active">
                                        <PaginationLink href="#pablo">{currentPage}</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={currentPage === Math.ceil(users.length / usersPerPage)}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={paginateNext}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </nav> */}
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listUsers.length === 0 && (
                                <div style={{ color: "red" }}>ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Modal de sucesso */}
            <Modal isOpen={successModal} toggle={toggleSuccessModal}>
                <ModalHeader toggle={toggleSuccessModal}>Sucesso</ModalHeader>
                <ModalBody>
                    A operação foi concluída com sucesso.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleSuccessModal}>OK</Button>
                </ModalFooter>
            </Modal>

            {/* Modal de erro */}
            <Modal isOpen={errorModal} toggle={toggleErrorModal}>
                <ModalHeader toggle={toggleErrorModal}>Erro</ModalHeader>
                <ModalBody>
                    {errorMessage || "Ocorreu um erro durante a operação."}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleErrorModal}>OK</Button>
                </ModalFooter>
            </Modal>

            {/* Modal de edição */}
            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)}>
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Editar Usuário</ModalHeader>
                <ModalBody>
                    {editingUser && (
                        <Form onSubmit={handleEditSubmit}>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-first-name">Nome</Label>
                                <Input className="form-control-alternative" type="text" name="name" id="name" value={editingUser?.name || ''} placeholder="Nome" onChange={handleEditInputChange} required />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-email">Email</Label>
                                <Input className="form-control-alternative" type="email" name="email" id="email" value={editingUser?.email || ''} placeholder="Email" onChange={handleEditInputChange} required />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-password">Senha</Label>
                                <Input className="form-control-alternative" type="password" name="password" id="password" placeholder="Senha" onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-expiration">Data de expiração</Label>
                                <Input className="form-control-alternative" type="date" name="expirationDate" id="expirationDate" value={editingUser?.expirationDate != null ? editingUser.expirationDate : ''} onChange={handleExpirationDateChange} />
                                <div className="ml-3 mt-2">
                                    <Input
                                        className="form-control-alternative"
                                        type="checkbox"
                                        name="expirationDate"
                                        value="vitalício"
                                        checked={editingUser?.expirationDate == null || editingUser?.expirationDate == "vitalício"}
                                        onChange={handleExpirationDateChange}
                                    />{' '}
                                    <Label>Vitalício</Label>
                                </div>
                            </FormGroup>
                            {editingUser.type != 2 && (
                                <FormGroup tag="fieldset">
                                    <legend className="form-control-label">Tipo de Usuário</legend>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="typeCandidate"
                                                value="2"
                                                checked={editingUser?.typeCandidate == 2}
                                                onChange={handleEditInputChange}
                                            />{' '}
                                            Candidato externo
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="typeCandidate"
                                                value="0"
                                                checked={editingUser?.typeCandidate == 0}
                                                onChange={handleEditInputChange}
                                            />{' '}
                                            Candidato
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            )}
                            <div className="d-flex justify-content-end">
                                <Button style={{ color: "#FFF", backgroundColor: "#004C4E" }} type="submit" size="m">Salvar alterações</Button>
                            </div>
                        </Form>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default AddUser;
