import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { CandidateContext } from "../CandidateContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface EducationType {
  startDate: string;
  endDate: string;
  educationalInstitution: string;
  course: string;
  candidate?: any;
  id?: number;
}

export default function useEducation() {
  const showToast = useToast();
  const {setLoading} = useContext(LoadingContext)
  const { handleLogout } = useContext(AuthContext)
  const [educations, setEducations] = useState<EducationType[]>([]);
  const [educationsTable, setEducationsTable] = useState<any>({
    mylist: [],
    columns: [],
  });

  const [formValuesEducation, setFormValuesEducation] = useState<EducationType>({
    endDate: "",
    startDate: "",
    educationalInstitution: "",
    course: ""
  });

  const { candidate } = useContext(CandidateContext);

  function getAllEducationByCandidateId(
    candidateId: number
  ): Promise<EducationType[] | null> {
    setLoading(true);
    return api
      .get(`/Education/get-educations-candidate/${candidateId}`)
      .then((response) => {
        const educations: EducationType[] = response.data;
        setEducations(educations);
        createNewListTable(educations);
        setLoading(false);
        return educations;
      })
      .catch((error) => {
        showToast(
          "error",
          "Erro ao obter informações das Formações acadêmicas. Verifique novamente mais tarde.",
          error
        );
        setLoading(false);
        console.error("Erro ao obter informações", error);
        return null;
      });
  }

  function postEducationByCandidateId(
    education: EducationType
  ): Promise<void | null> {
    setLoading(true);
    return api
      .post(`/Education/create?candidateId=${candidate.id}`, education)
      .then((response) => {
        showToast("success", "Formação acadêmica cadastrada com sucesso.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          handleLogout()
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao adicionar Formação acadêmica. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao adicionar informações da Formação acadêmica:", error);
        return null;
      });
  }

  function putEducationById(
    idEducation: number,
    education: EducationType
  ): Promise<void | null> {
    setLoading(true);
    return api
      .put(`/Education/${idEducation}`, education)
      .then((response) => {
        showToast("success", "Formação acadêmica atualizada com sucesso.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          handleLogout()
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao atualizar Formação acadêmica. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao atualizar informações da Formação acadêmica:", error);
        return null;
      });
  }

  function deleteEducationById(
    idEducation: number,
  ): Promise<void | null> {
    setLoading(true);
    return api
      .delete(`/Education/${idEducation}`)
      .then((response) => {
        showToast("success", "Formação acadêmica deletada com sucesso.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          handleLogout()
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao deletada Formação acadêmica. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao deletada a Formação acadêmica:", error);
        return null;
      });
  }

  const createNewListTable = (Educations: EducationType[]) => {
    if (Educations && Educations.length > 0) {
      const newList = Educations.map((item: EducationType) => {
        return {
          column1: item.educationalInstitution,
          column2: item.course,
          column3: item.startDate
            ? item.startDate.split("-").reverse().join("/")
            : "",
          column4: item.endDate
            ? item.endDate.split("-").reverse().join("/")
            : "",
          id: item.id,
        };
      });

      const columns = [
        "INSTITUIÇÃO DE ENSINO",
        "CURSO",
        "DATA INICIO",
        "DATA FIM",
        "",
      ];

      setEducationsTable({ mylist: newList, columns: columns });
    } else {
      setEducationsTable({ mylist: [], columns: [] });
    }
  };

  return {
    getAllEducationByCandidateId,
    educations,
    educationsTable,
    postEducationByCandidateId,
    formValuesEducation,
    setFormValuesEducation,
    putEducationById,
    deleteEducationById
  };
}
