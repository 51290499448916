import { ReactNode, createContext } from "react";
import useCourses, { CourseType } from "./hooks/useCourses";

interface CoursesContextProviderProps {
    children: ReactNode;
}

export interface CoursesContextData {
    getAllCoursesByCandidateId: (
        candidateId: number
    ) => Promise<CourseType[] | null>;
    postCourseByCandidateId: (
        curses: CourseType
    ) => Promise<void | null>;
    putCourseById: (
        idEducation: number,
        curses: CourseType
    ) => Promise<void | null>;
    deleteCourseById: (
        idEducation: number,
    ) => Promise<void | null>;
    courses: CourseType[];
    coursesTable: any;
    formValuesCourses: CourseType;
    setFormValuesCourses: any;
}

const initialState: CoursesContextData = {
    getAllCoursesByCandidateId: async (candidateId: number) => {
        return Promise.resolve(null);
    },
    postCourseByCandidateId: async (courses: CourseType) => {
        return Promise.resolve(null);
    },
    putCourseById: async (idEducation: number, curses: CourseType) => {
        return Promise.resolve(null);
    },
    deleteCourseById: async (idEducation: number) => {
        return Promise.resolve(null);
    },
    courses: [],
    coursesTable: [],
    formValuesCourses: {
        endDate: "",
        startDate: "",
        educationalInstitution: "",
        extracurricularCourse: ""
    },
    setFormValuesCourses: () => {},
};

export const CoursesContext =
    createContext<CoursesContextData>(initialState);

export default function CoursesContextProvider({
    children,
}: CoursesContextProviderProps) {
    const {
        getAllCoursesByCandidateId,
        courses,
        coursesTable,
        postCourseByCandidateId,
        formValuesCourses,
        setFormValuesCourses,
        putCourseById,
        deleteCourseById
    } = useCourses();

    return (
        <CoursesContext.Provider
            value={{
                getAllCoursesByCandidateId,
                courses,
                coursesTable,
                postCourseByCandidateId,
                formValuesCourses,
                setFormValuesCourses,
                putCourseById,
                deleteCourseById
            }}
        >
            {children}
        </CoursesContext.Provider>
    );
}
