import { ReactNode, createContext } from "react";
import useMunicipios, { MunicipiosType } from "./hooks/useMunicipios";

interface MunicipiosContextProviderProps {
    children: ReactNode;
}

export interface MunicipiosContextData {
    handleGetAllMunicipios: () => Promise<MunicipiosType[] | null>;
    handleGetMunicipiosPorEstados: (
        uf: string
    ) => Promise<MunicipiosType[] | null>;
    municipiosResponse: MunicipiosType[];
}

const initialState: MunicipiosContextData = {
    handleGetAllMunicipios: async () => {
        return Promise.resolve(null);
    },
    handleGetMunicipiosPorEstados: async () => {
        return Promise.resolve(null);
    },
    municipiosResponse: [],
};

export const MunicipiosContext =
    createContext<MunicipiosContextData>(initialState);

export default function MunicipiosContextProvider({
    children,
}: MunicipiosContextProviderProps) {
    const {
        handleGetAllMunicipios,
        handleGetMunicipiosPorEstados,
        municipiosResponse,
    } = useMunicipios();

    return (
        <MunicipiosContext.Provider
            value={{
                handleGetAllMunicipios,
                handleGetMunicipiosPorEstados,
                municipiosResponse,
            }}
        >
            {children}
        </MunicipiosContext.Provider>
    );
}
