import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { ExpertiseType } from "./useExpertise";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";
import { EducationType } from "./useEducation";
import { CourseType } from "./useCourses";
import { CompetenceType } from "./useCompetence";
import { get, List } from "lodash";
const XLSX = require('xlsx');

export interface CandidateType {
  name: string;
  document: string;
  email: string;
  celPhone: string;
  address: string;
  birthData: string;
  user: any;
  description: string;
  city: string;
  region: string;
  photo: any;
  state: string;
  typeCandidate: number;
  curriculum: any;
  linkedin: string;
  hasCar: boolean;
  carLicense: number;
  personalGoal: string;
  hasPCD: boolean;
  isActive: number;
  presentationVideo: string;
  averageAdvertising: string;
  id: number;
  updatedDate: string;
  competences?: CompetenceType[];
  expertises?: ExpertiseType[];
  educations?: EducationType[];
  extracurricularCourses?: CourseType[];
}

export default function useCandidate() {
  const showToast = useToast();
  const { setLoading } = useContext(LoadingContext);
  const { objUserTk } = useContext(AuthContext);
  const [candidate, setCandidate] = useState<CandidateType>({
    name: "",
    document: "",
    email: "",
    celPhone: "",
    address: "",
    description: "",
    birthData: "",
    typeCandidate: 0,
    presentationVideo: "",
    averageAdvertising: "",
    city: "",
    state: "",
    region: "",
    user: {},
    photo: "",
    expertises: [],
    educations: [],
    extracurricularCourses: [],
    competences: [],
    curriculum: "",
    linkedin: "",
    hasCar: false,
    carLicense: 0,
    personalGoal: "",
    hasPCD: false,
    isActive: 0,
    id: 0,
    updatedDate: "",
  });

  const [listCandidate, setListCandidate] = useState<CandidateType[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [initialListCandidate, setInitialListCandidate] = useState<CandidateType[]>([]);
  const { handleLogout } = useContext(AuthContext);

  function handleGetByCandidateId(id: number): Promise<CandidateType | null> {
    setLoading(true);
    return api
      .get(`/Candidate/getcandidateid/${id}`)
      .then((response) => {
        const candidate: CandidateType = response.data;
        setCandidate(candidate);
        setLoading(false);
        return candidate;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao obter informações do candidato:", error);
        if (error?.response?.status == 401) {
          handleLogout();
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao obter informações do candidato. Verifique novamente mais tarde.",
            error
          );
        }
        return null;
      });
  }

  function handleGetByUserId(id: number): Promise<CandidateType | null> {
    setLoading(true);
    return api
      .get(`/Candidate/get-candidate-userid/${id}`)
      .then((response) => {
        const candidate: CandidateType = response.data;
        setCandidate(candidate);
        setLoading(false);
        return candidate;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao obter informações do usuário:", error);
        if (error?.response?.status == 401) {
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
          handleLogout();
        } else {
          showToast(
            "error",
            "Erro ao obter informações do candidato. Verifique novamente mais tarde.",
            error
          );
        }
        return null;
      });
  }

  function handleGetFavoriteCandidateAPI(ids: number[]): Promise<CandidateType[] | null> {
    setLoading(true);
    return api
      .post(`/Candidate/get-favorites`, ids)
      .then((response) => {
        const candidate: CandidateType[] = response.data;
        setListCandidate(candidate);
        setLoading(false);
        return candidate;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao obter candidatos favoritos: ", error);
        if (error?.response?.status == 401) {
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
          handleLogout();
        } else {
          showToast(
            "error",
            "Erro ao obter candidatos favoritos. Verifique novamente mais tarde.",
            error
          );
        }
        return null;
      });
  }

  function handleGetCvCandidate(idCandidate: number): Promise<any | null> {
    setLoading(true);
    return api
      .post(`/Candidate/GeneratePdfProfile`, {candidateId: idCandidate, typeProfile: objUserTk.Type}, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Curriculo_${candidate.name}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
        return response.data;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao obter currículo do candidato: ", error);
        if (error?.response?.status == 401) {
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
          handleLogout();
        } else {
          showToast(
            "error",
            "Erro ao obter currículo do candidato. Verifique novamente mais tarde.",
            error
          );
        }
        return null;
      });
  }
  
  function putCandidate(
    candidate: CandidateType
  ): Promise<CandidateType | null> {
    setLoading(true);
    return api
      .put(`/Candidate/${candidate.id}`, candidate)
      .then((response) => {
        showToast("success", "Perfil atualizado com sucesso.");
        setLoading(false);
        return null;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao obter informações do usuário:", error);
        if (error?.response?.status == 401) {
          handleLogout();
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao atualizar perfil. Verifique novamente mais tarde.",
            error
          );
        }
        return null;
      });
  }

  function getAllCandidatesPaged(
    pageNumber: number,
    city?: string,
    keyword?: string,
    educationStatus?: string,
    hasCar?: boolean,
    hasPCD?: boolean,
    distance?: number,
    state?: string,
    cityReference?: string,
  ): Promise<CandidateType[] | null> {
    setLoading(true);

    let url = `/Candidate/get-candidates-paged-simplified?`;

    if (pageNumber !== 0) {
      url = url + `pageNumber=${pageNumber}&pageSize=9`;
    }

    if (city) {
      url = url + `&City=${city}`;
    }

    if (keyword) {
      url = url + `&Keyword=${keyword}`;
    }

    if (state) {
      url = url + `&State=${state}`;
    }

    if (educationStatus) {
      url = url + `&EducationStatus=${educationStatus}`;
    }

    if (hasCar) {
      url = url + `&HasCar=${hasCar}`;
    }

    if (hasPCD) {
      url = url + `&HasPCD=${hasPCD}`;
    }

    if (cityReference && distance) {
      url = url + `&Distance=${distance}&CityReference=${cityReference}`;
    } else if (cityReference && !distance) {
      url = url + `&Distance=10&CityReference=${cityReference}`;
    }

    return api
      .get(url)
      .then((response) => {
        setListCandidate(response.data.result);
        setInitialListCandidate(response.data.result);
        setPageCount(response.data.pageCount);
        setLoading(false);
        return response.data.result;
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handleLogout();
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao carregar candidatos. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao carregar candidatos:", error);
        return null;
      });
  }

  function downloadCandidateReport(
    city?: string,
    keyword?: string,
    educationStatus?: string,
    hasCar?: boolean,
    hasPCD?: boolean,
    distance?: number,
    state?: string,
    cityReference?: string,
    listFavorite?: number[],
  ) {
    setLoading(true);

    let url = `/Candidate/get-candidate-report?userId=${objUserTk.UserId}&pageNumber=1&pageSize=99999`;

    if (listFavorite) {
      url += `&ListFavorite=${listFavorite}`;
    }

    if (city) {
      url += `&City=${city}`;
    }

    if (keyword) {
      url += `&Keyword=${keyword}`;
    }

    if (state) {
      url += `&State=${state}`;
    }

    if (educationStatus) {
      url += `&EducationStatus=${educationStatus}`;
    }

    if (hasCar) {
      url += `&HasCar=${hasCar}`;
    }

    if (hasPCD) {
      url += `&HasPCD=${hasPCD}`;
    }

    if (distance) {
      url += `&Distance=${distance}&CityReference=${cityReference}`;
    }

    return api
      .get(url)
      .then((response) => {
        const candidates = response.data;

        const worksheet = XLSX.utils.json_to_sheet(candidates);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Candidates");

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(data);
        link.download = 'Candidatos.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);

        return candidates;
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handleLogout();
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao carregar candidatos. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao carregar candidatos:", error);
        return null;
      });
  }


  return {
    handleGetByCandidateId,
    handleGetByUserId,
    handleGetFavoriteCandidateAPI,
    candidate,
    setCandidate,
    putCandidate,
    listCandidate,
    setListCandidate,
    initialListCandidate,
    pageCount,
    getAllCandidatesPaged,
    setPageCount,
    downloadCandidateReport,
    handleGetCvCandidate
  };
}
