import * as React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
   root: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
   }, 
   ul: {
      "& .Mui-selected": {
         backgroundColor: "#4db7a2",
         color: "#fff"
         // '&:hover': {
         //   backgroundColor: '#4CAF50',
         // },
      },
      "& .MuiPaginationItem-root": {
         color: "#000000ba"
      }
      // '& .MuiPaginationItem-page:hover': {
      //   backgroundColor: '#f2f2f2',
      // },
   },
   pagination: {
      "& ul, & li": {
         "&::before": {
            content: "none"
         }
      }
   }
}));

type Props = {
   count: number;
   onChange: (event: React.ChangeEvent<unknown>, value: any) => void;
};

export default function PaginationMaterial({count, onChange}: Props) {
   const classes = useStyles();

   return (
      <div className={classes.pagination}>
         <Stack spacing={2}>
            <Pagination
               count={count ?? 5}
               className={classes.ul}
               defaultValue={1}
               onChange={(e, value) => onChange(e, value)}
               renderItem={(item) => (
                  <PaginationItem
                     slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                     {...item}
                  />
               )}
            />
         </Stack>
      </div>
   );
}
