import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";

import "./ModalCompetence.css";
import { useContext, useState } from "react";
import { CandidateContext } from "../../context/CandidateContext";
import { CompetenceContext } from "../../context/CompetenceContext";

interface ModalComponentProps {
  openModal: {open: boolean, idEdit: number};
  closeModal: () => void;
}

const ModalCompetence = (props: ModalComponentProps) => {
  const {
    postCompetenceByCandidateId,
    getAllCompetenceByCandidateId,
    putCompetenceById,
    formValuescompetence,
    setFormValuesCompetence,
  } = useContext(CompetenceContext);
  const { candidate } = useContext(CandidateContext);
  const { openModal, closeModal } = props;
  const [errors, setErrors] = useState({
    name: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    const newValue =
      type === "checkbox" ? (checked ? "Em andamento" : "") : value;

    setFormValuesCompetence({
      ...formValuescompetence,
      [name]: newValue,
    });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      if (openModal.idEdit !== 0) {
        await putCompetenceById(openModal.idEdit, formValuescompetence);
      } else {
        await postCompetenceByCandidateId(formValuescompetence);
      }
      await getAllCompetenceByCandidateId(candidate.id);
      closeModal();
      clearForm();
    }
  };

  const clearForm = () => {
    setFormValuesCompetence({
      name: ""
    });
    setErrors({
      name: ""
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    for (const field in formValuescompetence) {
      //@ts-ignore
      if (!formValuescompetence[field] && field !== "candidate") {
        isValid = false;
        //@ts-ignore
        newErrors[field] = "Campo obrigatório";
      } else {
        //@ts-ignore
        newErrors[field] = "";
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const cancelButton = () => {
    clearForm();
    closeModal();
  };

  return (
    <div>
      <Modal
        style={{ maxWidth: "870px" }}
        isOpen={openModal.open}
        toggle={closeModal}
        {...props}
      >
        <ModalHeader />
        <ModalBody>
          <div className="content-modal-name-job-details text-center mb-4">
            Competências
          </div>
          <CardBody style={{ boxShadow: "0 5px 8px rgba(-11, -17, -10, 0.2)" }}>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Nome
                      </label>
                      <Input
                        name="name"
                        value={formValuescompetence.name}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-educationalInstitution"
                        placeholder="Nome da competência"
                        type="text"
                      />
                      <span style={{ color: "red" }}>{errors.name}</span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <Button className="button-custom" onClick={handleSubmit}>
            Salvar
          </Button>
          <Button color="secondary" onClick={cancelButton}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalCompetence;
