import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface EstadosType {
    uf: string;
    codigo_uf: number;
}

export default function useEstados() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [estadosResponse, setEstados] = useState<EstadosType[]>([]); 
    const { handleLogout } = useContext(AuthContext);


    function handleGetAllEstados(): Promise<EstadosType[] | null> {
        // setLoading(true);
        return api
            .get(`/Estados/GetAll`)
            .then((response) => {
                const estadosResponse: EstadosType[] = response.data;
                setEstados(estadosResponse);
                // setLoading(false);
                return estadosResponse;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter informações dos estados brasileiros:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações dos estados brasileiros. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    return {
        estadosResponse,
        handleGetAllEstados,
    };
}
