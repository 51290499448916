import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "./ModalComponent.css";
import { useContext } from "react";
import { JobContext } from "../../context/JobContext";

interface ModalComponentProps {
  openModal: boolean;
  closeModal: () => void;
}

const ModalComponent = (props: ModalComponentProps) => {
  const { openModal, closeModal } = props;
  const { jobSelectCard } = useContext(JobContext);

  const redirectionLinkJob = () => {
    window.open(`${jobSelectCard?.jobLink}`, "_blank");
    closeModal();
  };

  return (
    <div>
      <Modal
        style={{ maxWidth: "870px" }}
        isOpen={openModal}
        toggle={closeModal}
        {...props}
      >
        <ModalHeader />
        <ModalBody>
          <div className="container">
            <div className="row">
              <div
                className="col-sm-4 col-md-4 col-lg-4 d-flex flex-column align-items-center"
                style={{ gap: "13px" }}
              >
                <div className="card-profile-image-modal">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {jobSelectCard?.companyLogoUrl === "Não Informado" ? (
                      <img
                        style={{
                          maxWidth: "40px",
                          minWidth: "40px",
                        }}
                        className="logo-job"
                        alt="..."
                        src={require("../../assets/img/brand/logoIcon.jpg")}
                      />
                    ) : (
                      <img
                        style={{
                          maxWidth: "70px",
                          minWidth: "70px",
                          marginRight: "10px",
                        }}
                        className="logo-job"
                        alt="..."
                        src={jobSelectCard?.companyLogoUrl}
                      />
                    )}
                  </a>
                </div>
                <div className="content-name-company">
                  {jobSelectCard?.companyName}
                </div>
                <div className="content-modal-name-job">
                  {jobSelectCard?.jobTitle}
                </div>
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/linkdln.svg")
                        .default
                    }
                  />
                </span>
              </div>
              <div
                className="col-sm d-flex flex-column"
                style={{ gap: "15px" }}
              >
                <div className="content-modal-name-job-details">
                  {jobSelectCard?.companyName} - {jobSelectCard?.jobTitle}
                </div>
                <div>Descrição da vaga: </div>
                <div>{jobSelectCard?.description}</div>
                <div>
                  <i className="ni ni-briefcase-24"></i>{" "}
                  {jobSelectCard?.workModel}
                </div>
                {/* <div>
                  <i className="ni ni-single-02"></i> {" "}
                  Há <strong>15 candidatos</strong> aplicados à esta vaga!
                </div> */}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="button-custom" onClick={redirectionLinkJob}>
            Aplicar
          </Button>
          <Button color="secondary" onClick={closeModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalComponent;
