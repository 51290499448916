import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from "reactstrap";
import useUser from "../../context/hooks/useUser";

const CsvUploadModal = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { createUsersFromCsv } = useUser();

    const toggleModal = () => setModalOpen(!modalOpen);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleFileUpload = async () => {
        if (selectedFile) {
            try {
                await createUsersFromCsv(selectedFile);
                toggleModal();
            } catch (error) {
                console.error("Erro ao enviar arquivo:", error);
            }
        } else {
            alert("Por favor, selecione um arquivo .csv antes de enviar.");
        }
    };

    return (
        <>
            <Button style={{ color: "#FFF", backgroundColor: "#004C4E" }} onClick={toggleModal}>
                Enviar planilha
            </Button>

            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Importar Usuários via planilha</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="csvFile">Selecione o arquivo</Label>
                        <Input type="file" name="file" id="csvFile" accept=".csv, .xlsx" onChange={handleFileChange} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ color: "#FFF", backgroundColor: "#004C4E" }} onClick={handleFileUpload}>
                        Enviar
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default CsvUploadModal;