// ToastContext.tsx

import React, { createContext, useContext, ReactNode } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ToastContextType {
  showToast: (type: string, message: string, error?: any) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const showToast = (type: string, message: string, error?: any) => {
    console.log("error", error);
    if (Object.keys(toast).includes(type)) {
      (toast as any)[type](message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: "colored",
      });
    } else {
      console.warn(`Tipo de toast inválido: ${type}`);
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer position="top-right" autoClose={3000} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context.showToast;
};
