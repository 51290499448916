import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";

import "./ModalExperience.css";
import { useContext, useState } from "react";
import { ExpertiseContext } from "../../context/ExpertiseContext";
import { CandidateContext } from "../../context/CandidateContext";
import { ChatGptContext } from "../../context/ChatGptContext";

interface ModalComponentProps {
  openModal: { open: boolean, idEdit: number };
  closeModal: () => void;
}

const ModalExperience = (props: ModalComponentProps) => {
  const {
    postExpertiseByCandidateId,
    getAllExpertiseByCandidateId,
    putExpertiseById,
    formValues,
    setFormValues,
  } = useContext(ExpertiseContext);
  const { handleChatGptMessage, chatGptResponse } = useContext(ChatGptContext);
  const { candidate } = useContext(CandidateContext);
  const { openModal, closeModal } = props;
  const [copied, setCopied] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    typeJob: "",
    company: "",
    startDate: "",
    endDate: "",
    description: "",
  });
  const [generatedText, setGeneratedText] = useState<string | null>(null);
  const [showGeneratedText, setShowGeneratedText] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    const newValue =
      type === "checkbox" ? (checked ? "Atualmente" : "") : value;

    setFormValues({
      ...formValues,
      [name]: newValue,
    });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      if (openModal.idEdit !== 0) {
        await putExpertiseById(openModal.idEdit, formValues);
      } else {
        await postExpertiseByCandidateId(formValues);
      }
      await getAllExpertiseByCandidateId(candidate.id);
      closeModal();
      clearForm();
    }
  };

  const clearForm = () => {
    setFormValues({
      title: "",
      typeJob: "",
      company: "",
      startDate: "",
      endDate: "",
      description: "",
    });
    setErrors({
      title: "",
      typeJob: "",
      company: "",
      startDate: "",
      endDate: "",
      description: "",
    });
    setGeneratedText(null);
    setShowGeneratedText(false);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    for (const field in formValues) {
      //@ts-ignore
      if (!formValues[field] && field !== "candidate" && field !== "typeJob") {
        isValid = false;
        //@ts-ignore
        newErrors[field] = "Campo obrigatório";
      } else {
        //@ts-ignore
        newErrors[field] = "";
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const cancelButton = () => {
    clearForm();
    closeModal();
  };

  const isDescriptionEmpty = formValues.description.trim() === "";

  const handleAIButtonClick = async () => {
    const text = await handleChatGptMessage(formValues.description);
    setGeneratedText(text?.toString() || null);
    setShowGeneratedText(true);
    setCopied(false);
  };

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(generatedText || "");
    setCopied(true);
  };

  return (
    <div>
      <Modal
        style={{ maxWidth: "870px" }}
        isOpen={openModal.open}
        toggle={closeModal}
        {...props}
      >
        <ModalHeader />
        <ModalBody>
          <div className="content-modal-name-job-details text-center mb-4">
            INFORMAÇÃO DA EXPERIÊNCIA
          </div>
          <CardBody style={{ boxShadow: "0 5px 8px rgba(-11, -17, -10, 0.2)" }}>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Cargo
                      </label>
                      <Input
                        name="title"
                        value={formValues.title}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-username"
                        placeholder="Cargo"
                        type="text"
                      />
                      <span style={{ color: "red" }}>{errors.title}</span>
                    </FormGroup>
                  </Col>
                  {/* <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Tipo de Emprego
                      </label>
                      <Input
                        name="typeJob"
                        value={formValues.typeJob}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-email"
                        placeholder="Estagiário, etc..."
                        type="text"
                      />
                      <span style={{ color: "red" }}>{errors.typeJob}</span>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Empresa
                      </label>
                      <Input
                        name="company"
                        value={formValues.company}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder="Nome da empresa"
                        type="text"
                      />
                      <span style={{ color: "red" }}>{errors.company}</span>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-last-name"
                      >
                        Data Início
                      </label>
                      <Input
                        name="startDate"
                        value={formValues.startDate}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-last-name"
                        placeholder="01/01/2024"
                        type="date"
                      />
                      <span style={{ color: "red" }}>{errors.startDate}</span>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-last-name"
                      >
                        Data Fim
                      </label>
                      <Input
                        name="endDate"
                        value={formValues.endDate}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-last-name"
                        placeholder="01/01/2024"
                        type="date"
                      />

                      <div className="mt-2 ml-5">
                        <Input
                          name="endDate"
                          checked={formValues.endDate === "Atualmente" ? true : false}
                          onChange={handleChange}
                          className="form-control-alternative"
                          id="input-last-name"
                          placeholder="01/01/2024"
                          type="checkbox"
                        />
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Atualmente
                        </label>
                      </div>
                      <span style={{ color: "red" }}>{errors.endDate}</span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-last-name"
                      >
                        Descrição
                      </label>
                      <Input
                        name="description"
                        value={formValues.description}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-last-name"
                        placeholder="Descreva sobre sua experiência..."
                        type="textarea"
                        rows="5"
                      />
                      <span style={{ color: "red" }}>{errors.description}</span>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-right">
                  <Button
                    className="button-custom btn-sm"
                    disabled={isDescriptionEmpty}
                    onClick={handleAIButtonClick}
                  >
                    Melhorar descrição com inteligência artificial
                  </Button>
                  {showGeneratedText && (
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="generated-text">
                          </label>
                          <Input
                            name="generated-text"
                            value={generatedText || ""}
                            className="form-control-alternative"
                            id="generated-text"
                            placeholder="Descrição aprimorada por inteligência artificial"
                            type="textarea"
                            rows="5"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {showGeneratedText && (
                    <div className="text-right">
                      <Button
                        className="button-custom btn-sm"
                        disabled={copied || !generatedText}
                        onClick={handleCopyButtonClick}
                      >
                        {copied ? "Copiado" : "Copiar texto gerado"}
                      </Button>
                    </div>
                  )}
                </div>  
              </div>
              <hr className="my-4" />
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <Button className="button-custom" onClick={handleSubmit}>
            Salvar
          </Button>
          <Button color="secondary" onClick={cancelButton}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExperience;
