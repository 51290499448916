import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { ExpertiseType } from "./useExpertise";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";
import { EducationType } from "./useEducation";
import { CourseType } from "./useCourses";
import { CompetenceType } from "./useCompetence";

export interface ChatGptType {
    message: string;
}

export default function useCandidate() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [chatGptResponse, setChatGptResponse] = useState<ChatGptType>({
        message: "",
    });
    const { handleLogout } = useContext(AuthContext);


    function handleChatGptMessage(message: string): Promise<ChatGptType | null> {
        setLoading(true);
        return api
            .get(`/ChatGpt/UseChatGPT?message=${message}`)
            .then((response) => {
                const chatGptResponse: ChatGptType = response.data;
                setChatGptResponse(chatGptResponse);
                setLoading(false);
                return chatGptResponse;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter informações do ChatPT:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações do ChatGPT. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    return {
        chatGptResponse,
        handleChatGptMessage,
    };
}
