import { ReactNode, createContext, useState } from "react";

interface LoadingContextProviderProps {
  children: ReactNode;
}

const initialState: LoadingContextData = {
  loading: false,
  setLoading: () => {},
};

export interface LoadingContextData {
  loading: boolean;
  setLoading: any;
}

export const LoadingContext = createContext<LoadingContextData>(initialState);

export default function LoadingContextProvider({
  children,
}: LoadingContextProviderProps) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}
