import { ReactNode, createContext } from "react";
import useEducation, { EducationType } from "./hooks/useEducation";

interface EducationContextProviderProps {
  children: ReactNode;
}

export interface EducationContextData {
  getAllEducationByCandidateId: (
    candidateId: number
  ) => Promise<EducationType[] | null>;
  postEducationByCandidateId: (
    Education: EducationType
  ) => Promise<void | null>;
  putEducationById: (
    idEducation: number,
    Education: EducationType
  ) => Promise<void | null>;
  deleteEducationById: (
    idEducation: number,
  ) => Promise<void | null>;
  educations: EducationType[];
  educationsTable: any;
  formValuesEducation: EducationType;
  setFormValuesEducation: any;
}

const initialState: EducationContextData = {
  getAllEducationByCandidateId: async (candidateId: number) => {
    return Promise.resolve(null);
  },
  postEducationByCandidateId: async (education: EducationType) => {
    return Promise.resolve(null);
  },
  putEducationById: async (idEducation: number, education: EducationType) => {
    return Promise.resolve(null);
  },
  deleteEducationById: async (idEducation: number) => {
    return Promise.resolve(null);
  },
  educations: [],
  educationsTable: [],
  formValuesEducation: {
    endDate: "",
    startDate: "",
    educationalInstitution: "",
    course: ""
  },
  setFormValuesEducation: () => {},
};

export const EducationContext =
  createContext<EducationContextData>(initialState);

export default function EducationContextProvider({
  children,
}: EducationContextProviderProps) {
  const {
    getAllEducationByCandidateId,
    educations,
    educationsTable,
    postEducationByCandidateId,
    formValuesEducation,
    setFormValuesEducation,
    deleteEducationById,
    putEducationById,
  } = useEducation();

  return (
    <EducationContext.Provider
      value={{
        getAllEducationByCandidateId,
        educations,
        educationsTable,
        postEducationByCandidateId,
        formValuesEducation,
        setFormValuesEducation,
        putEducationById,
        deleteEducationById
      }}
    >
      {children}
    </EducationContext.Provider>
  );
}
