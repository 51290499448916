import { ReactNode, createContext } from "react";
import useChatGpt, { ChatGptType } from "./hooks/useChatGpt";

interface ChatGptContextProviderProps {
    children: ReactNode;
}

export interface ChatGptContextData {
    handleChatGptMessage: (message: string) => Promise<ChatGptType | null>;
    chatGptResponse: ChatGptType;
}

const initialState: ChatGptContextData = {
    handleChatGptMessage: async (message: string) => {
        return Promise.resolve(null);
    },
    chatGptResponse: {
        message: "",
    },
};

export const ChatGptContext =
    createContext<ChatGptContextData>(initialState);

export default function ChatGptContextProvider({
    children,
}: ChatGptContextProviderProps) {
    const {
        handleChatGptMessage,
        chatGptResponse,
    } = useChatGpt();

    return (
        <ChatGptContext.Provider
            value={{
                handleChatGptMessage,
                chatGptResponse,
            }}
        >
            {children}
        </ChatGptContext.Provider>
    );
}
