import formatDateBr from "./formatDateBr";

export default function calculateDate(dataStr: string) {
    // Convertendo a string para um objeto Date
    const data = new Date(dataStr);
    const hoje = new Date();
    const ontem = new Date(hoje);
    ontem.setDate(hoje.getDate() - 1);

    // Verificando se a data é hoje
    if (data.toDateString() === hoje.toDateString()) {
        return "Hoje";
    }
    // Verificando se a data é ontem
    else if (data.toDateString() === ontem.toDateString()) {
        return "Ontem";
    }
    // Retornando a data formatada como dd/MM/yyyy
    else {
        return formatDateBr(data);
    }
}