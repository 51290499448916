import { useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Button,
} from "reactstrap";

interface TableComponentProps {
  data: any;
  columns: any;
  titleTable: string;
  actionDropdown: any;
  actionDelete: any;
}

const TableComponent = ({
  data,
  columns,
  titleTable,
  actionDropdown,
  actionDelete,
}: TableComponentProps) => {
  const [pagination, setPagination] = useState({ initial: 0, final: 4 });
  const [activePage, setActivePage] = useState(1);

  const onChangePagination = (e: any, value: any) => {
    e.preventDefault();
    setActivePage(value);
    const positionInitial = (value - 1) * 4;
    const positionFinal = positionInitial + 4;

    setPagination({ initial: positionInitial, final: positionFinal });
  };

  const numberPagination = () => {
    const numberCalc = Math.ceil(data.length / 4);
    const paginationItems = [];

    for (let i = 0; i < numberCalc; i++) {
      paginationItems.push(
        <PaginationItem
          key={i}
          className={i + 1 === activePage ? "active" : ""}
          onClick={(e) => onChangePagination(e, i + 1)}
        >
          <PaginationLink href="#pablo">{i + 1}</PaginationLink>
        </PaginationItem>
      );
    }

    return paginationItems;
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader className="border-0 d-flex justify-content-between">
          <h3 className="mb-0">{titleTable}</h3>
          <Button
            style={{
              color: "#FFF",
              backgroundColor: "#640764",
              fontSize: "14px",
              display: "flex",
            }}
            onClick={(e) => actionDropdown()}
            size="sm"
          >
            <div className="ni ni-fat-add" style={{ fontSize: "20px" }}></div>
            Adicionar
          </Button>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              {columns.map((item: any, index: number) => (
                <th key={`table-component-${item + index}`} scope="col">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data
              .slice(pagination.initial, pagination.final)
              .map((item: any, index: number) => {
                return (
                  <tr key={item.id}>
                    <th scope="row">
                      <Media>
                        <span className="mb-0 text-sm">{item.column1}</span>
                      </Media>
                    </th>
                    <th scope="row">
                      <Media>
                        <span className="mb-0 text-sm">{item.column2}</span>
                      </Media>
                    </th>
                    <th scope="row">
                      <Media>
                        <span className="mb-0 text-sm">{item.column3}</span>
                      </Media>
                    </th>
                    <th scope="row">
                      <Media>
                        <span className="mb-0 text-sm">{item.column4}</span>
                      </Media>
                    </th>
                    <td className="text-right">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          style={{ backgroundColor: "#640764" }}
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            onClick={(e) => actionDropdown(item.id)}
                          >
                            Editar
                          </DropdownItem>
                          <DropdownItem onClick={(e) => actionDelete(item.id)}>
                            Apagar
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem className="disabled">
                <PaginationLink
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  tabIndex={-1}
                >
                  <i className="fas fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
              {numberPagination()}
              <PaginationItem>
                <PaginationLink
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </CardFooter>
      </Card>
    </>
  );
};

export default TableComponent;
