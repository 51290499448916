import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Header from "../../../components/Headers/Header";
import AdminNavbar from "../../../components/Navbars/AdminNavbar/AdminNavbar";
import "./SearchCandidates.css";
import CardCandidate from "../../../components/CardCandidate/CardCandidate";
import ModalComponentRecruiter from "../../../components/ModalComponentRecruiter/ModalComponentRecruiter";
import { CandidateContext } from "../../../context/CandidateContext";
import calculateAge from "../../../utils/calculateAge";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import PaginationMaterial from "../../../components/PaginationMaterial/PaginationMaterial";
import _ from "lodash";
import WhatsappButton from "../../../components/WhatsappButtom/WhatsappButtom";
import { CandidateType } from "../../../context/hooks/useCandidate";
import { AuthContext } from "../../../context/AuthContext";
import { FavoriteContext } from "../../../context/FavoriteContext";

const SearchCandidates = () => {
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [dataSearch, setDataSearch] = useState({input1: "", input2: "", state: "", educationStatus: "", hasCar: false, hasPCD: false, distance: 0, cityReference: ""});
  const [showOnlyFavorites, setShowOnlyFavorites] = useState<boolean>(false); 
  const { objUserTk } = useContext(AuthContext);
  const { handleGetByUserId } = useContext(FavoriteContext);
  const [paginationMain, setPaginationMain] = useState({
    initial: 0,
    final: 9,
  });

  const [paginationCommon, setPaginationCommon] = useState({
    initial: 0,
    final: 9,
  });
  
  const [paginationFavorite, setPaginationFavorite] = useState({
    initial: 0,
    final: 9,
  });

  const [favoriteCandidates, setFavoriteCandidates] = useState<CandidateType[]>([]);
  const {
    handleGetByCandidateId,
    listCandidate,
    setCandidate,
    setListCandidate,
    initialListCandidate,
    getAllCandidatesPaged,
    pageCount,
    setPageCount,
    handleGetFavoriteCandidateAPI,
  } = useContext(CandidateContext);

  const listCandidateMain = listCandidate.filter((e) => e.typeCandidate === 1); 
  const listCandidateCommon = listCandidate.filter(
    (e) => e.typeCandidate === 0
  ); //comum

  const handleGetUserDetails = (idCandidate: number) => {
    var cardCandidate = handleGetByCandidateId(idCandidate);
    setCandidate(cardCandidate);
    setOpenModal((prevState) => !prevState);
  };
  
  const onChangePaginationCommon = async (e: any, value: any) => {
    
    await getAllCandidatesPaged(value, dataSearch.input2, dataSearch.input1, dataSearch.educationStatus, dataSearch.hasCar, dataSearch.hasPCD, dataSearch.distance, dataSearch.state, dataSearch.cityReference);
  };

  const handleSearchCandidate = async (data: any) => {
    if (data.input1 || data.input2 || data.educationStatus || data.hasCar || data.hasPCD || data.distance || data.state || data.cityReference) {
      setDataSearch({input1: data.input1, input2: data.input2, educationStatus: data.educationStatus, hasCar: data.hasCar, hasPCD: data.hasPCD, distance: data.distance, state: data.state, cityReference: data.cityReference});
      await getAllCandidatesPaged(1, data.input2, data.input1, data.educationStatus, data.hasCar, data.hasPCD, data.distance, data.state, data.cityReference);
    }
  };

  useEffect(() => {
    setLoading(false);
    getAllCandidatesPaged(1);
    
    if (objUserTk.UserId) {
      handleGetByUserId(objUserTk.UserId)
        .then((favoriteData) => {
          if (favoriteData) {
            localStorage.setItem('favoriteCandidates', JSON.stringify(favoriteData.listFavoriteCandidates) || '[]');
            localStorage.setItem('rejectedCandidates', JSON.stringify(favoriteData.listRejectedCandidates) || '[]');
          }
        })
        .catch((error) => {
          console.error('Erro ao obter dados favoritos:', error);
        });
    }
  }, []);

  useEffect(() => {
    if (showOnlyFavorites) {
      handleGetFavoriteCandidates();
    } else {
      getAllCandidatesPaged(1);
    }
  }, [showOnlyFavorites]); 

  const handleGetFavoriteCandidates = async () => {
    try {
      const favoriteIds = JSON.parse(localStorage.getItem(`favoriteCandidates`) || '[]');
      if (favoriteIds.length > 0) {
        const favorites = await handleGetFavoriteCandidateAPI(favoriteIds); 
        setFavoriteCandidates(favorites || []); 
      }
    } catch (error) {
      console.error("Erro ao obter candidatos favoritos:", error);
    }
  };

  const handleToggleShowOnlyFavorites = () => {
    const newShowOnlyFavorites = !showOnlyFavorites;
    setShowOnlyFavorites(!showOnlyFavorites);
    localStorage.setItem('showOnlyFavorites', JSON.stringify(newShowOnlyFavorites));
  };

  const onChangePaginationFavorite = async (e: any, value: any) => {
    await handleGetFavoriteCandidates();
    const positionInitial = (value - 1) * 9;
    const positionFinal = positionInitial + 9;
    setPaginationFavorite({ initial: positionInitial, final: positionFinal });
  };
  

  return (
    <>
      <WhatsappButton />
      <LoadingSpinner />
      <Header />
      <AdminNavbar
        placeholderSearch="Nome"
        placeholderSearch1="Descrição"
        titleButtonSearch="PROCURAR"
        brandText={"PROCURAR CANDIDATOS"}
        search={false}
        titleSearch={""}
        handleButtonSearch={handleSearchCandidate}
        showAdvancedFilters={true}
      />

      <Container className="mt--7" fluid>
        
        {/* <Card
          className="shadow cards-filters-jobs"
          style={{ marginTop: "60px" }}
        >
          <CardHeader className="bg-transparent d-flex justify-content-center">
            <div className="d-flex" style={{ gap: "15px" }}>
            </div>
          </CardHeader>
        </Card> */}

        <div className="title-row-card-candidate mt-8">
          {/* {listCandidateMain.length > 0 ? "Destaque" : ""} */}
          {""}
        </div>

        {/*
        {listCandidateMain.length > 0 && (
          <Row className="line-cards-candidate-container">
              {listCandidateMain
                .slice(paginationMain.initial, paginationMain.final)
                .map((item: any) => {
                  return (
                    <Col sm="6" xl="4" key={item.id}>
                      <CardCandidate
                        handleGetUserDetails={() =>
                          handleGetUserDetails(item.id)
                        }
                        id={item.id}
                        imgProfile={
                          item.photo ||
                          require("../../../assets/img/theme/profile.png")
                        }
                        cityCandidate={item.city}
                        distanceCandidate={""}
                        detailsCandidate={item.description}
                        experinceCandidate={item.expertises?.[0]?.title || ""}
                        experinceCandidate2={item.expertises?.[1]?.title || ""}
                        infoCandidate={`${item.name}, ${calculateAge(
                          item.birthData
                        )} anos`}
                      />
                    </Col>
                  );
                })}
          </Row>
        )} */}

        {/* {pageCount > 3 && (
          <div className="d-flex justify-content-center mt-4">
            <PaginationMaterial
              onChange={onChangePaginationMain}
              count={Math.ceil(pageCount / 3 || 0)}
            />
          </div>
        )} */}

        {/* <div className="title-row-card-candidate">Candidatos de Primeira</div> */}
        
        <div className="title-row-card-candidate mt-8 d-flex align-items-center">
          <div className="mr-auto">Candidatos de Primeira</div>
          <div className="filter-favorites">
            <input
              type="checkbox"
              checked={showOnlyFavorites}
              onChange={handleToggleShowOnlyFavorites}
            />
            <span> Mostrar apenas favoritos</span>
          </div>
        </div>

        <Row className="line-cards-candidate-container">
            {(showOnlyFavorites ? favoriteCandidates.slice(paginationFavorite.initial, paginationFavorite.final) : listCandidate.slice(paginationCommon.initial, paginationCommon.final))
            .map((item: any) => {
              return (
                <Col sm="6" xl="4" key={item.id} className="mb-3">
                  <CardCandidate
                    handleGetUserDetails={() => handleGetUserDetails(item.id)}
                    id={item.id}
                    imgProfile={
                      item.photo ||
                      require("../../../assets/img/theme/profile.png")
                    }
                    cityCandidate={item.city}
                    stateCandidate={item.state}
                    distanceCandidate={""}
                    detailsCandidate={item.description}
                    // experinceCandidate={item.expertises?.[0]?.title || ""}
                    // experinceCandidate2={item.expertises?.[1]?.title || ""}
                    personalGoal={item.personalGoal}
                    infoCandidate={`${item.name}, ${calculateAge(
                      item.birthData
                    )} anos`}
                  />
                </Col>
              );
            })}
        </Row>

        {pageCount && (
          <div className="d-flex justify-content-center mt-4">
            <PaginationMaterial
              onChange={(e: any, value: any) => (showOnlyFavorites ? onChangePaginationFavorite(e, value) : onChangePaginationCommon(e, value))}
              count={showOnlyFavorites ? Math.ceil(favoriteCandidates.length / 9 || 0) : pageCount}
            />
          </div>
        )}

        {listCandidateCommon.length === 0 && (
          <div style={{ color: "red" }}>ENCONTRADO NENHUM REGISTRO</div>
        )}
      </Container>
      <ModalComponentRecruiter
        openModal={openModal}
        closeModal={() => setOpenModal((state) => !state)}
      />
    </>
  );
};

export default SearchCandidates;
