import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { CandidateContext } from "../CandidateContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface CourseType {
    startDate: string;
    endDate: string;
    educationalInstitution: string;
    extracurricularCourse: string;
    candidate?: any;
    id?: number;
}

export default function useCourses() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext)
    const { handleLogout } = useContext(AuthContext)
    const [courses, setCourses] = useState<CourseType[]>([]);
    const [coursesTable, setCoursesTable] = useState<any>({
        mylist: [],
        columns: [],
    });

    const [formValuesCourses, setFormValuesCourses] = useState<CourseType>({
        endDate: "",
        startDate: "",
        educationalInstitution: "",
        extracurricularCourse: ""
    });

    const { candidate } = useContext(CandidateContext);

    function getAllCoursesByCandidateId(
        candidateId: number
    ): Promise<CourseType[] | null> {
        setLoading(true);
        return api
            .get(`/ExtracurricularCourses/get-extracurricular-candidate/${candidateId}`)
            .then((response) => {
                const courses: CourseType[] = response.data;
                setCourses(courses);
                createNewListTable(courses);
                setLoading(false);
                return courses;
            })
            .catch((error) => {
                showToast(
                    "error",
                    "Erro ao obter informações das Formações acadêmicas. Verifique novamente mais tarde.",
                    error
                );
                setLoading(false);
                console.error("Erro ao obter informações", error);
                return null;
            });
    }

    function postCourseByCandidateId(
        courses: CourseType
    ): Promise<void | null> {
        setLoading(true);
        return api
            .post(`/ExtracurricularCourses/create?candidateId=${candidate.id}`, courses)
            .then((response) => {
                showToast("success", "Curso cadastrado com sucesso.");
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    console.log();
                    showToast(
                        "error",
                        "Erro ao adicionar curso. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao adicionar informações do curso:", error);
                return null;
            });
    }

    function putCourseById(
        idCourse: number,
        course: CourseType
    ): Promise<void | null> {
        setLoading(true);
        return api
            .put(`/ExtracurricularCourses/${idCourse}`, course)
            .then((response) => {
                showToast("success", "Curso atualizado com sucesso.");
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar curso. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar informações do curso:", error);
                return null;
            });
    }

    function deleteCourseById(
        idCourse: number,
    ): Promise<void | null> {
        setLoading(true);
        return api
            .delete(`/ExtracurricularCourses/${idCourse}`)
            .then((response) => {
                showToast("success", "Curso deletado com sucesso.");
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao deletar curso. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao deletar o curso:", error);
                return null;
            });
    }

    const createNewListTable = (Course: CourseType[]) => {
        if (Course && Course.length > 0) {
            const newList = Course.map((item: CourseType) => {
                return {
                    column1: item.educationalInstitution,
                    column2: item.extracurricularCourse,
                    column3: item.startDate
                        ? item.startDate.split("-").reverse().join("/")
                        : "",
                    column4: item.endDate
                        ? item.endDate.split("-").reverse().join("/")
                        : "",
                    id: item.id,
                };
            });

            const columns = [
                "INSTITUIÇÃO DE ENSINO",
                "CURSO",
                "DATA INICIO",
                "DATA FIM",
                "",
            ];

            setCoursesTable({ mylist: newList, columns: columns });
        } else {
            setCoursesTable({ mylist: [], columns: [] });
        }
    };

    return {
        getAllCoursesByCandidateId,
        postCourseByCandidateId,
        putCourseById,
        deleteCourseById,
        courses,
        coursesTable,
        formValuesCourses,
        setFormValuesCourses
    };
}
