import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface CompanyType {
  name: string;
  companyName: string;
  address: string;
  email: string;
  celPhone: string;
  city: string;
  description: string;
  photo: string;
  id: number
  user: any
}

export default function useCompany() {
  const showToast = useToast();
  const {setLoading} = useContext(LoadingContext)
  const { handleLogout } = useContext(AuthContext)
  const [company, setCompany] = useState<CompanyType>({
    name: "",
    companyName: "",
    address: "",
    email: "",
    celPhone: "",
    city: "",
    description: "",
    photo: "",
    id: 0,
    user: {},
  });

  function handleGetByUserId(id: number): Promise<CompanyType | null> {
    setLoading(true);
    return api
      .get(`/Company/get-company-userid/${id}`)
      .then((response) => {
        const company: CompanyType = response.data;
        setCompany(company);
        setLoading(false);
        return company;
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          handleLogout()
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao obter informações da empresa. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao obter informações do usuário:", error);
        return null;
      });
  }

  function putCompany(Company: CompanyType): Promise<CompanyType | null> {
    setLoading(true);
    return api
      .put(`/Company/${Company.id}`, Company)
      .then((response) => {
        showToast("success", "Perfil atualizado com sucesso.");
        setLoading(false);
        return null;
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          handleLogout()
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao atualizar perfil. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao obter informações do usuário:", error);
        return null;
      });
  }

  return { handleGetByUserId, company, setCompany, putCompany };
}
