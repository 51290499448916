import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface IndicatorType {
    city: string,
    state: string,
    numCurriculosPreenchidos: number,
    userCountByType?: { [key: string]: number };
    userCountByCompanyName?: { [key: string]: number };
}

export interface IncompleteCandidateType {
    name: string;
    email?: string;
    celPhone?: string;
}

export interface UserCountType {
    [key: string]: number;
}

export default function useIndicator() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout } = useContext(AuthContext);

    function handleGetCandidateCountByState(): Promise<IndicatorType | null> {
        setLoading(true);
        return api
            .get(`/Candidate/get-candidate-count-by-state`)
            .then((response) => {
                const stateIndicatorType: IndicatorType = response.data;
                return stateIndicatorType;
            })
            .catch((error) => {
                console.error("Erro ao obter lista de currículos por estado:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter lista de currículos por estado. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    function handleGetCandidateCountByCity(): Promise<IndicatorType | null> {
        return api
            .get(`/Candidate/get-candidate-count-by-city`)
            .then((response) => {
                const cityIndicatorType: IndicatorType = response.data;
                return cityIndicatorType;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter lista de currículos por estado:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter lista de currículos por estado. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    function handleGetUserCountByType(): Promise<UserCountType | null> {
        setLoading(true);
        return api
            .get(`/Users/get-count-by-type`)
            .then((response) => {
                const userCountByType: UserCountType = response.data;
                setLoading(false);
                return userCountByType;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter lista de usuários por tipo:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter lista de usuários por tipo. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    function handleGetUserCountByCompanyName(): Promise<UserCountType | null> {
        return api
            .get(`/Company/get-count-by-company-name`)
            .then((response) => {
                const userCountByCompanyName: UserCountType = response.data;
                return userCountByCompanyName;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter lista de usuários por empresa:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter lista de usuários por empresa. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    function handleGetIncompleteCandidate(): Promise<IncompleteCandidateType | null> {
        return api
            .get(`/Candidate/get-all-candidate-incomplete`)
            .then((response) => {
                const incompleteCandidate: IncompleteCandidateType = response.data;
                return incompleteCandidate;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter lista de usuários incompletos:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter lista de usuários incompletos. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    return {
        handleGetCandidateCountByState,
        handleGetCandidateCountByCity,
        handleGetUserCountByType,
        handleGetUserCountByCompanyName,
        handleGetIncompleteCandidate,
    };
}
