import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { CandidateContext } from "../CandidateContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface JobType {
  companyName: string;
  companyLogoUrl: string;
  jobTitle: string;
  location: string;
  jobType: string;
  publishedDate: string;
  jobLink: string;
  description: string;
  workModel: string;
  pcd: string;
  id: number;
  [key: string]: any;
}

export default function useJob() {
  const showToast = useToast();
  const { setLoading } = useContext(LoadingContext);
  const { handleLogout } = useContext(AuthContext);
  const [jobs, setJobs] = useState<JobType[]>([]);
  const [jobSelectCard, setJobSelectCard] = useState<JobType>({
    companyLogoUrl: "",
    companyName: "",
    description: "",
    id: 0,
    jobLink: "",
    jobTitle: "",
    jobType: "",
    location: "",
    workModel: "",
    pcd: "",
    publishedDate: "",
  });

  const [listJobs, setListJobs] = useState<JobType[]>([]);
  const [pageCountJobs, setPageCountJobs] = useState<number>(0);
  const [initialListJobs, setInitialListJobs] = useState<JobType[]>([]);

  function getAllJobs(): Promise<JobType[] | null> {
    setLoading(true);
    return api
      .get(`/Job/get-jobs`)
      .then((response) => {
        const jobs: JobType[] = response.data;
        setJobs(jobs);
        setLoading(false);
        return jobs;
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handleLogout();
        } else {
          showToast(
            "error",
            "Erro ao obter informações das vagas. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        console.error("Erro ao obter informações das vagas:", error);
        return null;
      });
  }

  function handleGetByJobId(id: number): Promise<JobType | null> {
    setLoading(true);
    return api
      .get(`/Job/getjobid/${id}`)
      .then((response) => {
        setJobs(response.data);
        setLoading(false);
        return response.data;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao obter informações da vaga:", error);
        if (error?.response?.status == 401) {
          handleLogout();
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao obter informações da vaga. Verifique novamente mais tarde.",
            error
          );
        }
        return null;
      });
  }

  function getAllJobsPaged(
    pageNumber: number,
    keyword?: string,
    location?: string,
    workModel?: string,
    publishedDate?: string,
    PCD?: string,
  ): Promise<JobType[] | null> {
    setLoading(true);

    let url = `/Job/get-jobs-paged?`;

    if (pageNumber !== 0) {
      url = url + `pageNumber=${pageNumber}&pageSize=20`;
    }

    if (keyword) {
      url = url + `&Keyword=${keyword}`;
    }

    if (location) {
      url = url + `&Location=${location}`;
    }

    if (workModel) {
      url = url + `&WorkModel=${workModel}`;
    }

    if (publishedDate) {
      url = url + `&PublishedDate=${publishedDate}`;
    }

    if (PCD) {
      url = url + `&PCD=${PCD}`;
    }
    return api
      .get(url)
      .then((response) => {
        const jobs: JobType[] = response.data.result;
        setJobs(jobs);
        setListJobs(jobs);
        setInitialListJobs(jobs);
        setPageCountJobs(response.data.pageCount);
        setLoading(false);
        return jobs;
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handleLogout();
          showToast(
            "error",
            "Sessão desconectada. Favor login novamente.",
            error
          );
        } else {
          showToast(
            "error",
            "Erro ao carregar vagas. Verifique novamente mais tarde.",
            error
          );
        }
        setLoading(false);
        return null;
      });

    function compareDates(a: any, b: any): number {
      const dateA = new Date(a.publishedDate).getTime();
      const dateB = new Date(b.publishedDate).getTime();

      return dateA - dateB;
    }
  }

  return {
    getAllJobs,
    jobs,
    setJobs,
    jobSelectCard,
    setJobSelectCard,
    getAllJobsPaged,
    listJobs,
    setListJobs,
    initialListJobs,
    pageCountJobs,
    setPageCountJobs,
    handleGetByJobId,
  };
}
