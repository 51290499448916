import React, { useState, useEffect, useContext } from 'react';
import { UncontrolledTooltip } from "reactstrap";
import IconeEye from "../../assets/icons/IconeEye";
import IconeHeart from "../../assets/icons/IconeHeart";
import IconeReject from '../../assets/icons/IconeReject ';
import "./CardCandidate.css";
import Resizer from "react-image-file-resizer";
import { FavoriteContext } from '../../context/FavoriteContext';
import { AuthContext } from '../../context/AuthContext';

interface PropsCardCandidate {
  imgProfile: string;
  cityCandidate: string;
  stateCandidate: string;
  distanceCandidate: string;
  detailsCandidate: string;
  // experinceCandidate: string;
  // experinceCandidate2: string;
  personalGoal: string;
  infoCandidate: string;
  id: number;
  handleGetUserDetails: (idCandidate: number) => void;
}

const CardCandidate = (props: PropsCardCandidate) => {
  const [resizedImg, setResizedImg] = useState<string>("");
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [isRejected, setIsRejected] = useState<boolean>(false);
  const { objUserTk } = useContext(AuthContext);
  const { putFavoriteList } = useContext(FavoriteContext);

  useEffect(() => {
    const favoriteCandidates = JSON.parse(localStorage.getItem(`favoriteCandidates`) || '[]');
    const rejectedCandidates = JSON.parse(localStorage.getItem(`rejectedCandidates`) || '[]');

    setIsFavorite(favoriteCandidates.includes(props.id));
    setIsRejected(rejectedCandidates.includes(props.id));

    const fetchAndResizeImage = async () => {
      try {
        const response = await fetch(props.imgProfile);
        const blob = await response.blob();

        const sizeInMB = blob.size / (1024 * 1024); // Convertendo bytes para megabytes

        if (sizeInMB > 1) {
          Resizer.imageFileResizer(
            blob,
            100, // largura desejada
            100, // altura desejada
            'JPEG', // formato desejado
            70, // qualidade da imagem (0-100)
            0, // rotação (0 = não rotacionar)
            uri => {
              setResizedImg(uri as string);
            },
            'base64'
          );
        } else {
          setResizedImg(props.imgProfile);
        }
      } catch (error) {
        console.error('Erro ao redimensionar imagem:', error);
      }
    };

    fetchAndResizeImage();
  }, [props.imgProfile, props.id]);

  const toggleFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const favoriteCandidates = JSON.parse(localStorage.getItem(`favoriteCandidates`) || '[]');
    const rejectedCandidates = JSON.parse(localStorage.getItem(`rejectedCandidates`) || '[]');

    if (isRejected) {
      const updatedRejected = rejectedCandidates.filter((id: number) => id !== props.id);
      localStorage.setItem(`rejectedCandidates`, JSON.stringify(updatedRejected));
    }

    if (isFavorite) {
      const updatedFavorites = favoriteCandidates.filter((id: number) => id !== props.id);
      localStorage.setItem(`favoriteCandidates`, JSON.stringify(updatedFavorites));
    } else {
      const updatedFavorites = [...favoriteCandidates, props.id];
      localStorage.setItem(`favoriteCandidates`, JSON.stringify(updatedFavorites));
    }

    putFavoriteList({
      userId: objUserTk.UserId,
      listFavoriteCandidates: JSON.parse(localStorage.getItem(`favoriteCandidates`) || '[]'),
      listRejectedCandidates: JSON.parse(localStorage.getItem(`rejectedCandidates`) || '[]')
    });

    setIsFavorite(!isFavorite);
    setIsRejected(false);
  };

  const toggleReject = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const rejectedCandidates = JSON.parse(localStorage.getItem(`rejectedCandidates`) || '[]');
    const favoriteCandidates = JSON.parse(localStorage.getItem(`favoriteCandidates`) || '[]');

    let updatedRejected: number[] = [];
    if (isFavorite) {
      const updatedFavorites = favoriteCandidates.filter((id: number) => id !== props.id);
      localStorage.setItem(`favoriteCandidates`, JSON.stringify(updatedFavorites));
      updatedRejected = rejectedCandidates;
    }

    if (isRejected) {
      const updatedRejected = rejectedCandidates.filter((id: number) => id !== props.id);
      localStorage.setItem(`rejectedCandidates`, JSON.stringify(updatedRejected));
    } else {
      const updatedRejected = [...rejectedCandidates, props.id];
      localStorage.setItem(`rejectedCandidates`, JSON.stringify(updatedRejected));
    }

    putFavoriteList({
      userId: objUserTk.UserId,
      listFavoriteCandidates: JSON.parse(localStorage.getItem(`favoriteCandidates`) || '[]'),
      listRejectedCandidates: JSON.parse(localStorage.getItem(`rejectedCandidates`) || '[]')
    });

    setIsRejected(!isRejected);
    setIsFavorite(false);
  };

  return (
    <div className={`card-candidate-container ${isFavorite ? 'favorite-card' : ''} ${isRejected ? 'rejected-card' : ''}`}>
      <div className="card-candidate-separetor">
        <div className="card-candidate-content">
          <div className="card-profile-image-candidate">
            {resizedImg ? (
              <img
                style={{
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
                alt="Avatar"
                className="img-fluid rounded-circle"
                src={resizedImg}
              />
            ) : null}
          </div>
          {isFavorite && <div className="favorite-indicator">Favorito</div>}
          {isRejected && <div className="rejected-indicator">Stand-by</div>}
        </div>

        <div className="card-candidate-content-2">
          <div className="title-locale-city-candidate">
            {props.cityCandidate}, {props.stateCandidate}
          </div>
          <div
            className="title-details-candidate-obs"
            id={`title-details-candidate-obs${props.id}`}
          >
            {props.distanceCandidate} <br />
            {props.detailsCandidate}
          </div>
          <UncontrolledTooltip
            style={{ backgroundColor: "#4db7a2" }}
            delay={-2}
            target={`title-details-candidate-obs${props.id}`}
          >
            {props.distanceCandidate} <br />
            {props.detailsCandidate}
          </UncontrolledTooltip>
          {/* <div className="title-experience-candidate-card">
            {props.experinceCandidate}
          </div>
          <div className="title-experience-candidate-card">
            {props.experinceCandidate2}
          </div> */}
          <div className="title-experience-candidate-card">
            {props.personalGoal}
          </div>
        </div>
      </div>
      <div
        className="card-name-age-candidate-bottom"
        onClick={() => props.handleGetUserDetails(props.id)}
      >
        <UncontrolledTooltip
          style={{ backgroundColor: "#4db7a2" }}
          delay={-2}
          target={`content-name-age-candidate-bottom${props.id}`}
        >
          {props.infoCandidate}
        </UncontrolledTooltip>
        <div
          className="content-name-age-candidate-bottom"
          id={`content-name-age-candidate-bottom${props.id}`}
        >
          {props.infoCandidate}
        </div>
        <div className="card-candidate-icons">
          <IconeEye />
          <button className={`heart-button ${isFavorite ? 'favorite' : ''}`} onClick={toggleFavorite}>
            <IconeHeart />
          </button>
          <button className={`reject-button ${isRejected ? 'rejected' : ''}`} onClick={toggleReject}>
            <IconeReject />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardCandidate;
