import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface MunicipiosType {
    nome: string;
}

export default function useMunicipios() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [municipiosResponse, setMunicipios] = useState<MunicipiosType[]>([]); 
    const { handleLogout } = useContext(AuthContext);


    function handleGetAllMunicipios(): Promise<MunicipiosType[] | null> {
        // setLoading(true);
        return api
            .get(`/Municipios/GetAll`)
            .then((response) => {
                const municipiosResponse: MunicipiosType[] = response.data;
                setMunicipios(municipiosResponse);
                // setLoading(false);
                return municipiosResponse;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter informações dos municípios:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações dos municípios. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    function handleGetMunicipiosPorEstados(
        uf: string
    ): Promise<MunicipiosType[] | null> {
        setLoading(true);
        setMunicipios([]);
        return api
            .get(`/Municipios/GetMunicipiosByEstado?uf=${uf}`)
            .then((response) => {
                const municipiosResponse: MunicipiosType[] = response.data;
                setMunicipios(municipiosResponse);
                setLoading(false);
                return municipiosResponse;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter informações dos municípios:", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações dos municípios. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    return {
        municipiosResponse,
        handleGetAllMunicipios,
        handleGetMunicipiosPorEstados,
    };
}
