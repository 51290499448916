import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { CandidateContext } from "../CandidateContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface CompetenceType {
    name: string;
    candidate?: any;
    id?: number;
}

export default function useCompetence() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext)
    const { handleLogout } = useContext(AuthContext)
    const [competences, setCompetence] = useState<CompetenceType[]>([]);
    const [competenceTable, setCompetenceTable] = useState<any>({
        mylist: [],
        columns: [],
    });

    const [formValuescompetence, setFormValuesCompetence] = useState<CompetenceType>({
        name: ""
    });

    const { candidate } = useContext(CandidateContext);

    function getAllCompetenceByCandidateId(
        candidateId: number
    ): Promise<CompetenceType[] | null> {
        setLoading(true);
        return api
            .get(`/Competence/get-competence-candidate/${candidateId}`)
            .then((response) => {
                const competence: CompetenceType[] = response.data;
                setCompetence(competence);
                createNewListTable(competence);
                setLoading(false);
                return competence;
            })
            .catch((error) => {
                showToast(
                    "error",
                    "Erro ao obter competências. Verifique novamente mais tarde.",
                    error
                );
                setLoading(false);
                console.error("Erro ao obter informações", error);
                return null;
            });
    }

    function postCompetenceByCandidateId(
        competence: CompetenceType
    ): Promise<void | null> {
        setLoading(true);
        return api
            .post(`/Competence/create?candidateId=${candidate.id}`, competence)
            .then((response) => {
                showToast("success", "Formação acadêmica cadastrada com sucesso.");
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter competências. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao adicionar competência:", error);
                return null;
            });
    }

    function putCompetenceById(
        idCompetence: number,
        competence: CompetenceType
    ): Promise<void | null> {
        setLoading(true);
        return api
            .put(`/Competence/${idCompetence}`, competence)
            .then((response) => {
                showToast("success", "Competência atualizada com sucesso.");
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter competências. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar competência:", error);
                return null;
            });
    }

    function deleteCompetenceById(
        idCompetence: number,
    ): Promise<void | null> {
        setLoading(true);
        return api
            .delete(`/Competence/${idCompetence}`)
            .then((response) => {
                showToast("success", "Competência deletada com sucesso.");
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    handleLogout()
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao deletada competência. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao deletar a competência:", error);
                return null;
            });
    }

    const createNewListTable = (competences: CompetenceType[]) => {
        if (competences && competences.length > 0) {
            const newList = competences.map((item: CompetenceType) => {
                return {
                    column1: item.name,
                    id: item.id,
                };
            });
    
            const columns = [
                "NOME DA COMPETÊNCIA",
                " ",
                " ",
                " ",
                " ", 
            ];
    
            setCompetenceTable({ mylist: newList, columns: columns });
        } else {
            setCompetenceTable({ mylist: [], columns: [] });
        }
    };
    

    return {
        competences,
        competenceTable,
        formValuescompetence,
        setFormValuesCompetence,
        getAllCompetenceByCandidateId,
        postCompetenceByCandidateId,
        putCompetenceById,
        deleteCompetenceById,
    };
}
