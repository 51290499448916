import React, { useState, useEffect } from 'react';
import './WhatsappButtom.css';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsappButton = () => {
    const whatsappNumber = '34992743033';
    const [showHelpBox, setShowHelpBox] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {    
            setShowHelpBox(false);
        }, 5000); 
        
        return () => clearTimeout(timer);
    }, []);

    const openWhatsAppChat = () => {
        window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}`, '_blank');
    };

    const toggleHelpBox = () => {
        setShowHelpBox(!showHelpBox);
    };

    return (
        <div className="whatsapp-button" onClick={openWhatsAppChat} onMouseEnter={() => setShowHelpBox(true)} onMouseLeave={() => setShowHelpBox(false)}>
            <WhatsAppIcon />
            {showHelpBox && (
                <div className="help-box">
                    <span>Precisa de ajuda?</span>
                    <button onClick={toggleHelpBox}>x</button>
                </div>
            )}
        </div>
    );
};

export default WhatsappButton;
