import { ReactNode, createContext } from "react";
import useFavorite, { FavoriteType } from "./hooks/useFavorite";

interface FavoriteContextProviderProps {
    children: ReactNode;
}

export interface FavoriteContextData {
    handleGetByUserId: (id: number) => Promise<FavoriteType | null>;
    putFavoriteList: (userData: FavoriteType) => Promise<FavoriteType | null>;
}

const initialState: FavoriteContextData = {
    handleGetByUserId: async (id: number) => {
        return Promise.resolve(null);
    },
    putFavoriteList: async (userData: FavoriteType) => {
        console.log("userData: ", userData);
        return Promise.resolve(null);
    },
};

export const FavoriteContext = createContext<FavoriteContextData>(initialState);

export default function FavoriteContextProvider({
    children,
}: FavoriteContextProviderProps) {
    const {
        handleGetByUserId,
        putFavoriteList,
    } = useFavorite();

    return (
        <FavoriteContext.Provider
            value={{
                handleGetByUserId,
                putFavoriteList,
            }}
        >
            {children}
        </FavoriteContext.Provider>
    );
}
