import * as React from "react";
import { SVGProps } from "react";

const IconeReject = ({
    fill,
}: SVGProps<SVGSVGElement>): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="26"
            viewBox="0 0 24 24"
        >
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <circle cx="12" cy="12" r="11.5" stroke="#FFFFFF" />
                <path stroke="#FFFFFF" d="M7.83 7.83l8.34 8.34M16.17 7.83L7.83 16.17" />
            </g>
        </svg>
    );
};

export default IconeReject;
