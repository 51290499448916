import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";

import "./ModalEducation.css";
import { useContext, useState } from "react";
import { EducationContext } from "../../context/EducationContext";
import { CandidateContext } from "../../context/CandidateContext";

interface ModalComponentProps {
  openModal: {open: boolean, idEdit: number};
  closeModal: () => void;
}

const ModalEducation = (props: ModalComponentProps) => {
  const {
    postEducationByCandidateId,
    getAllEducationByCandidateId,
    putEducationById,
    formValuesEducation,
    setFormValuesEducation,
  } = useContext(EducationContext);
  const { candidate } = useContext(CandidateContext);
  const { openModal, closeModal } = props;
  const [errors, setErrors] = useState({
    endDate: "",
    startDate: "",
    educationalInstitution: "",
    course: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    const newValue =
      type === "checkbox" ? (checked ? "Em andamento" : "") : value;

    setFormValuesEducation({
      ...formValuesEducation,
      [name]: newValue,
    });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      if (openModal.idEdit !== 0) {
        await putEducationById(openModal.idEdit, formValuesEducation);
      } else {
        await postEducationByCandidateId(formValuesEducation);
      }
      await getAllEducationByCandidateId(candidate.id);
      closeModal();
      clearForm();
    }
  };

  const clearForm = () => {
    setFormValuesEducation({
      endDate: "",
      startDate: "",
      educationalInstitution: "",
      course: ""
    });
    setErrors({
      endDate: "",
      startDate: "",
      educationalInstitution: "",
      course: ""
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    for (const field in formValuesEducation) {
      //@ts-ignore
      if (!formValuesEducation[field] && field !== "candidate") {
        isValid = false;
        //@ts-ignore
        newErrors[field] = "Campo obrigatório";
      } else {
        //@ts-ignore
        newErrors[field] = "";
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const cancelButton = () => {
    clearForm();
    closeModal();
  };

  return (
    <div>
      <Modal
        style={{ maxWidth: "870px" }}
        isOpen={openModal.open}
        toggle={closeModal}
        {...props}
      >
        <ModalHeader />
        <ModalBody>
          <div className="content-modal-name-job-details text-center mb-4">
            Formação acadêmica
          </div>
          <CardBody style={{ boxShadow: "0 5px 8px rgba(-11, -17, -10, 0.2)" }}>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Instituição de ensino
                      </label>
                      <Input
                        name="educationalInstitution"
                        value={formValuesEducation.educationalInstitution}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-educationalInstitution"
                        placeholder="Nome da instituição de ensino"
                        type="text"
                      />
                      <span style={{ color: "red" }}>{errors.educationalInstitution}</span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-course"
                      >
                        Curso
                      </label>
                      <Input
                        name="course"
                        value={formValuesEducation.course}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-first-course"
                        placeholder="Curso"
                        type="text"
                      />
                      <span style={{ color: "red" }}>{errors.course}</span>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-last-name"
                      >
                        Data Início
                      </label>
                      <Input
                        name="startDate"
                        value={formValuesEducation.startDate}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-last-startDate"
                        placeholder="01/01/2024"
                        type="date"
                      />
                      <span style={{ color: "red" }}>{errors.startDate}</span>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-last-name"
                      >
                        Data Fim
                      </label>
                      <Input
                        name="endDate"
                        value={formValuesEducation.endDate}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-last-endDate"
                        placeholder="01/01/2024"
                        type="date"
                      />

                      <div className="mt-2 ml-5">
                        <Input
                          name="endDate"
                          checked={formValuesEducation.endDate  === "Em andamento" ? true : false}
                          onChange={handleChange}
                          className="form-control-alternative"
                          id="input-last-endDate"
                          placeholder="01/01/2024"
                          type="checkbox"
                        />
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Em andamento
                        </label>
                      </div>
                      <span style={{ color: "red" }}>{errors.endDate}</span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <Button className="button-custom" onClick={handleSubmit}>
            Salvar
          </Button>
          <Button color="secondary" onClick={cancelButton}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalEducation;
