import { ReactNode, createContext } from "react";
import useCompany, { CompanyType } from "./hooks/useCompany";

interface CompanyContextProviderProps {
  children: ReactNode;
}

export interface CompanyContextData {
  handleGetByUserId: (id: number) => Promise<CompanyType | null>;
  putCompany: (Company: CompanyType) => Promise<CompanyType | null>;
  company: CompanyType;
  setCompany: any;
}

const initialState: CompanyContextData = {
  handleGetByUserId: async (id: number) => {
    return Promise.resolve(null);
  },
  putCompany: async (Company: CompanyType) => {
    return Promise.resolve(null);
  },
  company: {
    name: "",
    companyName: "",
    city: "",
    email: "",
    celPhone: "",
    address: "",
    user: {},
    photo: "",
    description: "",
    id: 0,
  },
  setCompany: () => {}
};

export const CompanyContext = createContext<CompanyContextData>(initialState);

export default function CompanyContextProvider({
  children,
}: CompanyContextProviderProps) {
  const { handleGetByUserId, company, setCompany, putCompany } = useCompany();

  return (
    <CompanyContext.Provider
      value={{
        handleGetByUserId,
        company,
        setCompany,
        putCompany
      }}
    >
      {children}
    </CompanyContext.Provider>
    
  );
}
