import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";

import "./ModalChangePassword.css";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

interface ModalComponentProps {
  openModal: boolean;
  closeModal: () => void;
}

const ModalChangePassword = (props: ModalComponentProps) => {
const { formPassword, setFormPassword, handleChangePassword } = useContext(AuthContext)
  const { openModal, closeModal } = props;
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormPassword({
      ...formPassword,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      handleChangePassword(formPassword);
      cancelButton()
    }
  };

  const clearForm = () => {
    setFormPassword({
      password: "",
      confirmPassword: "",
    });
    setErrors({
      password: "",
      confirmPassword: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (formPassword.confirmPassword !== formPassword.password) {
      newErrors["confirmPassword"] = "Senha Diferente"
      isValid = false
    } else if (!formPassword.password){
      isValid = false
      newErrors["password"] = "Campo Obrigatório"
    } else {
      newErrors["confirmPassword"] = ""
      newErrors["password"] = ""
    }

    setErrors(newErrors);
    return isValid;
  };

  const cancelButton = () => {
    clearForm();
    closeModal();
  };

  return (
    <div>
      <Modal
        style={{ maxWidth: "570px" }}
        isOpen={openModal}
        toggle={closeModal}
        {...props}
      >
        <ModalHeader />
        <ModalBody>
          <div className="content-modal-name-job-details text-center mb-4">
            Mude sua senha
          </div>
          <CardBody style={{ boxShadow: "0 5px 8px rgba(-11, -17, -10, 0.2)" }}>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Senha
                      </label>
                      <Input
                        name="password"
                        value={formPassword.password}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-password"
                        placeholder="Digite sua nova senha"
                        type="password"
                      />
                      <span style={{ color: "red" }}>{errors.password}</span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-course"
                      >
                        Confirme sua senha
                      </label>
                      <Input
                        name="confirmPassword"
                        value={formPassword.confirmPassword}
                        onChange={handleChange}
                        className="form-control-alternative"
                        id="input-first-confirmPassword"
                        placeholder="Confirma sua nova senha"
                        type="password"
                      />
                      <span style={{ color: "red" }}>{errors.confirmPassword}</span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <Button className="button-custom" onClick={handleSubmit}>
            Salvar
          </Button>
          <Button color="secondary" onClick={cancelButton}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalChangePassword;
