import Profile from "./views/screensCandidate/Profile.tsx";
import Login from "./views/Login.tsx";
import Tables from "./views/screensCandidate/Tables.tsx";
import SearchJobs from "./views/screensCandidate/SearchJobs/SearchJobs.tsx";
import SearchCandidates from "./views/screensRecruiter/SearchCandidates/SearchCandidates.tsx";
import IndicatorMenu from "./views/screensAdmin/IndicatorMenu.tsx";
import Dashboard from "./views/screensCandidate/Dashboard.tsx";
import ProfileRecruiter from "./views/screensRecruiter/Profile.tsx";
import AddUser from "./views/screensAdmin/AddUser.tsx";
import Home from "./views/Home.tsx";

export const routes = [
  {
    path: "/index",
    name: "Página Inicial",
    icon: "fa fa-home text-yellow",
    component: <Home />,
    layout: "/admin",
  },
  {
    path: "/search-jobs",
    name: "Procurar Vagas",
    icon: "ni ni-planet text-blue",
    component: <SearchJobs />,
    layout: "/admin",
  },
  // {
  //   path: "/my-applications",
  //   name: "Minhas Candidaturas",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: <Tables />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/search-jobs",
  //   name: "Procurar Vagas",
  //   icon: "ni ni-planet text-blue",
  //   component: <SearchJobs />,
  //   layout: "/admin",
  // },
  {
    path: "/profile",
    name: "Meu Perfil",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

export const routesRecruiter = [
  {
    path: "/index",
    name: "Página Inicial",
    icon: "fa fa-home text-yellow",
    component: <Home />,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Meu Perfil",
    icon: "ni ni-single-02 text-yellow",
    component: <ProfileRecruiter />,
    layout: "/admin",
  },
  {
    path: "/search-candidates",
    name: "Procurar Candidatos",
    icon: "ni ni-planet text-blue",
    component: <SearchCandidates />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

export const routesAdmin = [
  // {
  //   path: "/index",
  //   name: "Página Inicial",
  //   icon: "fa fa-home text-yellow",
  //   component: <Home />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/profile",
  //   name: "Meu Perfil",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: <Profile />,
  //   layout: "/admin",
  // },
  {
    path: "/index",
    name: "Menu de indicadores",
    icon: "fa-solid fa-chart-simple text-purple",
    component: <IndicatorMenu />,
    layout: "/admin",
  },
  {
    path: "/search-candidates",
    name: "Procurar Candidatos",
    icon: "ni ni-planet text-blue",
    component: <SearchCandidates />,
    layout: "/admin",
  },
  {
    path: "/search-jobs",
    name: "Procurar Vagas",
    icon: "ni ni-planet text-blue",
    component: <SearchJobs />,
    layout: "/admin",
  },
  {
    path: "/add-user",
    name: "Adicionar usuário",
    icon: "fa-solid fa-plus text-green",
    component: <AddUser />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

export const externalUser = [
  {
    path: "/index",
    name: "Página Inicial",
    icon: "fa fa-home text-yellow",
    component: <Home />,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Meu Perfil",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/search-jobs",
    name: "Procurar Vagas",
    icon: "ni ni-planet text-blue",
    component: <SearchJobs />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
]
