import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  FormGroup,
  Col,
  Row,
} from "reactstrap";

import "./DropdownFilter.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface DropdownFilterProps {
  nameDrop: string;
  type: "date" | "text";
  name: string;
  value: any;
  handleChange: any;
  options: any;
}

const DropdownFilter = (props: DropdownFilterProps) => {
  const { nameDrop, type, name, value, handleChange, options } = props;

  return (
    <>
      {type === "date" ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className="form-control-label" htmlFor="input-birth-date">
            {nameDrop}
          </label>
          <TextField
            id={name}
            onChange={handleChange}
            name={name}
            value={value}
            variant="standard"
            type="date"
          />
        </div>
      ) : (
        <div>
          <label className="form-control-label" htmlFor="input-birth-date">
            {nameDrop}
          </label>
          <Autocomplete
            clearIcon={null}
            disablePortal
            id={name}
            options={options}
            value={value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                name={name}
                value={value}
                variant="standard"
                {...params}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default DropdownFilter;
