import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

import "./ModalComponentRecruiter.css";
import { useContext, useEffect } from "react";
import ReactPlayer from "react-player";
import { AuthContext } from "../../context/AuthContext";
import { CandidateContext } from "../../context/CandidateContext";
import { AnnotationContext } from "../../context/AnnotationContext";
import calculateAge from "../../utils/calculateAge";
import { AnnotationType } from "../../context/hooks/useAnnotation";
import { ExpertiseType } from "../../context/hooks/useExpertise";
import { EducationType } from "../../context/hooks/useEducation";
import { CourseType } from "../../context/hooks/useCourses";
import { CompetenceType } from "../../context/hooks/useCompetence";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import React, { useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { useToast } from "../../context/ToastContext";
import zIndex from "@mui/material/styles/zIndex";

interface ModalComponentProps {
  openModal: boolean;
  closeModal: () => void;
}

const ModalComponentRecruiter = (props: ModalComponentProps) => {
  const { openModal, closeModal } = props;
  const { candidate, handleGetCvCandidate } = useContext(CandidateContext);
  const { objUserTk } = useContext(AuthContext);
  const showToast = useToast();
  const [loading, setLoading] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [showButtons, setShowButtons] = useState(true);
  const [annotations, setAnnotations] = useState<AnnotationType[]>([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [editingAnnotation, setEditingAnnotation] =
    useState<AnnotationType | null>(null);

  const {
    handleGetAnnotation,
    addAnnotation,
    putAnnotation,
    deleteAnnotation,
  } = useContext(AnnotationContext);

  const clearAnnotations = () => {
    setTextareaValue("");
  };

  useEffect(() => {
    if (openModal && candidate?.id) {
      clearAnnotations();
      loadCandidateAnnotations();
    } else {
      clearAnnotations();
    }
  }, [openModal, candidate]);

  const redirectToWhatsApp = () => {
    const urlWhatsApp = `https://wa.me/${
      "+55" + candidate?.celPhone.replace(/\D/g, "")
    }`;
    window.open(`${urlWhatsApp}`, "_blank");
  };

  const redirectToLinkedIn = () => {
    const urlLinkedIn = `${candidate?.linkedin.replace(/\s+/g, "").trim()}`;
    window.open(`${urlLinkedIn}`, "_blank");
  };

  const loadCandidateAnnotations = async () => {
    try {
      const candidateId = candidate?.id;
      const userId = objUserTk.UserId;
      const fetchedAnnotations = await handleGetAnnotation(userId, candidateId);
      if (Array.isArray(fetchedAnnotations)) {
        setAnnotations(fetchedAnnotations);
      }
    } catch (error) {
      console.error("Erro ao carregar anotações do candidato:", error);
    }
  };

  const editAnnotation = (annotation: AnnotationType) => {
    setEditingAnnotation(annotation);
    setTextareaValue(annotation.description || "");
  };

  const removeAnnotation = async (annotation: AnnotationType) => {
    try {
      await deleteAnnotation(annotation);
      loadCandidateAnnotations();
    } catch (error) {
      console.error("Erro ao excluir anotação:", error);
    }
  };

  const saveAnnotation = async () => {
    try {
      if (editingAnnotation) {
        await putAnnotation({
          ...editingAnnotation,
          description: textareaValue,
          lastUpdateDate: new Date().toISOString(),
        });
      } else {
        const newAnnotation = {
          candidateId: candidate?.id,
          userId: Number(objUserTk.UserId),
          description: textareaValue,
          creationDate: new Date().toISOString(),
          lastUpdateDate: new Date().toISOString(),
        };
        await addAnnotation(newAnnotation);
      }
      setTextareaValue("");
      setEditingAnnotation(null);
      loadCandidateAnnotations();
    } catch (error) {
      console.error("Erro ao salvar anotação:", error);
    }
  };

  const capitalizeName = (name: string): string => {
    return name.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div>
      <Modal
        isOpen={loading}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <ModalBody
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <h5>Baixando página...</h5>
            <div
              className="spinner-border ms-auto"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        style={{ maxWidth: "870px" }}
        isOpen={openModal}
        toggle={closeModal}
        {...props}
      >
        <ModalHeader />
        <ModalBody id="content-id">
          <div className="PDFmaker" ref={contentRef}>
            <div className="container">
              <div className="row">
                <div
                  className="col-sm-4 col-md-4 col-lg-4 d-flex flex-column align-items-justify-content-center"
                  style={{ gap: "13px" }}
                >
                  {/* <div className="content-name-company">
                    Conheça o candidato:{" "}
                  </div> */}
                  <div className="card-profile-image-candidate">
                    <img
                      style={{
                        width: "170px",
                        height: "170px",
                        objectFit: "cover",
                        objectPosition: "center center",
                      }}
                      alt="Avatar"
                      className="img-fluid rounded-circle shadow img-svg"
                      src={
                        candidate?.photo ||
                        require("../../assets/img/theme/profile.png")
                      }
                    />
                  </div>
                  <div className="content-name-state">
                    {candidate?.city}, {candidate?.state}
                  </div>
                  <div className="content-name-name">
                    <strong>
                      {candidate?.name ? capitalizeName(candidate?.name) : ""}
                    </strong>
                  </div>
                  <div className="content-name-years">
                    {calculateAge(candidate?.birthData)} anos
                  </div>
                  {/* <div className="content-modal-celphone">
                    {candidate.celPhone}
                  </div> */}
                  <div className="content-modal-email break-word">
                    {candidate.email}
                  </div>
                  <div className="content-modal-email">
                    <strong>Portador de alguma deficiência: </strong>
                    {candidate.hasPCD ? "Sim" : "Não"}
                  </div>
                  <div className="content-modal-email">
                    <strong>Status da CNH: </strong>
                    {candidate.carLicense === 0
                      ? "Não possúi"
                      : candidate.carLicense === 1
                      ? "Provisória"
                      : candidate.carLicense === 2
                      ? "Definitiva"
                      : "Indefinido"}
                  </div>
                  <div className="content-modal-email">
                    <strong>Possui carro próprio: </strong>
                    {candidate.hasCar ? "Sim" : "Não"}
                  </div>
                  <div className="content-modal-celphone text-center">
                    {candidate?.expertises?.map((experience: ExpertiseType) => {
                      return (
                        <React.Fragment key={experience.id}>
                          {experience.title}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="social-network-button text-justify">
                    <div className="d-flex justify-content-center" style={{gap: "14px"}}>
                      {candidate?.celPhone && (
                        <WhatsAppIcon
                          sx={{ color: "#075E54" }}
                          fontSize="large"
                          onClick={showButtons ? redirectToWhatsApp : undefined}
                          data-html2canvas-ignore
                        />
                      )}
                      {!showButtons && candidate?.celPhone && (
                        <span style={{ fontSize: "12px", textAlign: "center" }}>
                          Whatsapp: {candidate.celPhone}
                        </span>
                      )}
                      {candidate.linkedin && (
                        <LinkedInIcon
                          sx={{ color: "#075E54" }}
                          fontSize="large"
                          onClick={showButtons ? redirectToLinkedIn : undefined}
                          data-html2canvas-ignore
                        />
                      )}
                    </div>
                    {candidate.celPhone && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={redirectToWhatsApp}
                        className="content-modal-email break-word"
                      >
                        <strong>WhatsApp: </strong>
                        {candidate.celPhone.trim()}
                      </div>
                    )}
                    {candidate.linkedin && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={redirectToLinkedIn}
                        className="content-modal-email break-word"
                      >
                        <strong>Linkedin: </strong>
                        {candidate.linkedin.trim()}
                      </div>
                    )}
                  </div>
                  {candidate.extracurricularCourses &&
                    candidate.extracurricularCourses.length > 0 && (
                      <div>
                        <div>
                          <strong>Cursos Extracurriculares: </strong>
                        </div>
                        {candidate?.extracurricularCourses?.map(
                          (course: CourseType) => {
                            return (
                              <>
                                <div key={course.id}>
                                  <div>{course.extracurricularCourse}</div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    )}
                  {candidate.competences &&
                    candidate.competences.length > 0 && (
                      <div>
                        <div>
                          <strong>Principais competências: </strong>
                        </div>
                        {candidate?.competences?.map(
                          (competence: CompetenceType) => {
                            return (
                              <>
                                <div
                                  key={competence.id}
                                  style={{
                                    lineHeight: "1.8",
                                  }}
                                >
                                  <div>{competence.name}</div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    )}
                  <br />
                  <div>
                    <strong>Ultima atualização feita em: </strong>
                    {candidate?.updatedDate}
                  </div>
                </div>
                <div
                  className="col-sm d-flex flex-column"
                  style={{ gap: "25px" }}
                >
                  {candidate?.personalGoal && (
                    <div>
                      <div>
                        <strong>Objetivo profissional: </strong>
                      </div>
                      <div>{candidate?.personalGoal}</div>
                    </div>
                  )}
                  <div>
                    <strong>Descrição do candidato: </strong>
                  </div>
                  <div>{candidate?.description}</div>
                  <div>
                    <strong>Experiências: </strong>
                  </div>
                  {candidate?.expertises?.map((experience: ExpertiseType) => {
                    return (
                      <>
                        <div
                          key={experience.id}
                          style={{
                            fontSize: "13px",
                            borderLeft: "1px solid #55027D",
                          }}
                        >
                          <ul>
                            <div
                              style={{
                                marginBottom: "5px",
                                lineHeight: "1.9",
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              <strong>Cargo: </strong>
                              {experience.title}
                            </div>
                            <div
                              style={{
                                marginBottom: "5px",
                                lineHeight: "1.9",
                                fontSize: "14px",
                              }}
                            >
                              <strong>Empresa: </strong> {experience.company}
                            </div>
                            <div
                              style={{
                                marginBottom: "5px",
                                lineHeight: "1.9",
                                fontSize: "14px",
                              }}
                            >
                              <strong>Período: </strong>
                              {experience.startDate} - {experience.endDate}
                            </div>
                            <div
                              style={{
                                marginBottom: "5px",
                                lineHeight: "1.9",
                                fontSize: "14px",
                              }}
                            >
                              <strong>Descrição: </strong>
                              {experience.description}
                            </div>
                          </ul>
                        </div>
                      </>
                    );
                  })}
                  <div>
                    <strong>Formação acadêmica: </strong>
                  </div>
                  {candidate?.educations?.map((education: EducationType) => {
                    return (
                      <>
                        <div
                          key={education.id}
                          style={{
                            fontSize: "13px",
                            borderLeft: "1px solid #55027D",
                          }}
                        >
                          <ul>
                            <div
                              style={{
                                marginBottom: "5px",
                                lineHeight: "1.9",
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              <strong>Instituição de ensino: </strong>
                              {education.educationalInstitution}
                            </div>
                            <div
                              style={{
                                marginBottom: "5px",
                                lineHeight: "1.9",
                                fontSize: "14px",
                              }}
                            >
                              <strong>Curso: </strong> {education.course}
                            </div>
                            <div
                              style={{
                                marginBottom: "5px",
                                lineHeight: "1.9",
                                fontSize: "14px",
                              }}
                            >
                              <strong>Período: </strong>
                              {education.startDate} - {education.endDate}
                            </div>
                          </ul>
                        </div>
                      </>
                    );
                  })}
                  {/* {candidate.extracurricularCourses && candidate.extracurricularCourses.length > 0 && (
                    <div>
                      <div>
                        <strong>
                          Cursos extracurriculares:
                        </strong>
                      </div>
                      {candidate?.extracurricularCourses?.map((course: CourseType) => {
                        return (
                          <>
                            <div
                              key={course.id}
                              style={{
                                fontSize: "13px",
                                borderLeft: "1px solid #55027D",
                              }}
                            >
                              <ul>
                                <div>
                                  {" "}
                                  <strong>Instituição de ensino: </strong>
                                  {course.educationalInstitution}
                                </div>
                                <div>
                                  <strong>Curso: </strong> {course.extracurricularCourse}
                                </div>
                                <div>
                                  <strong>Período: </strong>
                                  {course.startDate} - {course.endDate}
                                </div>
                              </ul>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )} */}
                  {objUserTk.Type !== "1" && (
                    <div>
                      <div>
                        <strong>Anotações do Candidato:</strong>
                      </div>
                      <br />
                      {annotations.length === 0 && (
                        <div
                          style={{
                            fontSize: "13px",
                            borderLeft: "1px solid #55027D",
                          }}
                        >
                          <ul>
                            <div>
                              <strong>Nenhuma anotação encontrada.</strong>
                            </div>
                          </ul>
                        </div>
                      )}
                      {annotations?.map((annotation, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              style={{
                                fontSize: "13px",
                                borderLeft: "1px solid #55027D",
                                position: "relative",
                              }}
                            >
                              <ul>
                                <div style={{ width: "70%" }}>
                                  <strong>Anotação: </strong>
                                  {annotation.description}
                                </div>
                                <div>
                                  <strong>Última atualização: </strong>
                                  {annotation.lastUpdateDate}
                                </div>
                                {/* Botões de ação */}
                                <div
                                  className="hide-on-pdf"
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    color: "#55027D",
                                    display: showButtons ? "block" : "none",
                                  }}
                                >
                                  <Button
                                    size="sm"
                                    onClick={() => editAnnotation(annotation)}
                                  >
                                    Editar
                                  </Button>{" "}
                                  <Button
                                    size="sm"
                                    onClick={() => removeAnnotation(annotation)}
                                  >
                                    Excluir
                                  </Button>
                                </div>
                              </ul>
                            </div>
                          </>
                        );
                      })}
                      <Input
                        name="description"
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                        className={`form-control-alternative hide-on-pdf ${
                          showButtons ? "" : "hidden"
                        }`}
                        id="input-annotation"
                        placeholder="Insira suas anotações aqui..."
                        type="textarea"
                        rows={4}
                        cols={50}
                        style={{ width: "100%", marginBottom: "10px" }}
                      />
                      <Button
                        className={`button-custom hide-on-pdf ${
                          showButtons ? "" : "hidden"
                        }`}
                        data-html2canvas-ignore
                        onClick={saveAnnotation}
                      >
                        Adicionar Anotação
                      </Button>
                    </div>
                  )}
                  {candidate.presentationVideo && (
                    <div
                      className={`hide-on-pdf ${showButtons ? "" : "hidden"}`}
                    >
                      <ReactPlayer
                        url={candidate.presentationVideo}
                        playing={false}
                        controls={true}
                        width="auto"
                        height="300px"
                      />
                    </div>
                  )}
                  {candidate.averageAdvertising && (
                    <div
                      className={`hide-on-pdf ${showButtons ? "" : "hidden"}`}
                      data-html2canvas-ignore
                    >
                      <div>
                        <strong>Propaganda médica:</strong>
                      </div>
                      <ReactPlayer
                        url={candidate.averageAdvertising}
                        playing={false}
                        controls={true}
                        width="auto"
                        height="300px"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {/* {candidate?.curriculum ? (
            <Button className="button-custom" onClick={downloadPDF}>
              BAIXAR CURRÍCULO
            </Button>
          ) : (
            <Button disabled className="button-custom">
              CURRÍCULO INDISPONÍVEL
            </Button>
          )} */}
          <Button
            className="button-custom"
            onClick={() => handleGetCvCandidate(candidate?.id)}
          >
            BAIXAR CURRÍCULO
          </Button>
          <Button color="secondary" onClick={closeModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalComponentRecruiter;
