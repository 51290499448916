import React, { useContext, useEffect } from 'react';
import { Container } from "reactstrap";
import ReactPlayer from "react-player";
import Header from "../components/Headers/Header";
import AdminNavbar from "../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import WhatsappButton from "../components/WhatsappButtom/WhatsappButtom";
import { AuthContext } from '../context/AuthContext'; 
import { FavoriteContext } from '../context/FavoriteContext';

const Home = () => {

  const { objUserTk } = useContext(AuthContext); 
  const { handleGetByUserId } = useContext(FavoriteContext);

  const videoUrls = {
    1: "https://youtu.be/dDNY4pogRLE",
    2: "https://youtu.be/nUEsHUoME9A?si=5YJcqVkDcusAoRFK",
    3: "https://youtu.be/nUEsHUoME9A?si=5YJcqVkDcusAoRFK",
  };

  const videoUrl = videoUrls[objUserTk.Type] || videoUrls[3];

  useEffect(() => {
    
    if (objUserTk.UserId) {
      handleGetByUserId(objUserTk.UserId)
        .then((favoriteData) => {
          if (favoriteData) {
            localStorage.setItem('favoriteCandidates', JSON.stringify(favoriteData.listFavoriteCandidates) || '[]');
            localStorage.setItem('rejectedCandidates', JSON.stringify(favoriteData.listRejectedCandidates) || '[]');
          }
        })
    }
  }, []);


  return (
    <>
      <WhatsappButton />
      <LoadingSpinner />
      <Header />
      <AdminNavbar 
        brandText={"Página Inicial"}
        search={false}
        titleSearch={""}
      />

      <div style={{ position: "relative", zIndex: 0, height: "calc(100vh - 56px)", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <ReactPlayer
          url={videoUrl}
          playing={false} 
          controls={true} 
          style={{ position: "inherit", top: "-15%", left: 0, right: 0, bottom: 0, margin: "auto" }}
          width="87%" 
          height="80%" 
        />
      </div>
    </>
  );
};

export default Home;
