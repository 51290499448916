import { ReactNode, createContext } from "react";
import useExpertise, { ExpertiseType } from "./hooks/useExpertise";

interface ExpertiseContextProviderProps {
  children: ReactNode;
}

export interface ExpertiseContextData {
  getAllExpertiseByCandidateId: (
    candidateId: number
  ) => Promise<ExpertiseType[] | null>;
  postExpertiseByCandidateId: (
    expertise: ExpertiseType
  ) => Promise<void | null>;
  putExpertiseById: (
    idExperience: number,
    expertise: ExpertiseType
  ) => Promise<void | null>;
  deleteExpertiseById: (
    idExperience: number,
  ) => Promise<void | null>;
  expertises: ExpertiseType[];
  expertisesTable: any;
  formValues: ExpertiseType;
  setFormValues: any;
}

const initialState: ExpertiseContextData = {
  getAllExpertiseByCandidateId: async (candidateId: number) => {
    return Promise.resolve(null);
  },
  postExpertiseByCandidateId: async (expertise: ExpertiseType) => {
    return Promise.resolve(null);
  },
  putExpertiseById: async (idExperience: number, expertise: ExpertiseType) => {
    return Promise.resolve(null);
  },
  deleteExpertiseById: async (idExperience: number) => {
    return Promise.resolve(null);
  },
  expertises: [],
  expertisesTable: [],
  formValues: {
    company: "",
    description: "",
    endDate: "",
    startDate: "",
    title: "",
    typeJob: "",
  },
  setFormValues: () => {},
};

export const ExpertiseContext =
  createContext<ExpertiseContextData>(initialState);

export default function ExpertiseContextProvider({
  children,
}: ExpertiseContextProviderProps) {
  const {
    getAllExpertiseByCandidateId,
    expertises,
    expertisesTable,
    postExpertiseByCandidateId,
    formValues,
    setFormValues,
    deleteExpertiseById,
    putExpertiseById,
  } = useExpertise();

  return (
    <ExpertiseContext.Provider
      value={{
        getAllExpertiseByCandidateId,
        expertises,
        expertisesTable,
        postExpertiseByCandidateId,
        formValues,
        setFormValues,
        putExpertiseById,
        deleteExpertiseById
      }}
    >
      {children}
    </ExpertiseContext.Provider>
  );
}
