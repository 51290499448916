import { ReactNode, createContext } from "react";
import useAuth, { FormPassword, UserProps, UserToken } from "./hooks/useAuth";

interface AuthContextProviderProps {
  children: ReactNode;
}

const initialState: AuthContextData = {
  user: {
    password: "",
    email: "",
  },
  setUser({ password, email }) {},
  authenticated: false,
  handleLogin({ password, email }) {},
  handleLogout() {},
  loadingButton: false,
  objUserTk: { unique_name: "", UserId: 0, UserName: "", Type: "1" },
  selectTypeUser: 0,
  setSelectTypeUser: () => {},
  setFormPassword: () => {},
  formPassword: {
    password: "",
    confirmPassword: ""
  },
  handleChangePassword({password, confirmPassword}) {},
};

export interface AuthContextData {
  user: {
    password: string;
    email: string;
  };
  setUser: ({ password, email }: UserProps) => void;
  authenticated: boolean;
  handleLogin: ({ email, password }: UserProps) => void;
  handleLogout: () => void;
  loadingButton: boolean;
  objUserTk: UserToken;
  selectTypeUser: number,
  setSelectTypeUser: any;
  formPassword: FormPassword, 
  setFormPassword: any,
  handleChangePassword: ({password, confirmPassword}: FormPassword) => void
}

export const AuthContext = createContext<AuthContextData>(initialState);

export default function AuthContextProvider({
  children,
}: AuthContextProviderProps) {
  const {
    data,
    authenticated,
    loadingButton,
    handleLogin,
    handleLogout,
    handleSetUser,
    objUserTk,
    selectTypeUser,
    setSelectTypeUser,
    formPassword,
    setFormPassword,
    handleChangePassword
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        user: data,
        setUser: handleSetUser,
        authenticated: authenticated,
        handleLogin,
        handleLogout,
        loadingButton,
        objUserTk,
        selectTypeUser,
        setSelectTypeUser,
        formPassword,
        setFormPassword,
        handleChangePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
