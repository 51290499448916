import { ReactNode, createContext } from "react";
import useJob, { JobType } from "./hooks/useJob";

interface JobContextProviderProps {
  children: ReactNode;
}

export interface JobContextData {
  getAllJobs: (
  ) => Promise<JobType[] | null>;
  getAllJobsPaged: (
    pageNumber: number,
    keyword?: string,
    location?: string,
    workModel?: string,
    publishedDate?: string,
    PCD?: string,
  ) => Promise<JobType[] | null>;
  handleGetByJobId: (id: number) => Promise<JobType | null>;
  jobs: JobType[];
  setJobs: any
  jobSelectCard: JobType, 
  setJobSelectCard: any
  listJobs: JobType[];
  initialListJobs: JobType[];
  setListJobs: any;
  setPageCountJobs: any;
  pageCountJobs: number;
}

const initialState: JobContextData = {
  getAllJobs: async () => {
    return Promise.resolve(null);
  },
  handleGetByJobId: async (id: number) => {
    return Promise.resolve(null);
  },
  getAllJobsPaged: async (
    pageNumber: number,
    keyword?: string,
    location?: string,
    workModel?: string,
    publishedDate?: string,
    PCD?: string,
  ) => {
    return Promise.resolve(null);
  },
  listJobs: [],
  initialListJobs: [],
  jobs: [],
  jobSelectCard: {
    companyLogoUrl: "",
    companyName: "",
    description: "",
    pcd: "",
    workModel: "",
    id: 0,
    jobLink: "",
    jobTitle: "",
    jobType: "",
    location: "",
    publishedDate: ""
  },
  setJobs: () => {},
  setJobSelectCard: () => {},
  setListJobs: () => {},
  setPageCountJobs: () => {},
  pageCountJobs: 0,
};

export const JobContext =
  createContext<JobContextData>(initialState);

export default function JobContextProvider({
  children,
}: JobContextProviderProps) {
  const {
    jobs,
    getAllJobs,
    setJobs,
    jobSelectCard,
    setJobSelectCard,
    listJobs,
    initialListJobs,
    setListJobs,
    pageCountJobs,
    getAllJobsPaged,
    setPageCountJobs,
    handleGetByJobId,
  } = useJob();

  return (
    <JobContext.Provider
      value={{
        jobs,
        getAllJobs,
        setJobs,
        jobSelectCard,
        setJobSelectCard,
        initialListJobs,
        listJobs,
        setListJobs,
        pageCountJobs,
        getAllJobsPaged,
        setPageCountJobs,
        handleGetByJobId,
      }}
    >
      {children}
    </JobContext.Provider>
  );
}
