import { ReactNode, createContext } from "react";
import useCompetence, { CompetenceType } from "./hooks/useCompetence";

interface CompetenceContextProviderProps {
    children: ReactNode;
}

export interface CompetenceContextData {
    getAllCompetenceByCandidateId: (
        candidateId: number
    ) => Promise<CompetenceType[] | null>;
    postCompetenceByCandidateId: (
        competence: CompetenceType
    ) => Promise<void | null>;
    putCompetenceById: (
        idCompetence: number,
        competence: CompetenceType
    ) => Promise<void | null>;
    deleteCompetenceById: (
        idCompetence: number,
    ) => Promise<void | null>;
    competences: CompetenceType[];
    competenceTable: any;
    formValuescompetence: CompetenceType;
    setFormValuesCompetence: any;
}

const initialState: CompetenceContextData = {
    getAllCompetenceByCandidateId: async (candidateId: number) => {
        return Promise.resolve(null);
    },
    postCompetenceByCandidateId: async (competence: CompetenceType) => {
        return Promise.resolve(null);
    },
    putCompetenceById: async (idCompetence: number, competence: CompetenceType) => {
        return Promise.resolve(null);
    },
    deleteCompetenceById: async (idCompetence: number) => {
        return Promise.resolve(null);
    },
    competences: [],
    competenceTable: [],
    formValuescompetence: {
        name: ""
    },
    setFormValuesCompetence: () => { },
};

export const CompetenceContext =
    createContext<CompetenceContextData>(initialState);

export default function CompetenceContextProvider({
    children,
}: CompetenceContextProviderProps) {
    const {
        getAllCompetenceByCandidateId,
        competences,
        competenceTable,
        postCompetenceByCandidateId,
        formValuescompetence,
        setFormValuesCompetence,
        putCompetenceById,
        deleteCompetenceById
    } = useCompetence();

    return (
        <CompetenceContext.Provider
            value={{
                getAllCompetenceByCandidateId,
                competences,
                competenceTable,
                postCompetenceByCandidateId,
                formValuescompetence,
                setFormValuesCompetence,
                putCompetenceById,
                deleteCompetenceById
            }}
        >
            {children}
        </CompetenceContext.Provider>
    );
}
