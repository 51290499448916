import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { useToast } from "../ToastContext";
import { jwtDecode } from "jwt-decode";
import { LoadingContext } from "../LoadingContext";
import { FavoriteContext } from "../FavoriteContext";
import { AuthContext } from "../AuthContext";

export interface UserProps {
  password: string;
  email: string;
}

export interface FormPassword {
  password: string;
  confirmPassword: string;
}

export interface UserToken {
  unique_name: string;
  UserName: string;
  UserId: number;
  Type: "1" | "2";
}

type UserTypeCheck = 1 | 2 | 3;


export default function useAuth() {
  const { handleGetByUserId } = useContext(FavoriteContext);

  const [data, setData] = useState<UserProps>({
    password: "",
    email: "",
  });

  const [formPassword, setFormPassword] = useState<FormPassword>({
    password: "",
    confirmPassword: "",
  });

  const [authenticated, setAuthenticated] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const {setLoading} = useContext(LoadingContext)

  const tokenLocal = localStorage.getItem("token");

  let objUserTk: UserToken;

  if (tokenLocal) {
    objUserTk = jwtDecode(tokenLocal || "") as UserToken;
  } else {
    objUserTk = {} as UserToken;
  }

  const [selectTypeUser, setSelectTypeUser] = useState<UserTypeCheck>(
    parseInt(objUserTk.Type) as UserTypeCheck || 1 
  );

  const navigate = useNavigate();
  const showToast = useToast();

  function handleSetUser({ password, email }: UserProps) {
    setData({
      password,
      email,
    });
  }
  async function handleLogin({ email, password }: UserProps) {
    try {
      let userType = selectTypeUser;

      const isAdmin = email === "Admin@gmail.com" && password === "root";
      if (isAdmin) {
        setSelectTypeUser(3);
      }

      const {
        data: { token },
      } = await api.post("/Users/authenticate", { email, password, type: userType });

      localStorage.setItem("token", JSON.stringify(token));
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
      navigate("/admin/index");
    } catch (error) {
      showToast(
        "error",
        "Erro ao efetuar o login. Verifique suas credenciais.",
        error
      );
      console.error("Erro ao efetuar o login:", error);
    }
  }

  async function handleChangePassword(data: FormPassword) {
    try {
      setLoading(true);
      await api.put(`/Users/update-password/${objUserTk.UserId}`, data);
      showToast("success", "Senha atualizada com sucesso.");
      setLoading(false);
    } catch (error) {
      showToast(
        "error",
        "Erro ao mudar a senha. Verifique novamente mais tarde.",
        error
      );
      setLoading(false);
    }
  }

  async function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("favoriteCandidates");
    localStorage.removeItem("rejectedCandidates");
    api.defaults.headers.Authorization = null;
    navigate("/auth/login");
  }

  useEffect(() => {
    if (tokenLocal) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(tokenLocal)}`;
      setAuthenticated(true);
    }
    setLoadingButton(false);

    if (objUserTk.Type === "2") {
      setSelectTypeUser(2);
    }
  }, [tokenLocal]);

  // useEffect(() => {
  //   if (objUserTk.Type === "2") {
  //     setSelectTypeUser(2);
  //   }
  // }, []);

  return {
    authenticated,
    loadingButton,
    handleLogin,
    handleLogout,
    handleSetUser,
    data,
    objUserTk,
    selectTypeUser,
    setSelectTypeUser,
    formPassword, 
    setFormPassword,
    handleChangePassword
  };
}
