import { Button, Row } from "reactstrap";
import "./CardsJobs.css";

interface CardsJobsProps {
  description: string;
  nameJob: string;
  data: string;
  local: string;
  localOffice: string;
  nameCompany: string;
  jobType: string;
  companyLogoUrl: string;
  openJob: () => void;
  onClick: () => void;
}

const CardsJobs = (props: CardsJobsProps) => {
  const {
    description,
    nameJob,
    data,
    local,
    localOffice,
    nameCompany,
    jobType,
    openJob,
    onClick,
    companyLogoUrl,
  } = props;
  return (
    <>
      <div className="pt-4" onClick={onClick}>
        <Row className="icon-examples">
          {/* @ts-ignore */}
          <div lg="3" md="6" className="container d-flex align-items-center">
            {companyLogoUrl === "Não Informado" ? (
              <img
                style={{ maxWidth: "40px", minWidth: "40px", margin: "0 18px" }}
                className="logo-job"
                alt="..."
                src={require("../../assets/img/brand/logoIcon.jpg")}
              />
            ) : (
              <img
                style={{
                  maxWidth: "70px",
                  minWidth: "70px",
                  marginRight: "10px",
                }}
                className="logo-job"
                alt="..."
                src={companyLogoUrl}
              />
            )}
            <div className="card-list-jobs" tabIndex={0}>
              <div className="content-data-list-jobs">{data}</div>
              <div id="tooltip9332484" className="container-card-list-jobs">
                <div className="content-card-list-jobs">{nameJob}</div>
                <div className="content-name-company">{nameCompany}</div>
                <div className="cotent-info-local-jobs">
                  <div className="">{local}</div>
                  <div className="">{localOffice}</div>
                </div>
                <div className="cotent-desc-local-jobs">{jobType}</div>
                <div className="cotent-desc-local-jobs">{description}</div>
              </div>
              <div className="content-data-list-jobs-button">
                <Button onClick={openJob} className="button-custom">
                  + Detalhes
                </Button>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CardsJobs;
