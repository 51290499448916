import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import WhatsappButton from '../../components/WhatsappButtom/WhatsappButtom';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import { AuthContext } from '../../context/AuthContext';
import { IndicatorContext } from '../../context/IndicatorContext';
import { IndicatorType, UserCountType, IncompleteCandidateType } from '../../context/hooks/useIndicator';
import { Bar } from 'react-chartjs-2';
import { Icon } from '@material-ui/core';
import { FilterAlt } from '@mui/icons-material';

const IndicatorMenu = () => {
    const { handleGetCandidateCountByState, handleGetCandidateCountByCity, handleGetUserCountByType, handleGetUserCountByCompanyName, handleGetIncompleteCandidate } = useContext(IndicatorContext);
    const [cityData, setCityData] = useState<IndicatorType[]>([]);
    const [stateData, setStateData] = useState<IndicatorType[]>([]);
    const [incompleteCandidateData, setIncompleteCandidateData] = useState<IncompleteCandidateType[]>([]);
    const [userTypeData, setUserTypeData] = useState<UserCountType | null>(null);
    const [companyNameData, setCompanyNameData] = useState<UserCountType | null>(null);
    const { objUserTk } = useContext(AuthContext);

    const [cityPage, setCityPage] = useState(1);
    const [statePage, setStatePage] = useState(1);
    const [incompleteCandidatePage, setIncompleteCandidatePage] = useState(1);

    const [citySortDirection, setCitySortDirection] = useState<'asc' | 'desc'>('desc');
    const [stateSortDirection, setStateSortDirection] = useState<'asc' | 'desc'>('desc');
    const [incompleteCandidateSortDirection, setIncompleteCandidateSortDirection] = useState<'asc' | 'desc'>('asc');
    
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (objUserTk.UserId) {
            handleGetIncompleteCandidate()
                .then(data => {
                    if (data) {
                        setIncompleteCandidateData(Array.isArray(data) ? data : [data]);
                    }
                })
                .catch(error => {
                    console.error("Erro ao buscar lista de usuários incompletos:", error);
                });

            handleGetCandidateCountByCity()
                .then(data => {
                    if (data) {
                        setCityData(Array.isArray(data) ? data : [data]);
                    }
                })
                .catch(error => {
                    console.error("Erro ao buscar dados da cidade:", error);
                });

            handleGetCandidateCountByState()
                .then(data => {
                    if (data) {
                        setStateData(Array.isArray(data) ? data : [data]);
                    }
                })
                .catch(error => {
                    console.error("Erro ao buscar dados do estado:", error);
                });

            handleGetUserCountByType()
                .then(data => {
                    setUserTypeData(data);
                })
                .catch(error => {
                    console.error("Erro ao buscar contagem de usuários por tipo:", error);
                });

            handleGetUserCountByCompanyName()
                .then(data => {
                    setCompanyNameData(data);
                })
                .catch(error => {
                    console.error("Erro ao buscar contagem de usuários por nome de empresa:", error);
                });
        }
    }, []);

    const getTypeName = (type: string): string => {
        switch (type) {
            case "1":
                return "Candidato";
            case "2":
                return "Recrutador";
            case "3":
                return "Admin";
            case "4":
                return "Usuário Externo";
            default:
                return "Tipo Desconhecido";
        }
    };

    const sortDataDescending = (data: IndicatorType[]) => {
        return [...data].sort((a, b) => b.numCurriculosPreenchidos - a.numCurriculosPreenchidos);
    }

    const sortIncompleteCandidateDescending = (data: IncompleteCandidateType[]) => {
        return [...data].sort((a, b) => b.celPhone ? 1 : -1);
    }

    const sortData = (data: IndicatorType[], direction: 'asc' | 'desc') => {
        return [...data].sort((a, b) => {
            if (direction === 'asc') {
                return a.numCurriculosPreenchidos - b.numCurriculosPreenchidos;
            } else {
                return b.numCurriculosPreenchidos - a.numCurriculosPreenchidos;
            }
        });
    };

    const sortDataIncompleteCandidate = (data: IncompleteCandidateType[], direction: 'asc' | 'desc') => {
        const sortedData = [...data].sort((a, b) => {
            if (a.name && b.name) {
                return a.name.localeCompare(b.name);
            } else {
                return a.name ? 1 : -1;
            }
        });

        if (direction === 'desc') {
            return sortedData.reverse();
        }

        return sortedData;
    }

    const toggleCitySortDirection = () => {
        setCitySortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
    };

    const toggleStateSortDirection = () => {
        setStateSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
    };

    const toggleIncompleteCandidateSortDirection = () => {
        setIncompleteCandidateSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
    };

    // Paginação dos resultados
    const itemsPerPage = 10;

    const cityDataSorted = sortDataDescending(cityData);
    const stateDataSorted = sortDataDescending(stateData);
    const incompleteCandidateDataSorted = sortIncompleteCandidateDescending(incompleteCandidateData);

    const cityPaginationItems = Math.ceil(cityDataSorted.length / itemsPerPage);
    const statePaginationItems = Math.ceil(stateDataSorted.length / itemsPerPage);
    const incompleteCandidatePaginationItems = Math.ceil(incompleteCandidateDataSorted.length / itemsPerPage);

    const filterIncompleteCandidates = (data: IncompleteCandidateType[], term: string): IncompleteCandidateType[] => {
        return data.filter((candidate: IncompleteCandidateType) => {
            return (candidate.name && candidate.name.toLowerCase().includes(term.toLowerCase())) ||
                (candidate.email && candidate.email.toLowerCase().includes(term.toLowerCase())) ||
                (candidate.celPhone && candidate.celPhone.toLowerCase().includes(term.toLowerCase()));
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const renderIncompleteCandidatePage = () => {
        const sortedData = sortDataIncompleteCandidate(incompleteCandidateData, incompleteCandidateSortDirection);
        const filteredData = filterIncompleteCandidates(sortedData, searchTerm);
        const startIndex = (incompleteCandidatePage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);
        const pageData = filteredData.slice(startIndex, endIndex);

        return (
            <>
                {pageData.map((candidate: IncompleteCandidateType) => (
                    <tr key={candidate.email}>
                        <td className="text-center">{candidate.name || "Não informado"}</td>
                        <td className="text-center">{candidate.email || "Não informado"}</td>
                        <td className="text-center">{candidate.celPhone || "Não informado"}</td>
                        <td className="text-center">
                            {candidate.celPhone ? (
                                <Button
                                    color="success"
                                    onClick={() => handleWhatsappClick(candidate.celPhone)}
                                >
                                    Enviar WhatsApp
                                </Button>
                            ) : (
                                <Button color="secondary" disabled>
                                    Sem Número
                                </Button>
                            )}
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    const handleWhatsappClick = (celPhone: string | undefined) => {
        if (celPhone) {
            const cleanedPhoneNumber = celPhone.replace(/\D/g, '');
            window.open(`https://wa.me/${cleanedPhoneNumber}`, '_blank');
        } else {
            console.error('Número de telefone não definido');
        }
    };

    const renderCityPage = () => {
        const sortedData = sortData(cityData, citySortDirection);
        const startIndex = (cityPage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, sortedData.length);
        const pageData = sortedData.slice(startIndex, endIndex);

        return (
            <>
                {pageData.map(city => (
                    <tr key={city.city}>
                        <td className="text-center" style={{ width: '30%' }}>{city.city || "Não informado"}</td>
                        <td className="text-center">{city.numCurriculosPreenchidos}</td>
                    </tr>
                ))}
            </>
        );
    };

    const renderStatePage = () => {
        const sortedData = sortData(stateData, stateSortDirection);
        const startIndex = (statePage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, sortedData.length);
        const pageData = sortedData.slice(startIndex, endIndex);

        return (
            <>
                {pageData.map(state => (
                    <tr key={state.state}>
                        <td className="text-center" style={{ width: '30%' }}>{state.state || "Não informado"}</td>
                        <td className="text-center">{state.numCurriculosPreenchidos}</td>
                    </tr>
                ))}
            </>
        );
    };

    const formatTypeData = (data: UserCountType | null): any => {
        if (!data) return {};

        const labels: string[] = [];
        const values: number[] = [];

        Object.entries(data).forEach(([type, value]) => {
            const typeName = getTypeName(type);
            labels.push(typeName);
            values.push(value);
        });

        return {
            labels,
            datasets: [
                {
                    label: 'Contagem',
                    data: values,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };
    const formatCompanyData = (data: UserCountType | null): any => {
        if (!data) return {};

        const labels: string[] = [];
        const values: number[] = [];

        Object.entries(data).forEach(([companyName, value]) => {
            labels.push(companyName);
            values.push(value);
        });

        return {
            labels,
            datasets: [
                {
                    label: 'Contagem',
                    data: values,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const paginateCityPrev = () => {
        setCityPage(prev => prev - 1);
    };

    const paginateCityNext = () => {
        setCityPage(prev => prev + 1);
    };

    const paginateStatePrev = () => {
        setStatePage(prev => prev - 1);
    };

    const paginateStateNext = () => {
        setStatePage(prev => prev + 1);
    };

    const paginateIncompleteCandidatePrev = () => {
        setIncompleteCandidatePage(prev => prev - 1);
    };

    const paginateIncompleteCandidateNext = () => {
        setIncompleteCandidatePage(prev => prev + 1);
    };

    return (
        <>
            <WhatsappButton />
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Menu de Indicadores"}
                search={false}
                titleSearch={""}
            />

            <Container>
                {/* Gráficos de contagem de usuários por tipo e por nome da empresa */}
                <Row className="mt-5">
                    <Col md="6">
                        <h2 className="text-center">Contagem de usuários por tipo</h2>
                        <Bar data={formatTypeData(userTypeData)} />
                    </Col>
                    <Col md="6">
                        <h2 className="text-center">Contagem de usuários por nome da empresa</h2>
                        <Bar data={formatCompanyData(companyNameData)} />
                    </Col>
                </Row>
                <hr />
                {/* Tabela de contagem de usuários por tipo */}
                <Row className="mt-5">
                    <Col md="12" className="text-center">
                        <h2>Contagem de usuários por tipo</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Table bordered striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center font-weight-bold">Tipo</th>
                                    <th className="text-center font-weight-bold">Contagem</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userTypeData && Object.entries(userTypeData).map(([type, count]) => (
                                    <tr key={type}>
                                        <td className="text-center">{getTypeName(type)}</td>
                                        <td className="text-center">{count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <hr />
                {/* Tabela de contagem de usuários incompletos */}
                <Row className="mt-5">
                    <Col md="12" className="text-center">
                        <h2>Usuários incompletos</h2>
                    </Col>
                </Row>
                <Col xs="6">
                    <form className="d-flex">
                        <input
                            className="form-control me-2 mt-3 mb-3"
                            style={{ width: "75%" }}
                            type="text"
                            placeholder="Pesquisar..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </form>
                </Col>
                <Row>
                    <Col md="12">
                        <Table bordered striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center font-weight-bold" style={{ cursor: 'pointer' }} onClick={toggleIncompleteCandidateSortDirection}>
                                        Nome&nbsp;
                                        {stateSortDirection === 'asc' ?
                                            <Icon component={FilterAlt} /> :
                                            <Icon component={FilterAlt} />
                                        }
                                    </th>
                                    <th className="text-center font-weight-bold">Email</th>
                                    <th className="text-center font-weight-bold">Telefone</th>
                                    <th className="text-center font-weight-bold">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderIncompleteCandidatePage()}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {/* <Button disabled={incompleteCandidatePage === 1} onClick={paginateIncompleteCandidatePrev} size="sm">Anterior</Button>
                            <span className="mx-3">Página {incompleteCandidatePage}</span>
                            <Button disabled={incompleteCandidatePage === incompleteCandidatePaginationItems} onClick={paginateIncompleteCandidateNext} size="sm">Próxima</Button> */}
                            <nav aria-label="...">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem disabled={incompleteCandidatePage === 1}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={paginateIncompleteCandidatePrev}
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem className="active">
                                        <PaginationLink href="#pablo">{incompleteCandidatePage}</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={incompleteCandidatePage === incompleteCandidatePaginationItems}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={paginateIncompleteCandidateNext}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </nav>
                        </div>
                    </Col>
                </Row>
                <hr />
                {/* Tabela de contagem de usuários por nome de empresa */}
                <Row className="mt-5">
                    <Col md="12" className="text-center">
                        <h2>Contagem de usuários por nome da empresa</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Table bordered striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center font-weight-bold">Nome da Empresa</th>
                                    <th className="text-center font-weight-bold">Contagem</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyNameData && Object.entries(companyNameData).map(([companyName, count]) => (
                                    <tr key={companyName}>
                                        <td className="text-center">{companyName}</td>
                                        <td className="text-center">{count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <hr />
                {/* Tabelas de contagem de currículos por cidade e estado */}
                <Row className="mt-5">
                    <Col md="6">
                        <h2 className="text-center">Dados da cidade</h2>
                        <Table bordered striped responsive size="sm">
                            <thead>
                                <th className="text-center font-weight-bold">
                                    Cidade
                                </th>
                                <th className="text-center font-weight-bold" style={{ cursor: 'pointer' }} onClick={toggleCitySortDirection}>
                                    Currículos&nbsp;
                                    {citySortDirection === 'asc' ?
                                        <Icon component={FilterAlt} /> :
                                        <Icon component={FilterAlt} />
                                    }
                                </th>
                            </thead>
                            <tbody>
                                {renderCityPage()}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {/* <Button disabled={cityPage === 1} onClick={paginateCityPrev} size="sm">Anterior</Button>
                            <span className="mx-3">Página {cityPage}</span>
                            <Button disabled={cityPage === cityPaginationItems} onClick={paginateCityNext} size="sm">Próxima</Button> */}
                            <nav aria-label="...">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem disabled={cityPage === 1}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={paginateCityPrev}
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem className="active">
                                        <PaginationLink href="#pablo">{cityPage}</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={cityPage === cityPaginationItems}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={paginateCityNext}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </nav>
                        </div>
                    </Col>
                    <Col md="6">
                        <h2 className="text-center">Dados do estado</h2>
                        <Table bordered striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center font-weight-bold">
                                        Estado
                                    </th>
                                    <th className="text-center font-weight-bold" style={{ cursor: 'pointer' }} onClick={toggleStateSortDirection}>
                                        Currículos&nbsp;
                                        {stateSortDirection === 'asc' ?
                                            <Icon component={FilterAlt} /> :
                                            <Icon component={FilterAlt} />
                                        }
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderStatePage()}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {/* <Button disabled={statePage === 1} onClick={paginateStatePrev} size="sm">Anterior</Button>
                            <span className="mx-3">Página {statePage}</span>
                            <Button disabled={statePage === statePaginationItems} onClick={paginateStateNext} size="sm">Próxima</Button> */
                                <nav aria-label="...">
                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem disabled={statePage === 1}>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={paginateStatePrev}
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem className="active">
                                            <PaginationLink href="#pablo">{statePage}</PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem disabled={statePage === statePaginationItems}>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={paginateStateNext}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </nav>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default IndicatorMenu;
