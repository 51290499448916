import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface FavoriteType {
    userId: number,
    listFavoriteCandidates: number[],
    listRejectedCandidates: number []
}

export default function useFavorite() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout } = useContext(AuthContext);
    const [favorite, setFavoriteList] = useState<FavoriteType>({
        userId: 0,
        listFavoriteCandidates: [],
        listRejectedCandidates: []
    });

    function handleGetByUserId(id: number): Promise<FavoriteType | null> {
        return api
            .get(`/Favorite/get-favorite-by-userid/${id}`)
            .then((response) => {
                const favorite: FavoriteType = response.data;
                setFavoriteList(favorite);
                return favorite;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter a lista de favoritos do usuário: ", error);
                if (error?.response?.status == 401) {
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                    handleLogout();
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações do candidato. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    function putFavoriteList(userData : FavoriteType): Promise<FavoriteType | null> {
        setLoading(true);
        return api
            .put(`/Favorite/update-list`, userData)
            .then((response) => {
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                setLoading(false);
                console.error("Erro ao obter a lista de favoritos do usuário: ", error);
                if (error?.response?.status == 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar perfil. Verifique novamente mais tarde.",
                        error
                    );
                }
                return null;
            });
    }

    return {
        handleGetByUserId,
        putFavoriteList,
    };
}
